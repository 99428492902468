import { ElementsUIGenrate } from "./ElementsUIGenrate";
import { useContext } from "react";
import { boardConetxt } from "../pages/form-builder/dashboard/ContextHelper";

export default function Element({ element }) {
  const disableElementList = [
    "InputField-Follow-up-boss-lead-id",
    "InputField-opp-key",
  ];
  const { board, setboard } = useContext(boardConetxt);
  console.log("element ====>", element);
  const str = element?.elementid.split("-");

  console.log("board>>", board);
  let UI;
  if (disableElementList.includes(element?.elementid)) {
    return null;
  } else {
    const Store = ElementsUIGenrate(element?.type);
    UI = Store?.ui;
  }
  ////console.log("Store",Store)
  // const{board, setboard}=useContext(boardConetxt);
  //const elem = Elements.find((e) => e.id === element.type);
  // //console.log(elem, id, Elements)
  ////console.log(elem)
  // const UI = Store?.ui;

  return <UI elementid={element.elementid} element={element} />;
}
