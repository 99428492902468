import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { boardConetxt, conditionContext } from "../dashboard/ContextHelper";
import { useContext, useState, useEffect } from "react";
import { makeApiCall } from "../../../modules/MakeApiRequest";
import ConditionEmpty from "./ConditionsEmpty";
import ConditionsAll from "../../../components/form-builder-components/FormConditions/ConditionsAll.js";
import { gql, useQuery } from "@apollo/client";
import { Loaderr } from "../../../components/Loaderr";

function Conditions() {
  const params = useParams();
  console.log("params", params);

  const FormData = gql`
    query FormData($formId: String) {
      formData(form_id: $formId) {
        form_id
        form_name
        form_description
        form_fields
        form_conditions
        status
        form_created_date
        form_updated_date
        update_by
      }
    }
  `;
  const {
    loading: formDataLoading,
    error: formDataError,
    data: formData,
    refetch
  } = useQuery(FormData, {
    variables: { formId: params.id },
    // onCompleted: (data) => {
    //   console.log("data", data?.formData?.form_conditions);

    //   // setCondition(() => data?.formData?.form_conditions);
    //   setboard(() => data?.formData?.form_fields);
    // },
  });
  if (formDataLoading) return <Loaderr />;

  return <ConditionsUi formData={formData} params={params} refetch={refetch}/>;
}

function ConditionsUi({ formData, params, refetch }) {
  const { board, setboard } = useContext(boardConetxt);
  const { condition, setCondition } = useContext(conditionContext);
  console.log("condition context", condition, formData);

  useEffect(() => {
    const condition = formData?.formData?.form_conditions || [];
      setCondition(() => condition );

    
  }, [formData?.formData?.form_conditions]);

  console.log("formData>", formData, condition);
  return (
    <>
      {condition && board && (
        <div className="main fmc-main">
          <section className="fm-condition">
            <div
              className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
              role="alert"
            >
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex align-items-center my-2">
                  <i className="bx bx-mobile fs-5" />
                  <i className="bx bx-arrow-to-right me-2 fs-5" />
                  <i className="bx bx-desktop me-2 fs-5" />
                </div>
                <span className="my-2">
                  Please switch to desktop and optimize this view. Smaller
                  screens are not efficient. To learn more click here
                </span>
              </div>
            </div>
            <section className="col-xxl-6 col-lg-7 col-md-9 mb-5 pb-5 col-12 mx-auto">
              <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 py-4 border-bottom">
                <h5 className="m-0">Conditions</h5>
                <NavLink
                  to={`/forms/conditions/${params.id}/create-edit/${condition.length}`}
                  className="d-flex align-items-center btn btn-primary-outline"
                  state={{ conditionLength: condition.length }}
                >
                  <i className="iconify me-md-1 nav_icon" data-icon="bx:plus" />
                  <span className="d-sm-block d-none">Add New conditions</span>
                </NavLink>
              </div>
              {condition.length === 0 ? (
                <ConditionEmpty />
              ) : (
                <ConditionsAll
                  condition={condition}
                  setCondition={setCondition}
                  formData={formData}
                  refetch={refetch}
                />
              )}
              {/* --------------------------------------------------------------- */}
            </section>
          </section>
        </div>
      )}
    </>
  );
}

export default Conditions;
