import React, { useState, useEffect, useContext } from "react";
import NoWorkflow from "../../assets/images/no-flow.png";
import Header from "../../components/header-component";
import { useNavigate } from "react-router-dom";
import { Loaderr } from "../../components/Loaderr";
import { gql, useQuery } from '@apollo/client';
import SingleTemplate from "../../components/SingleTemplate";

const Workflows = gql`
  query Workflows {
    Workflows {
      workflow_name
      workflow_description
      trigger_id
      workflow_id
      status
    }
}`;

const Templates = gql`
  query GetTemplates {
    GetTemplates {
      template_id
      template_name
      template_description
      status
    }
  }`;

  const UpdateWorkflowTemplateStatus = gql`
  mutation UpdateWorkflowTemplateStatus($templateId: String!, $status: Boolean!) {
    UpdateWorkflowTemplateStatus(template_id: $templateId, status: $status) {
      statusCode
      message
      data
    }
  }`;

  const WorkflowUsageStats = gql`
  query WorkflowUsageStats {
    workflowUsageStats {
      next_billing_date
      lifetime_actions_run
      billing_actions_run
      billing_limit
      actions_run_today
    }
  }`;

const AutomationBuilderDashboard = () => {
  
  const [templateList, setTemplateList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [workflowStats, setWorkflowStats] = useState({});

  const {loading, error, data} = useQuery(Workflows);
  const {loading: templateLoading, error: templateError, data: templateData} = useQuery(Templates);
  const {loading: workflowStatsLoading, error: workflowStatsError, data: workflowStatsData} = useQuery(WorkflowUsageStats);

  const navigate = useNavigate();
  
  useEffect(() => {
    let templates = templateData?.GetTemplates;
    setTemplateList(templates);
  }, [templateData]);

  useEffect(() => {
    let stats = workflowStatsData?.workflowUsageStats;
    setWorkflowStats(stats);
  }, [workflowStatsData]);

  if (loading || templateLoading) return <Loaderr />;
  
  const handelClickRoutes = (id) => { 
    navigate(`/automation-builder/edit-workflow/${id}`);
  }

  // Handler for search input change
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  function searchList() {
    
    const filteredOptions = data?.Workflows?.filter(option => {
      // Check if the values are string before applying string methods
      const workflowNameString = option.workflow_name ? option.workflow_name.toString() : '';
      const workflowDescriptionString = option.workflow_description ? option.workflow_description.toString() : '';
      
      // Check if the search term is present in either the name or the description
      return (
        workflowNameString.toLowerCase().split(" ").join("").includes(searchValue.toLowerCase().split(" ").join("")) ||
        workflowDescriptionString.toLowerCase().split(" ").join("").includes(searchValue.toLowerCase().split(" ").join(""))
      );
    });
    
    const renderedOptions = filteredOptions?.map((option, i) => (
      <div
      id="workflow-list"
      className="row gx-4 gy-1 flex-wrap "
      onClick={() => {
        handelClickRoutes(option.workflow_id);
      }}
    >
      <div className="col-xdl-6 col-md-12 col-12">
        <div className="d-flex flex-column justify-content-center pointer bd-l bg-white my-md-3 my-2 p-3 px-4 border shaddow-lg rounded-3">
          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <b className="fs-6">
                  {option.workflow_name}
                </b>
                <span className="mt-1">Fub automation</span>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <i
                className="iconify p-0 fs-3 text-primary"
                data-icon="ic:sharp-bolt"
              ></i>
              <span
                className="text-decoration-underline"
                onClick={() => {
                  handelClickRoutes(option.workflow_id);
                }}
              >
                {option.workflow_id}
              </span>
            </div>
          </div>

          <span className="mt-3 text-secondary">
            {option.workflow_description}
          </span>

          <hr className="hr op-10" />

          <div className="d-flex justify-content-between align-items-center">
            <span className="mt-1">
              {/* <span className="me-1">400</span>actions up */}
              {/* till date */}
            </span>
            <div>
              <i
                className="iconify p-0 fs-5 mx-2 text-primary"
                data-icon="akar-icons:settings-horizontal"
              ></i>
              <i
                className="iconify p-0 fs-5 text-secondary"
                data-icon="mdi:trash"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    ));
    return (
      <div>
        {renderedOptions?.length !== 0 ? (
          renderedOptions
        ) : (
          <div className="mt-3">No matches found.</div>
        )}
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className=" pt-5 container mx-auto" id="body-pd">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5"></i>
              <i className="bx bx-arrow-to-right me-2 fs-5"></i>
              <i className="bx bx-desktop me-2 fs-5"></i>
            </div>

            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens
              are not efficient. To learn more click here
            </span>
          </div>
        </div>

        <h5>Automation builder</h5>
        <p className="text-secondary mt-2">
          Sync, map fields and trigger automations with ease and create
          conditions with the desired platform. To create an automation you must
          first create a trigger to build an automation. For further help and
          assitance, contact <a className="text-primary text-decoration-underline" href="mailto:support@interface.re">here</a> and find out more about automating your
          work with your favourite platform.
        </p>

        {
          <>
            <section className="d-flex mb-3 flex-wrap">
              {/* <div
                className="col-lg-auto col-12"
                data-bs-toggle="modal"
                data-bs-target="#listOfUserTriggersModal"
              >
                <div className="d-flex align-items-center card-g bg-white me-md-3 my-md-3 my-2 p-3 pe-4 border shaddow-lg rounded-3">
                    <i
                      className="iconify me-2 p-0 display-4 text-primary"
                      data-icon="ic:sharp-bolt"
                    ></i>
                    <b className="me-2 fs-4">8</b>
                    <span>triggers</span>
                  </div>
              </div>

              <div className="col-lg-auto col-md-6 col-12">
                <div className="d-flex align-items-center card-g bg-white me-md-3 my-md-3 my-2 p-3 pe-4 border shaddow-lg rounded-3">
                  <i
                    className="iconify me-2 p-0 display-4 text-primary"
                    data-icon="ri:flow-chart"
                  ></i>
                  <b className="me-2 fs-4">2/3</b>
                  <span>Workflow used</span>
                  <span className="badge bg-warning rounded-pill ms-2 fw-normal text-small">
                    Free
                  </span>
                </div>
              </div> */}

              <div className="col-lg-auto col-md-6 col-12">
              <div className="d-flex flex-column justify-content-center card-g bg-white me-md-3 my-md-3 my-2 p-3 px-4 border shaddow-lg rounded-3">
                <div className="d-flex flex-wrap align-items-center mb-1">
                  <b className="me-2 fs-6">{workflowStats?.billing_actions_run}/{workflowStats?.billing_limit / 1000}k</b>
                  <span className="me-lg-5 me-3">Actions</span>
                  {/* <span className="btn btn-small ms-auto btn-primary text-small rounded-pill fw-normal text-small">
                    + Recharge
                  </span> */}
                </div>

                <div className="progress w-100 mt-3 rounded-pill" style={{ height: "10px" }}>
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{
                      width: `${(workflowStats?.billing_actions_run / workflowStats?.billing_limit) * 100}%`
                    }}
                    aria-valuenow={(workflowStats?.billing_actions_run / workflowStats?.billing_limit) * 100}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              </div>
            </section>

            <section>
              

              <div className="tab-content" id="myTabContent-auto">

                <div className="tab-pane fade show active pt-3" id="profile" role="tabpanel">
                
                  
                  <div className="d-flex align-items-center justify-content-between flex-wrap py-3 border-Xbottom">
                    <h5 className="p-0 m-0">
                      Total {data ? data?.Workflows?.length : 0}
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                          <i className="iconify me-md-1 nav_icon fs-3" data-icon="ic:sharp-manage-search"></i>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Search workflows"
                            value={searchValue}
                            onChange={handleSearchChange}
                          />
                      </div>
                      {/* <div className="d-flex align-items-center">
                        <i
                          className="iconify me-md-1 nav_icon fs-3"
                          data-icon="material-symbols:sort-rounded"
                        ></i>
                        <span className="d-md-block d-none">Sort</span>
                      </div> */}
                    </div>
                  </div>
                  {data?.length !== 0 ? (
                        <div>{searchList()}</div>
                    
                  ) : (
                    <div
                      id="workflow-list"
                      className="row gx-4 gy-1 flex-wrap mt-2 pb-5 mb-5"
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center col-lg-9 col-xl-4 mx-auto pt-5">
                        <img
                          className="img-fluid mb-3"
                          src={NoWorkflow}
                          alt=""
                        />
                        <h5>No Workflow created</h5>
                        <p className="text-secondary">
                          To learn more about triggers and workflow clik here{" "}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="tab-pane fade pt-4"id="home"role="tabpanel">
                
                  <span className="mt-4 text-secondary">Templates are predefined automation tailored built by us that are mostly used and best suited for you. All you need is to toggle on and off when needed</span>
                  
                  <section className="row row-cols-lg-3 row-cols-md-2 gx-4 gy-1 my-3 pb-5">     
                  {templateList?.map((template) => {
                    return (
                      <SingleTemplate template={template} />
                    );  
                  }
                  )}
                  </section>
                </div>
              </div>
            </section>
          </>
        }
      </div>
    </>
  );
};

export default AutomationBuilderDashboard;

