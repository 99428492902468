const isValidNumber = (elementData, ElementSetting) => {
  console.log("elementData", elementData)
  const { minValue, maxValue } = ElementSetting;
  console.log("minValue", maxValue)
  let Max
  if(maxValue==undefined){
    Max=13
  }else if(maxValue== ""){
    Max=13
  }else{
    Max=maxValue
  }
  const matchNumber=Math.pow(10,12);
  let NumberValidationResult = {};
  let inputnumber = elementData?.number
  //console.log("inputnumber",inputnumber?.length)
  //console.log(inputnumber)
  let contactReg = /^[0-9\b]+$/;
  //console.log(contactReg.test(inputnumber))
  if (!contactReg.test(inputnumber)) {
    NumberValidationResult = {
      result: true,
      message: "",
    };
  }else if(+inputnumber?.length>=Max){
    NumberValidationResult = {
      result: false,
      message: "You have reached your maximum limit of characters allowed",
    };
  }
  return NumberValidationResult;
};

const isNumberRequired = (elementData, ElementSetting) => {
  const { required } = ElementSetting;
  let NumberValidationResult = {};
//console.log(elementData?.number?.length)
  if (required && elementData?.number?.length === 0) {
    NumberValidationResult = { result: false, message: "Number Field is required" };
  } else {
    NumberValidationResult = { result: true, message: "" };
  }
  return NumberValidationResult;
};

export { isNumberRequired, isValidNumber };
