import React, { useState, useContext, useEffect } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import Validation from "../../../utils/Validation";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { event } from "jquery";
import { useForm, Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";


export const CheckBoxUi = ({
  elementid,
  message = {},
  PreviewData,
  showHide,
  oppFields,
  previewFlag = false,
}) => {
  const [errorMessage, setErrorMessage] = useState({});
  const { board, setboard } = useContext(boardConetxt);
  const [copy,setCopy]=useState(false);
  const {
    register,
    formState: { errors },control,setValue
  } = useFormContext();
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const Location=useLocation();
  const params=new URLSearchParams(Location.search);

  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])
  

  // useEffect(()=>{
  //   if(params.get(elementid.replace(/-\d+$/, ''))){
  //     setValue(elementid,params.get(elementid.replace(/-\d+$/, '')))
  //   } 
  
  // },[params.get(elementid.replace(/-\d+$/, ''))])

  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])
  
 
  const { options, label,hidden, instructions, required } = ElementSetting;

  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };

//("errors",errors);
  const handelCheck = (event, item) => {
    const newboard = board.map((product) => {
      if (product.elementid === elementid) {
        product.settings.options.map((item) => {
          if (+item.id === +event.target.id) {
            item.isCheck = !item.isCheck;
          }
          return item;
        });
      }
      let elementData = {};
      let validation = Validation(elementData, item);
      let { Check } = validation.ValidateBox();
      setErrorMessage(Check);
      return product;
    });
    setboard(newboard);
  };
  useEffect(() => {
    let elementData = {};
    let validation = Validation(elementData, ElementSetting);
    let { Check } = validation.ValidateBox();
    setErrorMessage(Check);
  }, [options]);
  useEffect(() => {
    if (message[elementid]?.result === false) {
      setErrorMessage(message);
    }
  }, [message]);
  const temp=[{id:1,value:"option 1"},{id:2,value:"option 2"},{id:3,value:"option 3"}]

  const labelName = board.find((item) => item?.elementid === elementid).name;

  return (
    <div
      className={previewFlag && GetStyleClasses(elementid) ||  ""}
      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      {/* {label ? (
        <span>{label}</span>
      ) : (
        <span>Write up question for checkboxes</span>
      )} */}
    <span>{copy ? `${label}-copy`: label ? label : "Write up question for checkboxes"}</span> 

      {required ? <span style={{ color: "red" }}>*</span> : ""}

      {ElementSetting?.options ? (
        ElementSetting?.options.map((item, i) => {
          // //("item", item)
          return (
            <div className="mt-1" key={i}>
              <Controller

                control={control}
                name={elementid}
                rules={{
                  required: {
                    value: required === true ? true : false,
                    message: "This field is required",
                  },
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={item?.id}
                    value={item?.value}
                    {...register(elementid)}
                  />
                )}
              />
              <label

                className="form-check-label text-secondary ms-1"
                htmlFor={item?.value}
              >
                {item?.value}
              </label>

              {/* <input
                className="form-check-input"
                type="checkbox"
                id={item?.id}
                defaultChecked={item?.isCheck}
                onChange={(event) => {
                  handelCheck(event, ElementSetting);
                }}
              />
              <label
                className="form-check-label text-secondary ms-1"
                htmlFor={item?.id}
              >
                {item?.value}
              </label> */}
            </div>
          );
        })
      ) : (
        <>
       
              <Controller 
                control={control}
                name={elementid}
              
                render={({ field: { onChange, value, ref } }) => (
                  <div className="mt-2">
                 
                </div>
                )}
              />

              <label
                className="form-check-label text-secondary "
                htmlFor="check-1"
              >
                Please Select an option or Add option
              </label>
                  


        {/* <Controller
                control={control}
                name={elementid}
               
                render={({ field: { onChange, value, ref } }) => (
                  <div className="mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="check-1"
                    // value="option 1"
                    {...register(elementid)}
                  />
                </div>
                )}
              />
               <label
              className="form-check-label text-secondary "
              htmlFor="check-2"
            >
              Checked checkbox
            </label> */}

          {/* <div className="">
            <input className="form-check-input" type="checkbox" id="check-2" />
            <label
              className="form-check-label text-secondary ms-1"
              htmlFor="check-2"
            >
              Checked checkbox
            </label>
          </div>
          <div className="">
            <input className="form-check-input" type="checkbox" id="check-3" />
            <label
              className="form-check-label text-secondary ms-1"
              htmlFor="check-3"
            >
              Checked checkbox
            </label>
          </div> */}
        </>
      )}

      {/* <div className="mt-2">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked
          id="check-1"
        />
        <label
          className="form-check-label text-secondary ms-1"
          htmlFor="check-1"
        >
          Default checkbox
        </label>
      </div> */}

      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
      {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
    </div>
  );
};
