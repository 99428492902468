import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import { useParams } from "react-router-dom";
import { Loaderr } from "../../../components/Loaderr";
import logo from "../../../assets/images/LOGO-BLUE.png";


const GetForm = gql`
query FormData($formId: String) {
  formData(form_id: $formId) {
    form_id
    form_name
    form_description
    base_form_template
    status
  }
}`;

function FormEntries() {
  const navigate = useNavigate();
  const parms = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { GetFormEntries, FormEntry, DeleteFormEntry,GetFormData } = useFormApiHelper();
  let { formEntriesLoading, formEntriesError, formEntries } =
    GetFormEntries(parms);
  const {
    deleteFormEntry,
    deleteFormEntryLoading,
    deleteFormEntryError,
    deleteFormEntryData,
  } = DeleteFormEntry();
  const {formData, formDataLoading, formDataError} = GetFormData(parms);
  console.log("formEntries", formEntries, formEntriesError);
  const handelEntryView = (id) => {
    navigate(`/forms/${parms.id}/entries/${id}`);
  };
  const handelEntryDelete = (id) => {
    deleteFormEntry({
      variables: {
        formEntryId: id,
        formId: parms.id,
      },
    });
  };
  console.log("formEntry", formData?.formData);

 
  useEffect(() => {
    if (deleteFormEntryData?.DeleteFormEntry?.form_entry_id) {
      window.location.reload();
    }
  }, [deleteFormEntryData]);

  const filteredEntries = formEntries?.formEntries?.filter((entry) => {
    var dateAsInt = parseInt(entry?.entry_created_date, 10);
    const newDate = new Date(dateAsInt);
    const formattedDate = newDate.toISOString().split('T')[0];
  
    const startDateFilter = !startDate || formattedDate >= new Date(startDate).toISOString().split('T')[0];
    const endDateFilter = !endDate || formattedDate <= new Date(endDate).toISOString().split('T')[0];
  
    const leadIdMatches = entry?.form_entry_data[0]["InputField-Follow-up-boss-lead-id"]
      ?.toString()
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
  
    const lastNameMatches = entry?.form_entry_data[0]["InputField-Client-Last-Name"]
      ?.toString()
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    
    const oppKeyMatches = entry?.form_entry_data[0]["InputField-opp-key"]
    ?.toString()
    .toLowerCase()
    .includes(searchQuery.toLowerCase());
  
    return startDateFilter && endDateFilter && ( leadIdMatches || lastNameMatches || oppKeyMatches );
  });

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
   
  if (formEntriesLoading || deleteFormEntryLoading) return <Loaderr />;

  const convertDate = (entryDate) => {
    var dateAsInt = parseInt(entryDate, 10);  
    const newDate = new Date(dateAsInt);
    const formattedDate = newDate.toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
    return formattedDate;
  };

  return (
    <>
    <header className="header bg-white border-bottom" id="header">
      <div>
        <div className="pointer" onClick={() => navigate("/forms/")}>
          <div className="d-flex align-items-center ms-2">
            <span className="navbar-brand m-0 p-0">
              <img className="img-fluid" src={logo} width="30" alt="" />
            </span>
            <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
            <span className="p-0 m-0 text-decoration-underline">Form Dashboard</span>
          </div>
        </div>
      </div>
    </header>
    <div className="main fmc-main p-0">
      <section className="container-lg container-fluid pt-5">
        {/* <b
          className="text-secondary pointer"
          onClick={() => navigate(`/forms`)}
        >
          <i className="iconify me-2" data-icon="mdi:arrow-left" />
          Back to form list
        </b> */}
        <div className="p-4 bg-white border rounded-3 mb-3 mt-3">
          <h5>
            <i className="iconify me-2" data-icon="tdesign:form" />
            {formData?.formData?.form_name ? formData?.formData?.form_name : "No Form Name"}
          </h5>
          <hr className="hr op-10" /> 
          <p className="text-secondary mt-2 m-0">
            <span className="text-dark me-2">Form Description:</span>{formData?.formData?.form_description }
          </p>
          <p className="text-secondary mt-2 m-0">
            <span className="text-dark me-2">Form Type:</span>{formData?.formData?.form_type }
          </p>
          <p className="text-secondary mt-2 m-0">
            <span className="text-dark me-2">Form template:</span>{formData?.formData?.base_form_template === "" ?  "Custom Form" : formData?.formData?.base_form_template }
          </p>
          <hr className="hr op-10" />
          <div className="d-flex mt-3">
            <div className=" pe-5 border-end text-center">
              <span>Total entries</span>
              <h3 className="mt-2">{formEntries?.formEntries?.length}</h3>
            </div>
            <div className="px-5 border-end text-center">
              <span>Today</span>
              <h3 className="mt-2">0</h3>
            </div>
          </div>
        </div>
          <div className="d-md-flex align-items-center">
            <div className="col-md-6 col-lg-7 col-xxl-4">
              <label className="small">Search</label>
              <input
                className="form-control input-bn search-fgy mb-3"
                placeholder="search entries"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="d-flex align-items-end justify-content-md-end input-group mb-3">
              <i class="iconify me-2 my-auto fs-5" data-icon="mdi:table-filter"></i>
              <div>
                <label className="small">Start date</label>
                <input
                  className="form-control input-bn search-fgy dy-filt"
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <hr className="col-1 mb-4 my-auto" />
              <div>
                <label className="small">End date</label>
                <input
                  className="form-control input-bn search-fgy dy-filt rounded-0"
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              <div className="btn btn-primary fs-6 d-flex align-items-center justify-content-center search-fgy">
                <i className="iconify" data-icon="mdi:calendar" />
              </div>
            </div>
          </div>
          {filteredEntries.length === 0 ? (
        <div className="text-left mt-4">No match found</div>
          ) : (
            <section className="d-flex flex-column py-4 gap-3">
              <div className="d-md-flex flex-wrap border rounded-3 d-none fm-list-container-thead">
                <div className="d-flex flex-wrap col-12 col-md-3 align-items-center justify-content-between pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
                  Entries
                </div>
                <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                  Opp Type
                </div>
                <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                  FUB Lead ID
                </div>
                <div className="d-flex align-items-center justify-content-center px-4 col col-md-4 border-0 border-end border">
                  Opp Key
                </div>
                <div className="d-flex align-items-center justify-content-center px-4 col col-md-1"></div>
              </div>
              {filteredEntries?.map((entry, index) => (
                <div
                  className="d-flex flex-wrap border rounded-3 bg-white fm-list-container"
                  key={index}
                >
                  <div className="d-flex flex-wrap col-12 col-md-3 align-items-center justify-content-between pb-2 pt-4 py-md-4 px-4 border-0 border-end border">
                    <p className=" fs-6 p-0 m-0">
                      {convertDate(entry?.entry_created_date)}  
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center p-4 gap-1 col col-md-2 border-0 border-end border">
                    <p>{entry?.form_entry_data[0]["DropDown-client-type"]}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center p-4 gap-1 col col-md-2 border-0 border-end border">
                    <p>{entry?.form_entry_data[0]["InputField-Follow-up-boss-lead-id"]}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center p-4 gap-1 col col-md-4 border-0 border-end border">
                  <p>{entry?.form_entry_data[0]["InputField-opp-key"]}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center p-4 col col-md-1">
                    <span
                      className="text-decoration-underline pointer"
                      onClick={() => handelEntryView(entry.form_entry_id)}
                    >
                      view
                    </span>
                  </div>
                </div>
              ))}
            </section>
        )}
      </section>
    </div>
    </>
  );
}

export default FormEntries;
