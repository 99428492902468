const isValidEmail = (elementData, ElementSetting) => {
  const { maxValue, predefine, minValue } = ElementSetting;
  let Max
  
  if(maxValue==undefined){
    Max=30
  }else if(maxValue== ""){
    Max=30
  }else{
    Max=maxValue
  }


  let emailValidationResult = {};
  let email = elementData.email;
  let emailRegex = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,4}/;
  //onsole.log("email",email?.length)
 // console.log("jon",email?.length >= Max)
  //console.log("test",emailRegex?.test(email))
  if (email?.length >=1&& !emailRegex?.test(email)) {
    emailValidationResult = {
      result: false,
      message: "Email the is not valid",
    };
  } if(email?.length >= Max){
    emailValidationResult = {
      result: false,
      message: "You have reached the maximum length",
    };
  }
  return emailValidationResult;
};



export { isValidEmail };
