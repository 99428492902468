const isValidDate = (elementData) => {
    let DateValidationResult = {};
    let date = elementData.date;
    let DateRegex =  /^[0-9]{2}[\/]{0}[0-9]{2}[\/]{1}[0-9]{4}$/g;
    if (DateRegex.test(date)) {
      DateValidationResult = {
        result: true,
        message: "",
      };
    }
    else if (date === "") {
      DateValidationResult = {
        result: false,
        message: "Date is required",
      };
    }
    else {
      DateValidationResult = {
        result: true,
        message: "",
      };
    }
    return DateValidationResult;
  };
  
  const isDateRequired = (elementData, elementSettings) => {
    let DateValidationResult = {};
    
    if (elementData?.date?.length === "") {
      DateValidationResult = { result: false, message: "Date is required" };
    } else {
      DateValidationResult = { result: true, message: "" };
    }
    return DateValidationResult;
  };
  
  
  export {isValidDate,isDateRequired};
  