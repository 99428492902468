import { useContext, useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Element from "./BoardElement";
import { boardConetxt } from "../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./form-builder-components/BoardElementsUI/BoardElementsSettingsUi/GetStyleClasses";
// import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import useFormApiHelper from "../utils/useFormApiHelper";

function DropBoard({ getFormData }) {
  const ref = useRef();
  const params = useParams();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);
  const { board, setboard } = useContext(boardConetxt);

  const { GetFormData, UpdateForm } = useFormApiHelper();
  const { updateForm } = UpdateForm();

  const { formDataLoading, formDataError, formData } = GetFormData(params);

  useEffect(() => {
    console.log("formData>", formData);
    setboard(() => formData?.formData?.form_fields);
  }, [formData?.formData]);

  useEffect(() => {
    if (board?.length > 0 ) {
      updateForm({
        variables: {
          formId: params.id,
          formData: {
            ...formData?.formData,
            form_fields: board?.map((item) => item?.elementid),
            form_conditions: getFormData?.form_conditions,
            form_name: getFormData?.form_name,
            form_description: getFormData?.form_description,
            status: true,
          },
        },
      });
    }
  }, [board]);

  let SaveStatus;
  if (formDataLoading) {
    SaveStatus = (
      <span
        className="text-secondary d-flex align-items-center"
        style={{ fontSize: "11px" }}
      >
        {" "}
        <i className="bx bx-loader-circle fs-4"></i>{" "}
        <span className="d-none d-lg-block">Saving</span>
      </span>
    );
  } else {
    SaveStatus = (
      <span
        className="d-flex align-items-center"
        style={{ fontSize: "11px", color: "green" }}
      >
        <i className="bx bx-check-double fs-4"></i>{" "}
        <span className="d-none d-lg-block">Form Saved</span>
      </span>
    );
  }

  return (
    <>
      <div className="mx-auto bg-white p-4 p-xl-5 mb-5 rounded-3 border">
        <div className="w-100 d-flex justify-content-between m-0 mb-3">
          <p></p>
          <span>{board?.length ? SaveStatus : ""}</span>
        </div>
        <div
          // className="mx-auto bg-white p-4 p-xl-5 mb-5 rounded-3 border"
          ref={ref}
        >
          <Droppable droppableId="dropboard">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}
              style={{minHeight:"400px"}}
              >
                <div className="container">
                  <div className="row">
                    {board?.length > 0
                      ? board.map((element, index) => {
                          //console.log("DropItem",element);
                          return (
                            <Draggable
                              key={String(element?.elementid)}
                              draggableId={String(element?.elementid)}
                              index={index}
                            >
                              {/* className="col-md-6"  */}
                              {(provided) => (
                                <div
                                  className={GetStyleClasses(element.elementid)}
                                  // className="fm-comp"

                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Element element={element} />
                                  {/* {provided.placeholder} */}
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      : null}
                  </div>
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          {board?.length > 0 && (
            <div className="d-flex justify-content-end">
              {/* <div
            className="d-flex align-items-center btn btn-primary"
            onClick={() => saveHandler()}
          >
            <span>Continue</span>
          </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DropBoard;
