import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions';
import { HeadingUi } from './HeadingUi';

export const DndHeadingUi = ({elementid,element,oppFields}) => {
   const {board,setboard}= useContext(boardConetxt);
   const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const {position} = ElementSetting;
  // let styles;
  // if (position == "left" || position=="right"){
  //   styles={textAlign:`${position}`}
  // }else if(position=='center'){
  //   styles={textAlign:'center'}
  // }

  return (
    <div style={{textAlign:`${position}`}}>
        <ElementsSettingsWidget
            elementid={elementid}
            board={board}
            setboard={setboard}
            BoardDeleteHandler={BoardDeleteHandler}
            element={element}
            />
            <HeadingUi elementid={elementid} oppFields={oppFields}/>
          
    </div>
  )
}
