const isValidInput = (elementData, ElementSetting) => {
 // console.log("elementData", ElementSetting);
  const { maxValue, required,minValue  } = ElementSetting;
  let Max
  if(maxValue==undefined){
    Max=30
  }else if(maxValue== ""){
    Max=30
  }else{
    Max=maxValue
  }
//console.log("maxValue",Max)
  let inputValidationResult = {};
  let input = elementData.input;
  //console.log("input",input?.length)
  let inputRegex = /^[\w ]+[a-zA-Z0-9_~!@#$%^&*()+={}[]:;'"><.,?]*$/;
  if (!inputRegex.test(input)) {
    inputValidationResult = {
      result: true,
      message: "",
    };
  } if(input?.length >= 1 &&input?.length >= Max) {
    inputValidationResult = {
      result: false,
      message: "You have reached your maximum limit of characters allowed",
    };
  }
  return inputValidationResult;
};

const isInputRequired = (elementData, ElementSetting) => {
  const { required } = ElementSetting;

  let inputValidationResult = {};

  if (required && elementData?.input?.length === 0) {
    inputValidationResult = {
      result: false,
      message: "Input Field is required",
    };
  } else {
    inputValidationResult = { result: true, message: "" };
  }
  return inputValidationResult;
};

export { isInputRequired, isValidInput };
