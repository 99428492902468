import PropTypes from "prop-types";
import React from "react";

import { Draggable } from "react-beautiful-dnd";

function DragableElement({
  id,
  index,
  element,
  settings,
  show,
  isDragDisabled = false,
}) {
  console.log("DragableElement", id, index);
  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <React.Fragment>
          <div
            id={id}
            settings={settings}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              transform: snapshot.isDragging
                ? provided.draggableProps.style?.transform
                : "translate(0px, 0px)",
            }}
          >
            {element}
            {provided.placeholder}
          </div>
          {show
            ? null
            : snapshot.isDragging && (
                <div style={{ transform: "none !important" }}>{element}</div>
              )}
        </React.Fragment>
      )}
    </Draggable>
  );
}

DragableElement.propTypes = {
  // id: PropTypes.string.isRequired,
  // index: PropTypes.number.isRequired,
  // element: PropTypes.element,
};

export default DragableElement;
