import React, { useContext } from 'react'

import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper';
import { RadiobuttonUi } from './RadiobuttonUi';


export const DndRadioBtnUi = ({elementid,element,oppFields}) => {
   const {board,setboard}=useContext(boardConetxt);
  return (
    <div elementid={elementid}>
    <ElementsSettingsWidget
    BoardDeleteHandler={BoardDeleteHandler}
    elementid={elementid}
    board={board}
    setboard={setboard}
    element={element}
    />
    <RadiobuttonUi elementid={elementid} oppFields={oppFields}></RadiobuttonUi>
    </div>
    
  )
}
