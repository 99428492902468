import React from "react";
import { useState, useCallback, useEffect, useContext } from "react";
import FilterBlocks from "./FilterBlocks";
import CustomArray from "../../../components/fields/CustomArray";
import ArrPng from "../../../assets/images/arr.png";
import TestFilterAction from "../../../actions/TestFilterAction";
import { WorkFlowSessionInfo } from "./EditWorkflow";
const optionsList =
  {
    "Continue if tag exists": {
      "description": "Continue if call was created by an Assigned Agent",
      "triggers": ['peopleCreated', 'peopleUpdated', 'peopleTagsCreated', 'peopleStageUpdated', 'peopleRelationshipCreated', 'peopleRelationshipUpdated', 'peopleRelationshipDeleted', 'notesCreated', 'notesUpdated', 'emailsCreated', 'emailsUpdated', 'emailsDeleted', 'appointmentsCreated', 'appointmentsUpdated', 'appointmentsDeleted', 'textMessagesCreated', 'callsCreated','callsUpdated', 'dealsCreated', 'dealsUpdated', 'dealsDeleted']
    },
    "Continue if tag doesn't exists": {
      "description": "Continue if call was created by an Assigned Agent",
      "triggers": ['peopleCreated', 'peopleUpdated', 'peopleTagsCreated', 'peopleStageUpdated', 'peopleRelationshipCreated', 'peopleRelationshipUpdated', 'peopleRelationshipDeleted', 'notesCreated', 'notesUpdated', 'emailsCreated', 'emailsUpdated', 'emailsDeleted', 'appointmentsCreated', 'appointmentsUpdated', 'appointmentsDeleted', 'textMessagesCreated', 'callsCreated', 'callsUpdated', 'dealsCreated', 'dealsUpdated','dealsDeleted']
    },
    "Continue if Call Made by Assigned Agent": {
      "description": "Continue if call was created by an Assigned Agent",
      "triggers": [ 'callsCreated', 'callsUpdated']
    },
    "Continue if Text Made by Assigned Agent": {
      "description": "Continue if Text was created by an Assigned Agent",
      "triggers": ['textMessagesCreated']
    },
    "Continue if Email Made by Assigned Agent": {
      "description": "Continue if Email was created by an Assigned Agent",
      "triggers": ['emailsCreated', 'emailsUpdated', 'emailsDeleted']
    },
    "Continue if Incoming Email": {
      "description": "Continue if Incoming Email",
      "triggers": ['emailsCreated', 'emailsUpdated', 'emailsDeleted']
    },
    "Continue if Email Sent by ActionPlan": {
      "description":  "Continue if Email Sent by ActionPlan",
      "triggers": ['emailsCreated', 'emailsUpdated', 'emailsDeleted']
    },
    "Continue if Outgoing Email": {
      "description": "Continue if Outgoing Email",
      "triggers": ['emailsCreated', 'emailsUpdated', 'emailsDeleted']
    },
    "Continue if Incoming Text": {
      "description": "Continue if Incoming Text",
      "triggers": ['textMessagesCreated']
    },
    "Continue if Outgoing Text": {
      "description": "Continue if Outgoing Text",
      "triggers": ['textMessagesCreated']
    },
    "Continue if Text Sent by ActionPlan": {
      "description": "Continue if Text Sent by ActionPlan",
      "triggers": ['textMessagesCreated']
    },
    // "Continue if Call Made by ActionPlan": {
    //   "description": "Continue if Call Made by ActionPlan",
    //   "triggers": ['callsCreated', 'callsUpdated']
    // },
    "Continue if Incoming Call": {
      "description": "Continue if Incoming Call",
      "triggers": ['callsCreated','callsUpdated']
    },
    "Continue if Outgoing Call": {
      "description": "Continue if Outgoing Call",
      "triggers": ['callsCreated','callsUpdated']
    },

  }

export default function AdvancedFilterBlock({
  action_index,
  action_info,
  data_mapper,
  UpdateAction,
  workflowHelper,
  setSelected_action,
  selected_action,
  action_id,
  triggerdetails,
  setTriggerdetails,
}) {
  const [show, setShow] = React.useState(true);
  const [testAction, setTestAction] = useState(null);
  const [filterType, setFilterType] = useState(
    action_info?.meta_data?.filterType
      ? action_info?.meta_data?.filterType
      : "template"
  );
  const [selectAutomation, setSelectAutomation] = useState(
    action_info?.meta_data?.automation_name
      ? action_info?.meta_data?.automation_name
      : ""
  );
  const [showDetails, setShowDetails] = useState(false);
  const [testAgain, settestAgain] = useState(true);
  const {session} = useContext(WorkFlowSessionInfo);
  const handleState = () => {
    show ? setFilterType("advance") : setFilterType("template");
    setShow(!show);
  };
  const [selected_modal_id, select_modal_id] = useState("");
  const select_modal = useCallback((field_id) => {
    select_modal_id(field_id);
  }, []);
  const [test_result, set_test_result] = useState(session?.[action_id]?.action_status === "SUCCESS" ? false : true);
  const getAutomationOptions = () => {
    let options = [];
    for (const [key, value] of Object.entries(optionsList)) {
      // if trigger details["triggertype"] is in value.triggers only then show the option
      if (value.triggers.includes(triggerdetails?.data_mapper?.type)) {
      options.push(
        <option value={key} key={key}>
          {key}
        </option>
      );
    }
  }
    return options;
  };

  useEffect(() => {
    UpdateAction({
      meta_data: {
        automation_name: selectAutomation,
        filterType: filterType,
      },
      test_status: "Not Tested",
    });
  }, [selectAutomation, filterType]);

  useEffect(() => {
    if(session?.[action_id]){
      setTestAction("test_action_again")
      setShowDetails(true)
    }
    else{
      setShowDetails(false)
    }
  }
  , []);

  useEffect(() => {
    session?.[action_id]?.action_status === "SUCCESS" ? set_test_result(false) : set_test_result(true)
  }, [session]);

  return (
    <>
      <>
        <div className="d-flex justify-content-center">
          <img height="70" src={ArrPng} alt="" />
        </div>
        {show && filterType === "template" ? (
          <div className="dot-border bg-white rounded-3 p-4 mb-5 border">
            <div className="w-100 d-flex justify-content-between">
              {!action_info.is_default ? (
                <p
                  className="text-decoration-underline wait text-secondary small"
                  onClick={() => {
                    workflowHelper?.change_action_to_unknown(action_id);
                  }}
                >
                  <i className="iconify" data-icon="mdi:arrow-left"></i>Change
                </p>
              ) : (
                <p></p>
              )}
              <span className="pointer" onClick={() => workflowHelper?.remove_action(action_id)}>
                <i
                  className="iconify p-0 fs-5 text-secondary wait"
                  data-icon="mdi:trash"
                ></i>
              </span>
            </div>
            <h6 className="w-100 mb-3">
            <strong> {action_index+". "}</strong> 
              <i className="iconify" data-icon="mdi:filter-cog" />
              Filter Template
            </h6>

            {selected_action !== action_info.action_id ? (
              <>
                <div className="mb-4 mt-2"
                 onClick={() => setSelected_action(action_info.action_id)}
                >
                  <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-center p-3 border-0 alert-primary col text-dark form-control-sm">
                      {selectAutomation
                        ? "Selected Filter Template : " + selectAutomation
                        : "Select a filter template"}
                      <i
                        className="iconify fs-5"
                        data-icon="akar-icons:settings-horizontal"
                      ></i>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mb-4 mt-2">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-center p-3 border-0 alert-primary col text-dark form-control-sm">
                      {selectAutomation
                        ? "Selected Filter Template : " + selectAutomation
                        : "Select a filter template"}
                      <i
                        className="iconify fs-5"
                        data-icon="akar-icons:settings-horizontal"
                      ></i>
                    </div>
                  </div>
                  <select
                    placeholder="Select Tag"
                    className="form-control form-control-sm"
                    required
                    name="select_automation"
                    value={selectAutomation}
                    onInput={(e) => {
                      setSelectAutomation(e.target.value);
                    }}
                  >
                    <option value="" selected hidden>
                      Selected Filter Template
                    </option>

                    {getAutomationOptions()}
                  </select>
                </div>
                {selectAutomation === "Continue if tag exists" && (
                  <div
                    href="workflow-3.html"
                    className="alert alert-primary filt-bg m-0 text-dark w-100"
                  >
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      <span>Continue: IF All of the below Tags exist</span>
                      <i
                        className="iconify text-secondary ms-2"
                        data-icon="mdi:info-circle"
                      />
                    </div>
                    <br />
                    <div>
                      <CustomArray
                        action_id={action_id}
                        select_modal={select_modal}
                        selected_modal_id={selected_modal_id}
                        UpdateAction={UpdateAction}
                        field_key="tags"
                        data_mapper={data_mapper}
                      />
                    </div>
                  </div>
                )}
                {selectAutomation === "Continue if tag doesn't exists" && (
                  <>
                    <div className="d-flex justify-content-between mb-4">
                      <div
                        href="workflow-3.html"
                        className="alert alert-primary filt-bg m-0 text-dark w-100"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Continue: IF All of the Below Tags don't exist</span>
                          <i
                            className="iconify text-secondary ms-2"
                            data-icon="mdi:info-circle"
                          />
                        </div>
                        <br />
                    <div>
                      <CustomArray
                        action_id={action_id}
                        select_modal={select_modal}
                        selected_modal_id={selected_modal_id}
                        UpdateAction={UpdateAction}
                        field_key="tags"
                        data_mapper={data_mapper}
                      />
                    </div>
                      </div>
                      <div>
                        <i
                          className="iconify ms-3 mt-2 fs-5"
                          data-icon="akar-icons:settings-horizontal"
                        />
                      </div>
                    </div>
                  </>
                )}
                {selectAutomation && (
                <div className="w-100 mt-4  modal-header">
              <div
                className="d-flex align-items-center pointer"
                onClick={() => {
                  testAction === "tested_action" ? setTestAction("test_action_again") :  setTestAction("tested_action");
                }}
              >
                <i
                  className="iconify p-0 fs-3 me-1 text-primary"
                  data-icon="ic:round-network-check"
                ></i>
               <span className="d-none d-md-block text-decoration-underline"
                  onClick={()=> {setShowDetails(false)
                    settestAgain(true)   
                    }}
                  >Test Filter
                </span>
                { test_result && (
                  <p className="text-danger m-3">
                    Please test the filter!
                  </p> )}
              </div>
              {testAction && testAction !== "test_action" && (
                <button
                type="button"
                className=" dropdown-toggle mt-3 btn-outline"
                data-bs-toggle="collapse"
                data-bs-target="#test_fub_automation"
                onClick={() => {setShowDetails(!showDetails)
                  setTestAction("test_action_again")
                  settestAgain(false)   
              }}
              >
                {showDetails ? "Show previous test details" : "Hide" }
              </button>
                 )}
            </div>
              )}

               {testAction === "tested_action" && (
                <div>
                  <TestFilterAction
                    triggerdetails={triggerdetails}
                    setTriggerdetails={setTriggerdetails}
                    testAction={testAction}
                    setTestAction={setTestAction}
                    action_id={action_id}
                    set_test_result={set_test_result}
                    setShowDetails={setShowDetails}
                    selected_action={selected_action}
                    testAgain={testAgain}
                  />
                </div>
                )}
              {!showDetails && testAction === "test_action_again" && (
                  
                  <div
                  id="test_fub_automation"
                  className={`${showDetails ?  "show accordion-collapse collapse" : ""  }`}
                  >
                  <TestFilterAction
                    triggerdetails={triggerdetails}
                    setTriggerdetails={setTriggerdetails}
                    testAction={testAction}
                    setTestAction={setTestAction}
                    action_id={action_id}
                    set_test_result={set_test_result}
                    setShowDetails={setShowDetails}
                    selected_action={selected_action}
                    testAgain={testAgain}
                  />
                  </div>
                )}
                  <div className="mt-4"
                  >
                  <div
                    className="w-100 text-decoration-underline pointer"
                    onClick={handleState}
                  >
                    Advanced filters
                    <i
                      className="iconify text-secondary ms-2"
                      data-icon="mdi:info-circle"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <FilterBlocks
            action_index={action_index}
            action_info={action_info}
            data_mapper={data_mapper}
            UpdateAction={UpdateAction}
            workflowHelper={workflowHelper}
            setSelected_action={setSelected_action}
            selected_action={selected_action}
            action_id={action_id}
            triggerdetails={triggerdetails}
            setTriggerdetails={setTriggerdetails}
            handleState={handleState}
          />
        )}
      </>
    </>
  );
}
