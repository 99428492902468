import React, { useRef } from "react";
import {
  boardConetxt,
  conditionContext,
} from "../../../pages/form-builder/dashboard/ContextHelper";
import { useContext, useState } from "react";
import AddConditions from "../../../components/form-builder-components/FormConditions/AddConditions";
import { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useMutation, gql, useQuery } from "@apollo/client";
import { Loaderr } from "../../../components/Loaderr";

function ConditionCreate() {
  console.log("ConditionCreate File");
  const { board, setboard } = useContext(boardConetxt);
  const { condition, setCondition } = useContext(conditionContext);
  const params = useParams();
  const location = useLocation();
  let initial = {
    condtions: [
      [
        {
          data_type: board?.[0]?.type ? board[0].type.toLowerCase() : "",
          expected_value: "",
          operator: "equals",
          field_id: board?.[0]?.elementid ? board[0].elementid : "",
        },
      ],
    ],
    Visibility: "show",
    Fields: [],
    ConditionName: "conditionName",
  };

  const [conditionName, setConditionName] = useState("");
  // console.log("location.state.conditionLength", location.state.conditionLength);
  const [conditionsIndex, setConditionsIndex] = useState(
    // location.state.conditionLength !== undefined
    // ? location.state.conditionLength
    // : location.state.index
    params.condition_index
  );
  const [initCondition, setInitCondition] = useState(
    location.state?.conditionLength !== undefined
      ? initial
      : condition[location.state.index]
  );
  console.log(" >>>", params);

  // console.log(
  //   "condition init",
  //   initCondition,
  //   // location.state.conditionLength,
  //   condition[location.state.index]
  // );

  const saveHandler = () => {
    console.log("save codition to DB", condition);
    updateForm({
      variables: {
        formId: params.id,
        formData: {
          ...formData.formData,
          form_conditions: condition,
        },
      },
    });
    // makeApiCall("POST", "user/save_conditions/", condition).then((data) => {
    //   console.log("data", data);
    // });
  };

  const handelNameEdit = (event) => {
    setInitCondition((prev) => {
      let newCondition = { ...prev };
      newCondition.ConditionName = event.target.value;
      return newCondition;
    });
  };

  useEffect(() => {
    setCondition((prev) => {
      let newCondition = [...prev];
      newCondition[conditionsIndex] = initCondition;
      return newCondition;
    });
    console.log("condition---------------------", condition);
  }, [initCondition]);

  const FormData = gql`
    query FormData($formId: String) {
      formData(form_id: $formId) {
        form_id
        form_name
        form_description
        form_fields
        form_conditions
        status
        form_created_date
        form_updated_date
        update_by
      }
    }
  `;
  const {
    loading: formDataLoading,
    error: formDataError,
    data: formData,
  } = useQuery(FormData, {
    variables: { formId: params.id },
    onCompleted: (data) => {
      console.log("data", data);
      setboard(() => data.formData.form_fields);
      setCondition(() => data.formData.form_conditions);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
  console.log("board null", board);
  const navigate = useNavigate();
  // update condition using mutauion
  const UpdateForm = gql`
    mutation UpdateForm($formId: String, $formData: GraphQLJSON) {
      UpdateForm(form_id: $formId, form_data: $formData) {
        team_id
        form_id
      }
    }
  `;
  const [
    updateForm,
    {
      loading: updateFormLoading,
      error: updateFormError,
      data: updateFormData,
    },
  ] = useMutation(UpdateForm, {
    onCompleted: (data) => {},
  });
  useEffect(() => {
    console.log("formData condition", formData,location.state.conditionLength);
    initial = {
      condtions: [
        [
          {
            data_type: board?.[0]?.type ? board[0].type.toLowerCase() : "",
            expected_value: "",
            operator: "equals",
            field_id: board?.[0]?.elementid ? board[0].elementid : "",
          },
        ],
      ],
      Visibility: "show",
      Fields: [],
      ConditionName: "conditionName",
    };
    console.log("initial", initial);
    setInitCondition(() =>
      location?.state?.conditionLength !== undefined
        ? condition[conditionsIndex] === undefined ? initial : condition[conditionsIndex]
        : condition[conditionsIndex]
    );
  }, [board]);
  console.log("ConditionCreate >>", initCondition, condition, conditionsIndex);
  if (formDataLoading || updateFormLoading) return <Loaderr />;

  return (
    <div className="main fmc-main">
      <section className="fm-condition">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5" />
              <i className="bx bx-arrow-to-right me-2 fs-5" />
              <i className="bx bx-desktop me-2 fs-5" />
            </div>
            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens
              are not efficient. To learn more click here
            </span>
          </div>
        </div>
        <section className="col-xxl-6 col-lg-7 col-md-9 mb-5 pb-5 col-12 mx-auto">
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 py-4 border-bottom">
            <div
              className="m-0 text-decoration-underline pointer"
              onClick={() => navigate(`/forms/conditions/${params.id}`)}
            >
              <i className="iconify" data-icon="mdi:chevron-left" />
              Go back
            </div>
            <input
              type="button"
              disabled={updateFormLoading}
              className="d-flex align-items-center btn btn-primary"
              onClick={() => saveHandler()}
              value="Save"
            />
          </div>
          <div className="input-group align-items-center mb-3 mt-4">
            <i className="iconify fs-4" data-icon="carbon:branch" />
            {initCondition?.ConditionName && (
              <input
                placeholder="Untitled Condition"
                onChange={(e) => handelNameEdit(e)}
                className="form-control input-lbn input-title"
                type="text"
                value={initCondition.ConditionName}
                required
                maxLength={30}
              />
            )}
            {/* <i class="iconify input-title-edit fs-4" data-icon="mdi:pencil"></i> */}
          </div>
          <div>
            {condition && initCondition?.condtions && (
              <AddConditions
                initCondition={initCondition}
                setInitCondition={setInitCondition}
                conditionsIndex={conditionsIndex}
                condition={condition}
                setCondition={setCondition}
              />
            )}
          </div>
        </section>
      </section>
    </div>
  );
}

export default ConditionCreate;
