import React from 'react'

export default function ThankyouPage() {
  return (
    <div style={{textAlign:"center",fontSize:"50px"}}>
      <p>InterFace</p>
      <h1>Thank you for submitting the form!</h1>
    </div>
  );
};




