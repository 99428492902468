import { useState, useEffect, useContext, useCallback } from "react";
import { Droppable } from "react-beautiful-dnd";
import DragableElement from "../../../components/DragableElement";
import { ElementsUIGenrate } from "../../../components/ElementsUIGenrate";
import { ElemContext, boardConetxt } from "./ContextHelper";
import useFormApiHelper from "../../../utils/useFormApiHelper";

const FormBuilderRightSideBar = ({formType}) => {
  const { board } = useContext(boardConetxt);
  const { CustomElement, setCustomElement, defaultElement, setDefaultElement } =
    useContext(ElemContext);
  const [value, setValue] = useState("");
  const { GetFormFields } = useFormApiHelper();
  const { FormFieldsData, refetch } = GetFormFields();

  console.log("FormFieldsData", FormFieldsData)
  useEffect(()=>{
    const data=FormFieldsData?.GetFieldsByTeamId?.map((item)=>{
      if(item.field_sub_type===formType.split(" ")[0].toLowerCase()){
        return item
      }
    })
    console.log(">>MM",data)
  },[])
  useEffect(() => {
    let defaultList = [];
    let customList = [];
    const tempdata = FormFieldsData?.GetFieldsByTeamId?.map((item) => {
       console.log("item<>",formType.split(" ")[0].toLowerCase()==item?.field_sub_type?.toLowerCase())
      
      if (formType.split(" ")[0].toLowerCase()==item?.field_sub_type?.toLowerCase() && item.field_type === "default") {
        const str = item.field_id.split("-")[0];
        const obj = {
          elementid: item.field_id,
          name: item.field_name,
          type: item.input_type || "InputField",
          settings: item.settings == null ? {} : item.settings,
        };
        defaultList.push(obj);
        // console.log("obj",obj)
      } else if (formType.split(" ")[0].toLowerCase()==item?.field_sub_type?.toLowerCase() && item.field_type === "custom") {
        // console.log("item<>",[item])
        const str = item.field_id.split("-")[0];

        const obj = {
          elementid: item.field_id,
          name: item.field_name,
          type: item.input_type || "InputField",
          // settings:{}
          settings: item.settings == null ? {} : item.settings,
        };
        customList.push(obj);
      }
    });
    setDefaultElement(defaultList);
    setCustomElement(customList);
  }, [FormFieldsData?.GetFieldsByTeamId]);

  console.log("defaultElement", defaultElement);
  useEffect(() => {
    console.log("Baord Callled");
    refetch();
  }, [board]);

  const filterElements = defaultElement?.filter((item) => {
    return (
      item?.settings?.placeholder ||
      item?.settings.label ||
      item?.settings.description ||
      item?.settings.title ||
      item?.name || item?.type)?.toLowerCase()?.includes(value?.toLowerCase());
  });

  const filterCustomElements = CustomElement?.filter((item) => {
    return (
      item?.settings?.placeholder ||
      item?.settings.label ||
      item?.settings.description ||
      item?.settings.title ||
      item?.name ||
      item?.type
    )?.toLowerCase()?.includes(value?.toLowerCase());
  });

  let show = true;
  const Element = useCallback(
    ({
      id,
      name,
      type,
      placeholder,
      field_type = "",
      isDisabled = "false",
    }) => {
      //("ids", id);
      const ElementName =
        placeholder?.placeholder ||
        placeholder?.label ||
        placeholder?.description ||
        placeholder?.title ||
        name ||
        type;
      const str = id?.split("-")[0];
      const Store = ElementsUIGenrate(str);

      return (
        <div
          className={`p-0 ${
            isDisabled &&
            field_type === "default_field" &&
            "disable-default-field-div"
          }`}
        >
          <div
            className={`d-flex p-2 border rounded align-items-center gap-2 ${
              isDisabled &&
              field_type === "default_field" &&
              "disable-default-field"
            }`}
            id={id}
          >
            <i className="fs-3 iconify" data-icon={Store?.icon} />
            <span>{ElementName}</span>
            <i
              className="small iconify ms-auto text-primary"
              data-icon="carbon:bookmark"
            />
          </div>
        </div>
      );
    },
    []
  );

  return (
    <div className="w-100 pb-5 mb-5">
      <div className="mb-4">
        <div className="input-group w-100 form-group">
          <input
            placeholder="Search "
            className="form-control input-bn search-bn"
            type="search"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ height: "40px !important" }}
          />
          <span className="d-flex p-1 search-bn-label border align-items-center text-secondary">
            <i className="iconify fs-4" data-icon="mdi:search" />
          </span>
        </div>
      </div>
      <nav>
        <div className="nav nav-tabs flex-nowrap fi-tab" role="tablist">
          <button
            className="nav-link col-6 active text-dark"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#default-field-container"
            type="button"
            role="tab"
          >
            <span className="d-flex align-items-center justify-content-center">
              <i className="iconify me-1" data-icon="mdi:bookmark" />
              <span className="d-none d-xl-block">Default Fields</span>
            </span>
          </button>
          <button
            className="nav-link col-6 text-dark"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#custom-field-container"
            type="button"
            role="tab"
          >
            <span className="d-flex align-items-center justify-content-center">
              {" "}
              <i
                className="iconify me-1"
                data-icon="ant-design:build-outlined"
              />
              <span className="d-none d-xl-block">Custom Fields</span>
              
            </span>
          </button>
        </div>
      </nav>
      <div className="tab-content pt-3">
        <div
          className="tab-pane fade show active"
          id="default-field-container"
          role="tabpanel"
        >
          <Droppable droppableId="DefaultElements">
            {(provided, index) => (
              <div
                key={index}
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="fm-component-cont  mt-2 mb-4 row row-cols-1 gap-3 p-0 m-0"
              >
                {filterElements?.map((item, index) => {
                  console.log("item>>", item.type);
                  const isElementInBoard = board?.some(
                    (element) => element.elementid === item.elementid
                  );
                  const str = item?.elementid?.split("-")[0];
                  // console.log("item>><",isElementInBoard)
                  const uuid = Math.floor(Math.random() * 10000);
                  if (isElementInBoard) {
                    return (
                      <Element
                        key={index}
                        id={item?.elementid}
                        type={item.type}
                        name={item?.name}
                        placeholder={item.settings}
                        field_type="default_field"
                        isDisabled={isElementInBoard}
                      />
                    );
                  } else {
                    return (
                      <DragableElement
                        id={item?.elementid}
                        key={item.elementid} //if we pass key here then it will give error and drag and drop will not work
                        index={index}
                        element={
                          <Element
                            id={item?.elementid}
                            type={item.type}
                            name={item?.name}
                            field_type="default_field"
                            isDisabled={isElementInBoard}
                          />
                        }
                        settings={item?.settings}
                        show={show}
                        isDragDisabled={isElementInBoard}
                      />
                    );
                  }
                })}
                {filterElements?.length === 0 && (
                  <div className="text-center">No Default Fields</div>
                )}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {/* {getOptions(defaultElement,value)} */}
        </div>
        <div
          className="tab-pane fade"
          id="custom-field-container"
          role="tabpanel"
        >
          <Droppable droppableId="CustomElements" isDropDisabled={true}>
            {(provided, index) => (
              <div
                key={index}
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="fm-component-cont mt-2 mb-4 row row-cols-1 gap-3 p-0 m-0"
              >
                {filterCustomElements?.map((item, index) => {
                  const isElementInBoard = board?.some(
                    (element) => element.elementid === item.elementid
                  );
                  // const str=item?.elementid?.split('-')[0]
                  console.log(
                    "ElementINBoard",
                    isElementInBoard,
                    board,
                    item.element
                  );
                  if (isElementInBoard) {
                    // return null
                    return (
                      <Element
                        key={index}
                        id={item?.elementid}
                        type={item.type}
                        name={item?.name}
                        field_type="default_field"
                        isDisabled={isElementInBoard}
                      />
                    );
                  } else {
                    return (
                      <DragableElement
                        id={item?.elementid}
                        key={item?.elementid} //if we pass key here then it will give error and drag and drop will not work
                        index={index}
                        element={
                          <Element
                            id={item?.elementid}
                            type={item.type}
                            name={item?.name}
                            field_type="default_field"
                            isDisabled={isElementInBoard}
                          />
                        }
                        settings={item?.settings}
                        show={show}
                        // isDragDisabled={isElementInBoard}
                      />
                    );
                  }
                })}
                {filterCustomElements?.length === 0 && (
                  <div className="text-center">No Custom Fields</div>
                )}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>

  );
};

export default FormBuilderRightSideBar;
