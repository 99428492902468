import { useRef, useState, useContext, useEffect } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import Validation from "../../../utils/Validation";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { boardConetxt ,ErrorResult} from "../../../pages/form-builder/dashboard/ContextHelper";
import { useForm,Controller ,useFormContext} from "react-hook-form";
import { useLocation } from "react-router-dom";


export const InputUi = ({ elementid,message={} ,PreviewData, showHide,previewFlag = false,oppFields}) => {
  const { board ,setboard} = useContext(boardConetxt);
  const [copy,setCopy]=useState(false);
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const Location=useLocation();
  const params=new URLSearchParams(Location.search);
  console.log("params",params.get('emoji'))
  const {
    placeHolder,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden,
  } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const {register,formState:{errors},setValue,control} = useFormContext();
  useEffect(()=>{
    setValue(elementid,predefine)
  },[predefine])
  
useEffect(()=>{
  if(oppFields){
    setValue(elementid,oppFields)
  } 
},[oppFields])

useEffect(()=>{
  if(elementid,params.get(elementid.replace(/-\d+$/, ''))){
    setValue(elementid,params.get(elementid.replace(/-\d+$/, '')))
  } 

},[elementid,params.get(elementid.replace(/-\d+$/, ''))])
  // const [errorMessage, seterrorMessage] = useState({});
  // const Inputref = useRef(null);
  // const inputValidationResult = (e) => {
  //   e.preventDefault();
  //   let elementData = {};
  //   elementData.input = Inputref.current.value;
  //   let elementSettings = {};
  //   let validation = Validation(elementData,ElementSetting);
  //   let { inputCheck } = validation.validateInput();
    
  //   seterrorMessage(inputCheck);
  //   const newboard = board.map((product) => {
  //     if (product.elementid === elementid) {
  //       product.settings.predefine = Inputref.current?.value;
  //     }
  //     return product;
  //   });
  //   setboard(newboard);
  // };

  // useEffect(()=>{
  //   let elementData = {};
  //   elementData.input = predefine !== "" ? predefine : Inputref.current.value;

  //   let validation = Validation( elementData,ElementSetting);
  //   let { inputCheck } = validation.validateInput();
  //   seterrorMessage(inputCheck);
  // },[predefine])


  // useEffect(() => {
  //   if (message[elementid]?.result === false) {
  //     seterrorMessage(message);
  //   }
  // }, [message]);

  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])
  const label=board.find(
    (item) => item?.elementid === elementid
  ).name;
  console.log("Setting>>",label)
  return (
    <div className={previewFlag && GetStyleClasses(elementid) ||  ""}
    id={elementid} style={initStyle} hidden={hidden && previewFlag}>
      <div className="form-floating w-100 form-group">
        {/* <input
          placeholder="Sample Input For Text Field"
          className="form-control input-bn"
          type={inputType}
          maxLength={maxValue !== "" ? maxValue : 30}
          minLength={minValue !== "" ? minValue : 0}
          name="input"
          
          id={elementid}
          ref={Inputref}
          defaultValue={predefine ? predefine : Inputref.current?.value}
          onChange={(e) => {
            inputValidationResult(e);
          }}
        /> */}
        {Object.keys(ElementSetting).length === 0 ? (
          <Controller
          control={control}
          name={elementid}
          rules={
            {
             
             
              maxLength:{
                value:100,
                message:`Please Enter Maximum 60 Characters`
              }
            }
          }
          render={({ field }) => (
            <input
              type="text"
              className="form-control input-bn"
              name="input"
              defaultValue={""}
              placeholder="Enter Text"
              {...field}
            />
          )}
        />
        ):(<Controller
        control={control}
        name={elementid}
        rules={
          {
            required: {
              value: required === true ? true : false,
              message: "This Field is Required"
            },
            minLength:{
              value:minValue === "" ? 0 : minValue,
              message: `Please Enter More then ${minValue === "" ? 0 : minValue} Characters`
            },
            maxLength:{
              value:maxValue === "" ? 80 : maxValue,
              message:`Please Enter Maximum ${maxValue === "" ? 80 : maxValue} Characters`
            }
          }
        }
        render={({ field }) => (
          <input
            type={inputType ? inputType : "text"}
            className="form-control input-bn"
            name="input"
            defaultValue={predefine===undefined || "" ? "" : predefine}
            placeholder="Enter Text"
            {...field}
          />
        )}
      />)}
        
       {/* {
        ElementSetting ? ( <input
          type="text"
          className="form-control input-bn"
          name="input"
          placeholder="Enter Text"
          {...register(elementid, {
           
          
            maxLength:{
              value:80,
              message:`Please Enter Maximum 80 Characters`
            }
          })}
        />) :(
          <input
          type={inputType ? inputType : "text"}
          className="form-control input-bn"
          name="input"
          defaultValue={predefine===undefined || "" ? "" : predefine}
          placeholder="Enter Text"
          {...register(elementid, {
            required:{
              value:required===undefined ? false : required,
              message:"This field is required"
            },
          
              minLength:{
                value:minValue === "" ? 0 : minValue,
                message: `Please Enter More then ${minValue === "" ? 0 : minValue} Characters`
              }
            ,
           maxLength:{
              value:maxValue === "" ? 80 : maxValue,
              message:`Please Enter Maximum ${maxValue === "" ? 80 : maxValue} Characters`
            }
          })}
        />
        )
       } */}
        <label>
          <span className="d-flex align-items-center">
            <span>
              {copy ? placeHolder == undefined ? label : placeHolder+"-copy": placeHolder ? placeHolder : label}
              {/* {placeHolder ? `${copy==true ? placeHolder-"copy": placeHolder}` : label} */}
              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      </div>
      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
      {errors && (
        <span className="text-danger">
          {errors[elementid]?.message}
        </span>
      )}
        </div>
      {/* placeholder for errors */}
      {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
    </div>
  );
};
