import React, { useContext, useEffect, useRef, useState } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import Validation from "../../../utils/Validation";
import {
  boardConetxt,
  ErrorResult,
} from "../../../pages/form-builder/dashboard/ContextHelper";
import { useForm, Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";
export const TextareaUi = ({
  elementid,
  message = {},
  showHide,
  previewFlag = false,
  oppFields

}) => {
  const { board, setboard } = useContext(boardConetxt);
  const [errorMessage, setErrorMessage] = useState({});
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const {
    placeHolder,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden,
  } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const [copy,setCopy]=useState(false);

  const textRef = useRef(null);
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const Location = useLocation();
  const params = new URLSearchParams(Location.search);

  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])
 


  useEffect(() => {
    if ((elementid, params.get(elementid.replace(/-\d+$/, "")))) {
      setValue(
        elementid,
        elementid,
        params.get(elementid.replace(/-\d+$/, ""))
      );
    }
  }, [elementid, params.get(elementid.replace(/-\d+$/, ""))]);
  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])

  ////console.log("value",textRef.current?.value)
  // const textValidationResult = (e) => {
  //   e.preventDefault();
  //   let elementData = {};
  //   elementData.textarea = textRef.current.value;
  //   let validation = Validation(elementData, ElementSetting);
  //   let { textAreaChecks } = validation.validateTextarea();

  //   setErrorMessage(textAreaChecks);
  //   const newboard = board.map((product) => {
  //     if (product.elementid === elementid) {
  //       product.settings.predefine = textRef.current?.value;
  //     }
  //     return product;
  //   });
  //   setboard(newboard);
  // };

  // useEffect(()=>{
  //   let elementData = {};
  //   elementData.textarea = predefine ? predefine :textRef.current.value;
  //   let validation = Validation(elementData, ElementSetting);
  //   let { textAreaChecks } = validation.validateTextarea();
  //   setErrorMessage(textAreaChecks);
  // },[predefine])

  // useEffect(() => {
  //   if (message[elementid]?.result === false) {
  //     setErrorMessage(message);
  //   }
  // }, [message]);
  const label = board.find((item) => item?.elementid === elementid).name;
  return (
    <div
      className={(previewFlag && GetStyleClasses(elementid)) || ""}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating w-100 form-group">
        {/* <textarea
          placeholder="Text Area"
          className="form-control input-bn"
          type={inputType}
          name="textarea"
          id={elementid}
          ref={textRef}
          defaultValue={predefine ? predefine : textRef?.current?.value}
          onChange={(e) => textValidationResult(e)}
          maxLength={maxValue ? maxValue : 400}
          minLength={minValue ? maxValue : 0}
        ></textarea> */}
        {Object.keys(ElementSetting).length === 0  ? (
          <Controller
            control={control}
            name={elementid}
            rules={{
              maxLength: {
                value: 500,
                message: `Please Enter Maximum 500 Characters`,
              },
            }}
            render={({ field }) => (
              <textarea
                placeholder="Text Area"
                className="form-control input-bn"
                type="text"
                name="textarea"
                id={elementid}
                {...field}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={elementid}
            rules={{
              required: {
                value: true,
                message: "This Field is Required",
              },
              minLength: {
                value: minValue === "" ? 0 : minValue,
                message: `Please Enter More then ${
                  minValue === "" ? 0 : minValue
                } Characters`,
              },
              maxLength: {
                value: maxValue === "" ? 500 : maxValue,
                message: `Please Enter Maximum ${
                  maxValue === "" ? 500 : maxValue
                } Characters`,
              },
            }}
            render={({ field }) => (
              <textarea
                placeholder="Text Area"
                className="form-control input-bn"
                type={inputType ? inputType : "text"}
                name="textarea"
                defaultValue={predefine === undefined ? "" : predefine}
                {...field}
              />
            )}
          />
        )}

        {/* {ElementSetting ? ( <textarea
          type="text"
          className="form-control input-bn"
          name="Textarea"
          id={elementid}
          rows="8"
          placeholder="Enter Text"
          {...register(elementid, {
          
            maxLength:{
              value:400,
              message:`Please Enter Maximum 400 Characters`
            },
            },
          )}
        />):( <textarea
          type={inputType === undefined  ? "text" : inputType}
          className="form-control input-bn"
          name="Textarea"
          id={elementid}
          rows="8"
          defaultValue={predefine===undefined || " " ? "" : predefine}
          placeholder="Enter Text"
          {...register(elementid, {
            required: {
              value: required === undefined ? false : required,
              message: "This Field is Required"
            },
            minLength: {
              value: minValue ===  "" ? 0 : minValue,
              message: `Please Enter More then ${minValue ===  "" ? 0 : minValue} Characters`
            },
            maxLength:{
              value:maxValue=== "" ? 400 : maxValue,
              message:`Please Enter Maximum ${maxValue=== "" ? 400 : maxValue} Characters`
            },
            },
          )}
        />)} */}
        <label>
          <span className="d-flex align-items-center">
            <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text"></i>
            {/* {placeHolder ? placeHolder : label}
             */}
              {copy ? placeHolder == undefined ? label : placeHolder+"-copy": placeHolder ? placeHolder : label}

            {required ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        </label>
      </div>
      <small className="text-secondary">{instructions}</small>
      {/* <!-- placeholder for errors --> */}
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
      {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
    </div>
  );
};
