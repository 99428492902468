import { ModalFooter } from "./ModalFooter";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const DropDownSettings = ({ elementid, closeModal, ElementSetting }) => {
  const initialvalue = {
    options: [{ id: 1, value: "", isCheck: false }],
    label: "",
    size: "large",
    inputType: "text",
    required: false,
    // duplicate: true,
    minValue: "",
    maxValue: "",
    instructions: "",
  };

  const [Dropdown, setDropDown] = useState(
    Object.keys(ElementSetting).length !== 0 ? ElementSetting : initialvalue
  );
  const [show, setShow] = useState(true);
  const handlerAddNewOption = () => {
    const id = Math.floor(Math.random() * 1000);
    const values = [...Dropdown.options];
    values.push({ id: id, value: "", isCheck: false });
    setDropDown({ ...Dropdown, options: values });
  };
  const handelChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setDropDown({ ...Dropdown, [name]: value });
  };
  const handleChanges = (event, index) => {
    event.preventDefault();
    const Values = [...Dropdown.options];
    const name = event.target.name;
    Values[index][name] = event.target.value;
    setDropDown({ ...Dropdown, options: Values });
  };
  const handelChang = (event) => {
    if (event.target.value) {
      setShow(false);
    }else{
      setShow(true);
    }
  };

  useEffect(() => {
    const data = [...Dropdown.options];
    //console.log(":Dropdown");
    const check = data.some((item) => {
      if (data.length == 0) {
        setShow(false);
      } else if (item.value === "") {
        setShow(true);
      }
    });
  }, [Dropdown.options]);

  const handelDelete = (event, id) => {
    event.preventDefault();
    const values = [...Dropdown.options];
    const newboard = values.filter((item) => item.id !== id);
    setDropDown({ ...Dropdown, options: newboard });
  };
  //console.log(":Dropdown", Object.keys(Dropdown.options).length, show);
  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i
            className="iconify fs-5 pointer me-1"
            data-icon="tabler:checkbox"
          />
          Dropdown Field
        </h5>
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Dropdown-Field label</span>
            </span>
          </label>
          <input
            className="form-control input-bn"
            type="text"
            name="label"
            maxLength="30"
            minLength="30"
            defaultValue={Dropdown.label || ""}
            onBlur={handelChange}
            placeholder="Enter Dropdown-Field label"
          />
          {/* placeholder for errors */}
        </div>
        {/* RadioBtn UI */}

        {Object.keys(Dropdown.options).length > 0 ? (
          Dropdown.options.map((input, index) => {
            // //console.log(index);
            // //console.log(input);
            return (
              <div key={index}>
                <div className="mb-2">
                  <div className="d-flex align-items-center">
                    <i
                      className="iconify mx-1 pointer text-secondary fs-5"
                      data-icon="ci:grid-vertical"
                    />
                    <input
                      className="form-control input-bn"
                      type="text"
                      defaultValue={input.value || ""}
                      required
                      id={input.id}
                      name="value"
                      placeholder="Enter option"
                      onBlur={(event) => handleChanges(event, index)}
                      onChange={(event) => handelChang(event, index)}
                    />
                    <span onClick={(e) => handelDelete(e, input.id)}>
                      <i
                        className="iconify fs-5 text-danger pointer ms-2"
                        data-icon="mdi:trash"
                      />
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
        {show && Object.keys(Dropdown.options).length < 2 && (
          // <div className="d-flex flex-column ">
          <span
            className="text-danger"
            style={{ marginLeft: "30px", paddingBottom: "5px" }}
          >
            Please enter at least one option for this field
          </span>
          // </div>
        )}

        {/* RadioBtn UI */}
        <div className="d-flex align-items-center mt-2">
          <i
            className="iconify fs-5 pointer text-secondary mx-1"
            data-icon="ci:plus"
          />
          <div
            className="btn btn-primary rounded-pill "
            onClick={() => {
              // //console.log("Add new option");
              handlerAddNewOption();
            }}
          >
            Add new option
          </div>
        </div>
        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Size</span>
                {/* <div>
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="icon-park-outline:to-left"
                  />
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="entypo:align-horizontal-middle"
                  />
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="icon-park-outline:to-right"
                  />
                </div> */}
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={Dropdown.size || ""}
              name="size"
              onChange={handelChange}
            >
              <option value="large">Large</option>
              <option value="medium">Medium</option>
              <option value="small">Small</option>
            </select>
          </div>
        </div>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Input type</span>
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={Dropdown.inputType || ""}
              name="inputType"
              onChange={handelChange}
            >
              <option value="dropdown">DropDown</option>
              {/* <option value="Password">Password</option>
              <option value="Numbers">Numbers</option> */}
            </select>
          </div>
        </div>
        <div className="mb-4">
          <span>Options</span>
          <div className="mt-2 d-flex align-items-center">
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Dropdown.required || ""}
                onChange={handelChange}
                name="required"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Required
              </label>
            </div>
            <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Dropdown.hidden || ""}
                onChange={handelChange}
                name="hidden"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Hidden
              </label>
            </div>
            {/* <div className="me-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Dropdown.hidden || ""}
                onChange={handelChange}
                name="hidden"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Hidden
              </label>
            </div> */}
            {/* <div className="me-3 ">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Dropdown.duplicate || ""}
                onChange={handelChange}
                name="duplicate"
                id="check-2"
              />
              <label className="form-check-label ms-1" htmlFor="check-2">
                No Duplicate
              </label>
            </div> */}
          </div>
        </div>
        {/* <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Range</span>
            </span>
          </label>
          <div className=" d-flex">
            <div className="col-6 pe-1">
              <input
                placeholder="min value"
                defaultValue={Dropdown.maxValue || ""}
                onBlur={handelChange}
                name="maxValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
            <div className="col-6 ps-1">
              <input
                placeholder="max value"
                defaultValue={Dropdown.minValue || ""}
                onBlur={handelChange}
                name="minValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
          </div>
        </div> */}
        {/* text-area--------- */}
        <div className="mb-4">
          <div className="form-floating w-100 form-group">
            <textarea
              style={{ minHeight: "100px !important" }}
              defaultValue={Dropdown.instructions || ""}
              name="instructions"
              onBlur={handelChange}
              placeholder="Instructions for Users (max 100 char)"
              className="form-control input-bn"
              type="text"
              maxLength="100"
            />
            <label>
              <span className="d-flex align-items-center">
                <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                <span>Instructions for Users (max 100 char)</span>
              </span>
            </label>
          </div>
          {/* placeholder for errors */}
        </div>
      </div>
      <ModalFooter
        ElementSettingData={Dropdown}
        closeModal={closeModal}
        elementid={elementid}
      />
    </>
  );
};

DropDownSettings.propTypes = {};

export default DropDownSettings;
