import React, {
  useState,
  useEffect,
  useContext
} from "react";
import TestFilterAction from "../../../actions/TestFilterAction";
import ArrPng from "../../../assets/images/arr.png";
import FilterSingleCondition from "./FilterSingleCondition";
import { WorkFlowSessionInfo } from "./EditWorkflow";


const FilterBlocks = ({
  action_index,
  action_info,
  data_mapper,
  UpdateAction,
  workflowHelper,
  setSelected_action,
  selected_action,
  action_id,
  triggerdetails,
  setTriggerdetails,
  handleState
}) => {

  const andCondition = {
    "!#value": "",
    "!#expected_value": "",
    "!#operator": "",
    "!#data_type": "",
  };

  const orCondition = [andCondition];
  const [testAction, setTestAction] = useState(null);
  const [conditions, setConditions] = useState(
    data_mapper?.conditions ? data_mapper?.conditions : [orCondition]
  );
    const [showDetails, setShowDetails] = useState(false);
  const [testAgain, settestAgain] = useState(true);
  const {session} = useContext(WorkFlowSessionInfo);
  const [test_result, set_test_result] = useState(session?.[action_id]?.action_status === "SUCCESS" ? false : true);

  let default_mapper = {
    // conditional_operator: "or",
    conditions: conditions
  };
  const update_data_mapper = (data_mapper) => {
    data_mapper = Object.keys(data_mapper).length === 0 ? default_mapper : data_mapper;
    UpdateAction({ data_mapper })
  }


 
  useEffect(() => {
    UpdateAction({ data_mapper: {conditions: conditions} });
  }, [conditions]);

  useEffect(() => {
    if(session?.[action_id]){
      setTestAction("test_action_again")
      setShowDetails(true)
    }
    else{
      setShowDetails(false)
    }
  }
  , []);

  useEffect(() => {
    session?.[action_id]?.action_status === "SUCCESS" ? set_test_result(false) : set_test_result(true)
  }, [session]);

  const addOrCondition = () => {
    // This function will add a new array for OR condition in Conditions array
    const orObj = [...conditions];
    orObj.push(orCondition);
    setConditions(orObj);
  };

  const addAndCondition = (andIndex) => {
    // This function will add a new object for AND condition inside OR array
    const andObj = [...conditions];
    andObj[andIndex].push(andCondition);
    setConditions(andObj);
  };

  const removeAndCondition = (orIndex, andIndex) => {
    // Deletes an AND condition object from OR array
    const orObj = [...conditions];
    orObj[orIndex].splice(andIndex, 1);
    if (orObj[orIndex].length === 0) {
      orObj.splice(orIndex, 1);
    }
    setConditions(orObj);
  };

  const submit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="dot-border rounded-3 bg-white gx-2 p-4 mb-4 border position-relative">
        {test_result && (
          <div className="d-flex align-items-end mb-3 tooltip-container bvjk">
            <i
              className="iconify text-danger mns-auto"
              data-icon="mdi:info-circle"
            ></i>
            <div className="tooltip-text">
              Please test the filter before saving workflow!
            </div>
          </div>
        )}
        <div className="w-100 d-flex justify-content-between">
          {!action_info.is_default ? (
            <p
              className="text-decoration-underline wait text-secondary small"
              onClick={() => {
                workflowHelper?.change_action_to_unknown(action_id);
              }}
            >
              <i className="iconify" data-icon="mdi:arrow-left"></i>Change
            </p>
          ) : (
            <p></p>
          )}
          <span className="pointer"  onClick={() => workflowHelper?.remove_action(action_id)}>
            <i
              className="iconify p-0 fs-5 text-secondary wait"
              data-icon="mdi:trash"
            ></i>
          </span>
        </div>
        {selected_action !== action_info.action_id ? (
          <div
            className="pointer"
            onClick={() => setSelected_action(action_info.action_id)}
          >
            <h5 className="fs-6 m-0 mb-4 mt-2">
            <strong> {action_index+". "}</strong> 
              <i className="iconify p-0 me-1" data-icon="mdi:filter"></i>Advanced Filter
              setup & testing
            </h5>

            <form onSubmit={submit}>
              {conditions.length > 0 && conditions?.map((orInput, orIndex) => {
                return (
                    <div key={orIndex}>
                      {orInput.map((andInput, andIndex, id) => {
                        if (orIndex === 0 && andIndex === 0)
                          return (
                            <>
                              <div className="row m-0 p-0" key={id}>
                                <div className="col-12 p-0 position-relative">
                                  <FilterSingleCondition
                                    key={orIndex+"_condition_"+andIndex}
                                    data_mapper={data_mapper}
                                    update_data_mapper={update_data_mapper}
                                    workflowHelper={workflowHelper}
                                    action_id={action_id}
                                    orIndex={orIndex}
                                    andIndex={andIndex}
                                    andInput={andInput}
                                    conditions={conditions}
                                    setConditions={setConditions}
                                  />
                                </div>
                                <div className="align-items-center col-1 m-2">
                                  {andIndex === 0 && orIndex === 0 ? (
                                    ""
                                  ) : (
                                    <div
                                      className="ms-auto"
                                      onClick={() =>
                                        removeAndCondition(orIndex, andIndex)
                                      }
                                    >
                                      <i
                                        className=" iconify fs-4 pointer text-danger"
                                        data-icon="mdi:trash"
                                      ></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                      })}
                    </div>
                );
              })}
            </form>
          </div>
        ) : (
          <>
            <h5 className="fs-6 m-0 mb-2 mt-2">
              
             {action_index+". "}
              <i className="iconify p-0 me-1" data-icon="mdi:filter"></i>Filter
              setup & testing
            </h5>
            <br />
            <form onSubmit={submit}>
              {conditions?.length > 0 && conditions?.map((orInput, orIndex, id) => {
                return (
                  <>
                    <div>
                      {orInput.map((andInput, andIndex, id) => {
                        return (
                          <>
                            <div className="row m-0 p-0" key={id}>
                              <div className="col-12 pe-3 p-0 position-relative">
                                <FilterSingleCondition
                                  key={orIndex+"_condition_"+andIndex}
                                  data_mapper={data_mapper}
                                  update_data_mapper={update_data_mapper}
                                  workflowHelper={workflowHelper}
                                  action_id={action_id}
                                  orIndex={orIndex}
                                  andIndex={andIndex}
                                  andInput={andInput}
                                  conditions={conditions}
                                  setConditions={setConditions}
                                />
                              </div>
                              
                              <div className="p-0 mb-3 d-flex justify-content-end">
                                {andIndex === 0 && orIndex === 0 ? (
                                  ""
                                ) : (
                                  <div
                                    className="filt-del"
                                    onClick={() =>
                                      removeAndCondition(orIndex, andIndex)
                                    }
                                  >
                                    <i
                                      className=" iconify fs-6 pointer text-danger"
                                      data-icon="ep:circle-close-filled"
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {/* AND button */}
                    {orInput.map((andInput, andIndex, id) =>
                      andIndex === 0 ? (
                        <>
                          <button
                            className="btn btn-primary rounded-pill px-3 btn-sm"
                            onClick={() => addAndCondition(orIndex)}
                          >
                            + AND
                          </button>
                          <hr className="dot-border border" />
                        </>
                      ) : (
                        ""
                      )
                    )}
                    {/* AND button end */}
                  </>
                );
              })}
              <button className="btn btn-primary rounded-pill px-3 btn-sm" onClick={addOrCondition}>
                + OR
              </button>
            </form>
            <br />

            <div className="w-100 mt-4 modal-header">
              <div
                className="d-flex align-items-center pointer"
                onClick={() => {
                  testAction === "tested_action" ? setTestAction("test_action_again") :  setTestAction("tested_action");
                }}
              >
                <i
                  className="iconify p-0 fs-3 me-1 text-primary"
                  data-icon="ic:round-network-check"
                ></i>
                <span className="d-none d-md-block text-decoration-underline"
                  onClick={()=> {setShowDetails(false)
                    settestAgain(true)   
                    }}
                  >Test Filter
                </span>
                { test_result && (
                  <p className="text-danger m-3">
                    Please test the filter!
                  </p> 
                )}
              </div>
              {testAction && testAction !== "test_action" && (
                <button
                type="button"
                className=" dropdown-toggle mt-3 btn-outline"
                data-bs-toggle="collapse"
                data-bs-target="#test_fub_automation"
                onClick={() => {setShowDetails(!showDetails)
                  setTestAction("test_action_again")
                  settestAgain(false)   
              }}
              >
                {showDetails ? "Show previous test details" : "Hide" }
              </button>
                 )}
            </div>
          
              {testAction === "tested_action" && (
                  <TestFilterAction
                    triggerdetails={triggerdetails}
                    setTriggerdetails={setTriggerdetails}
                    testAction={testAction}
                    setTestAction={setTestAction}
                    action_id={action_id}
                    set_test_result={set_test_result}
                    setShowDetails={setShowDetails}
                    selected_action={selected_action}
                    testAgain={testAgain}
                  />
                )}
                {!showDetails && testAction === "test_action_again" && (
                  
                  <div
                  id="test_fub_automation"
                  className={`${showDetails ?  "show accordion-collapse collapse" : ""  }`}
                  >
                  <TestFilterAction
                    triggerdetails={triggerdetails}
                    setTriggerdetails={setTriggerdetails}
                    testAction={testAction}
                    setTestAction={setTestAction}
                    action_id={action_id}
                    set_test_result={set_test_result}
                    setShowDetails={setShowDetails}
                    selected_action={selected_action}
                    testAgain={testAgain}
                  />
                  </div>
                )}

                  <div className="mt-4"
                  >
                  <div
              className="w-100 text-decoration-underline pointer"
              onClick={handleState}
            >
              Template Filters
              <i
                className="iconify text-secondary ms-2"
                data-icon="mdi:info-circle"
              />
            </div>
           </div>
          </>
        )}
      </div>
    </>
  );
};

export default FilterBlocks;
