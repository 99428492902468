const isValidDropDown=(elementData,ElementSetting)=>{
//console.log("ElementSetting",ElementSetting)
let DropValidationResult={}
// const { required, options } = ElementSetting;
// //   console.log("elr", ElementSetting);
// let check = options?.some((item) => item.isCheck === true);
// //console.log("Cehcing",check)
// if (required && !check) {
//   DropValidationResult = {
//     result: false,
//     message: "Select atleast one option",
//   };
// } else {
//   DropValidationResult = { result: true, message: "" };
// }
return DropValidationResult;   
}





export {isValidDropDown}