import React, { useContext, useEffect, useState } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import {
  boardConetxt,
  ErrorResult,
} from "../../../pages/form-builder/dashboard/ContextHelper";
import Validation from "../../../utils/Validation";
import { useForm, Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";
export const RadiobuttonUi = ({ elementid, message = {} , showHide,previewFlag = false,oppFields}) => {
  const [errorMessage, setErrorMessage] = useState({});
  const { board, setboard } = useContext(boardConetxt);
  // const [value, setValue] = useState("");
  const {
    register,
    formState: { errors },setError,control,setValue
  } = useFormContext();
  const [copy,setCopy]=useState(false);

  const Location=useLocation();
  const params=new URLSearchParams(Location.search);

  useEffect(()=>{
    if(elementid,params.get(elementid.replace(/-\d+$/, ''))){
      setValue(elementid,elementid,params.get(elementid.replace(/-\d+$/, '')))
    } 
  
  },[elementid,params.get(elementid.replace(/-\d+$/, ''))])

  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const {
    options,
    label,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden
  } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])
  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])
  

  //   const handelData = () => {
  //     const newboard = board?.map((product) => {
  //       if (product?.elementid === elementid) {
  //         product?.settings?.options?.map((item) => {
  //           if (item?.value === value) {
  //             item.isCheck = true;
  //           } else {
  //             item.isCheck = false;
  //           }
  //           return item;
  //         });
  //       }
  //       let elementData={}
  //       let validation = Validation(elementData,ElementSetting);
  //        let { RadioBtnCheck } = validation.RadioCheck();
  //       // //console.log("RadioBtnCheck",RadioBtnCheck)

  //        setErrorMessage(RadioBtnCheck);

  //       return product;
  //     });
  //     setboard(newboard);
  //   };
  //   useEffect(()=>{
  // handelData()
  //   },[value])
  // useEffect(()=>{
  //   let elementData={}
  //   let validation = Validation(elementData,ElementSetting);
  //    let { RadioBtnCheck } = validation.RadioCheck();
  //    setErrorMessage(RadioBtnCheck);
  // },[options])

  // useEffect(() => {
  //   if (message[elementid]?.result === false) {
  //     setErrorMessage(message);
  //   }
  // }, [message]);
console.log("data>>",ElementSetting)

  return (
    <div id={elementid} className={previewFlag && GetStyleClasses(elementid) ||  ""} style={initStyle} hidden={hidden && previewFlag}>
      {/* {label ? (
        <span>{label}</span>
      ) : (
        <span>Write up question for Radio buttons</span>
      )} */}
      <span id={elementid}>{copy ? `${label}-copy`: label ? label : "Write up question for Radio buttons"}</span> 

      {required ? <span style={{ color: "red" }}>*</span> : ""}

      {options ? (
        <>
          {options.map((item, i) => {
            return (
              <Controller
                key={i}
                name={`${elementid}`}
                control={control}
                rules={{
                  required: {
                    value: required === true ? true : false,
                    message: "This field is required",
                  },
                }}
                render={({ field}) => (
                  <div className="mt-2" key={i}>
                    <input
                      className="form-check-input from-check"
                      type="radio"
                     {...field}
                      value={item?.value}
                    />
                    <label
                      className="form-check-label  text-secondary ms-2"
                      htmlFor={item?.value}
                    >
                      {item?.value}
                    </label>

                  </div>
                )}
              />

              // <div className="mt-2" key={i}>
              //   <input
              //     className="form-check-input from-check"
              //     type="radio"
              //     {...register(`${elementid}`, {
              //       required: true,
              //     })}
              //     value={item?.value}
              //   />
              //   <label
              //     className="form-check-label  text-secondary ms-2"
              //     htmlFor={item?.value}
              //   >
              //     {item?.value}
              //   </label>
              // </div>
            );
          })}
        </>
      ) : (
        <>
          <Controller 
                control={control}
                name={elementid}
              
                render={({ field: { onChange, value, ref } }) => (
                  <div className="mt-2">
                 
                </div>
                )}
              />

              <label
                className="form-check-label text-secondary "
                htmlFor="check-1"
              >
                Please Select an option or Add option
              </label>
                  

        </>
      )}

      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>


      {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
    </div>
  );
};
