import { ContactUi } from "./ContactUi";
import React from "react";
import { BoardDeleteHandler } from "../../BoardHandlerFunctions";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { useContext } from "react";
import { ElementsSettingsWidget } from "./ElementsSettingsWidget";

export const DnDContact = ({ elementid, element ,PreviewData=false,oppFields}) => {
  const { board, setboard } = useContext(boardConetxt);
  // const ElementSetting = board.find(
  //   (item) => item?.elementid === elementid
  // ).settings;
  // const { position } = ElementSetting;
  // //console.log(position);

  // let elementFloat;
  // let elementWidth;
  // if (position === "left") {
  //   //elementFloat = "left";
  //   elementWidth = "col-md-6";
  // } else if (position === "right") {
  //   //elementFloat = "right";
  //   elementWidth = "col-md-6";
  // } else if (position === "full") {
  //   //elementFloat = null;
  //   elementWidth = "col-12";
  // }

  return (
    <>
      <div
      className="fm-comp mb-4"
      >
        <ElementsSettingsWidget
          BoardDeleteHandler={BoardDeleteHandler}
          elementid={elementid}
          board={board}
          setboard={setboard}
          element={element}
        />

        <ContactUi PreviewData={PreviewData} oppFields={oppFields}  elementid={elementid} />
      </div>
    </>
  );
};
