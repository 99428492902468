import { useRef, useState, useContext, useEffect } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import Validation from "../../../utils/Validation";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import {
  boardConetxt,
  ErrorResult,
} from "../../../pages/form-builder/dashboard/ContextHelper";
import { useForm, Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
export const StarRatingUi = ({ elementid, message = {}, PreviewData, showHide,previewFlag = false,oppFields }) => {
  const { board, setboard } = useContext(boardConetxt);
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const {
    title,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden
  } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const {
    register,
    formState: { errors },setValue
  } = useFormContext();
  const Location=useLocation();
  const params=new URLSearchParams(Location.search);

  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])
  


  // useEffect(()=>{
  //   if(elementid,params.get(elementid.replace(/-\d+$/, ''))){
  //     setValue(elementid,elementid,params.get(elementid.replace(/-\d+$/, '')))
  //   } 
  
  // },[elementid,params.get(elementid.replace(/-\d+$/, ''))])
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const label=board.find(
    (item) => item?.elementid === elementid
  ).name;
  return (
    <div
    className={previewFlag && GetStyleClasses(elementid) ||  ""}
      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <label>
          <span className="d-flex align-items-center">
            <span style={{fontSize:"15px"}}>
              {title ? title : ""}
              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      <div className="form-floating w-100 form-group">
      
        <div className="star-rating">
          
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <button
                type="button"
                key={index}
                className={index <= (hover || rating) ? "on" : "off"}
                onClick={() => setRating(index)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(rating)}
                {...register(elementid, {
                    required:{
                      value: required === true ? true : false,
                      message:"This field is required"
                    },
                  
                      minLength:{
                        value:minValue === undefined || "" ? 0 : minValue,
                        message: `Minimum length is ${minValue ===undefined || "" ? 0 : minValue}`
                      }
                    ,
                   maxLength:{
                      value:maxValue === undefined || "" ? 30 : maxValue,
                      message:`Maximum length is ${maxValue === undefined || "" ? 30 : maxValue}`
                    }
                  })}
              >
                <span className="star">&#9733;</span>
              </button>
            );
          })}
        </div>
        {/* <input
          type="text"
          className="form-control input-bn"
          name="input"
          defaultValue={predefine===undefined || "" ? "" : predefine}
          placeholder="Enter Text"
          {...register(elementid, {
            required:{
              value:required===undefined ? false : required,
              message:"This field is required"
            },
          
              minLength:{
                value:minValue === undefined || "" ? 0 : minValue,
                message: `Minimum length is ${minValue ===undefined || "" ? 0 : minValue}`
              }
            ,
           maxLength:{
              value:maxValue === undefined || "" ? 30 : maxValue,
              message:`Maximum length is ${maxValue === undefined || "" ? 30 : maxValue}`
            }
          })}
        /> */}
       
      </div>
      
      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
    </div>
  );
};
