import { Component } from "react";
// import WorkflowHeader from "../../../components/WorkflowHeader";


class Action {
    constructor({ workflow_id, action_id, automation_name, next_action_ids, data_mapper, api_name, api_type, meta_data, test_status,platform, is_default = false }) {
        this.action_id = action_id;
        this.next_action_ids = next_action_ids || [];
        this.data_mapper = data_mapper;
        this.api_name = api_name;
        this.api_type = api_type;
        this.meta_data = meta_data
        this.workflow_id = workflow_id;
        this.test_status = test_status;
        this.automation_name = automation_name;
        this.is_default = is_default || false;
        this.platform = platform;
        this.bind_methods();
    }

    bind_methods() {
        this.get_action_info = this.get_action_info.bind(this);
        this.update_action_info = this.update_action_info.bind(this);
        this.remove_action_info = this.remove_action_info.bind(this);
    }

    get_action_info() {
        return {
            action_id: this.action_id,
            next_action_ids: this.next_action_ids || [],
            data_mapper: this.data_mapper,
            api_name: this.api_name,
            api_type: this.api_type,
            workflow_id: this.workflow_id,
            meta_data: this.meta_data,
            test_status: this.test_status,
            automation_name: this.automation_name,
            is_default: this.is_default,
            platform: this.platform,
        }
    }

    update_action_info({ data_mapper, api_name, api_type, meta_data, test_status, automation_name,platform  }) {
        // this.next_action_ids = next_action_ids;
        if (data_mapper) this.data_mapper = data_mapper;
        if (api_name) this.api_name = api_name;
        if (api_type) this.api_type = api_type;
        if (meta_data) this.meta_data = meta_data;
        if (test_status) this.test_status = test_status;
        if (automation_name) this.automation_name = automation_name;
        if (platform) this.platform = platform;
    }

    remove_action_info() {
        this.data_mapper = {};
        this.api_name = "";
        this.api_type = "";
        this.meta_data = {};
        this.test_status = "";
        this.automation_name = "";
        this.platform = "";
    }

}
export class WorkflowHelper extends Component {

    constructor(actions, setWorkflowactions) {
        super();
        this.actions = [];
        this.setWorkflowactions = (data) => {
            setWorkflowactions(data);
        }
        if (actions) {
            for (let action of actions) {
                if (!action.action_id) continue;
                let a = new Action(action);
                this.actions.push(a);
            }
        }
        this.bind_methods();
    }



    bind_methods() {
        this.get_action = this.get_action.bind(this);
        this.add_action = this.add_action.bind(this);
        this.__delete_action = this.__delete_action.bind(this);
        this.__add_edge = this.__add_edge.bind(this);
        this.__remove_edge = this.__remove_edge.bind(this);
        this.get_parent = this.get_parent.bind(this);
        this.__remove_action = this.__remove_action.bind(this);
        this.move_node = this.move_node.bind(this);
        this.remove_action = this.remove_action.bind(this);
        // this.get_workflow = this.get_workflow.bind(this);
        this.get_parent_chain = this.get_parent_chain.bind(this);
        this.update_action_info = this.update_action_info.bind(this);
        this.get_actions_by_order = this.get_actions_by_order.bind(this);
        this.change_action_id = this.change_action_id.bind(this);
        this.change_action_to_unknown = this.change_action_to_unknown.bind(this);
        this.add_action = this.add_action.bind(this);
        this.add_trigger = this.add_trigger.bind(this);
        this.add_default_action = this.add_default_action.bind(this);
    }

    update_action_info(action_id) {
        this.update = function update_action_info(data) {
            let action = this.get_action(action_id);
            action.update_action_info(data);
            this.setWorkflowactions(this.get_workflow());
        }
        this.update = this.update.bind(this);
        return this.update;
    }

    add_action(action_info, after_action_id, at_index = 0) {
        let action_id = action_info['action_id'];
        // Check if action_id already exists
        for (let action of this.actions) {
            if (action.action_id === action_id) {
                return
            }
        }
        let new_action = new Action(action_info)
        this.actions.push(new_action);

        let after_action = this.get_action(after_action_id)
        if (after_action.next_action_ids.length >= 1) {
            // remove edge from after_action to its child
            let child_id = after_action.next_action_ids[at_index];
            let removed_at_index = this.__remove_edge(after_action_id, child_id);
            // add edge from after_action to new_action
            this.__add_edge(after_action_id, action_id, removed_at_index);
            // add edge from new_action to child
            this.__add_edge(action_id, child_id);
        }
        else {
            this.__add_edge(after_action_id, action_id);
        }
        // To rerender
        this.setWorkflowactions(this.get_workflow());
    }

    add_default_action(actions) {
        // if (!actions || actions.length === 0) {
        //     return;
        // }
        let action_to_remove = []
        // remove actions with is_default true using remove_action
        for (let action of this.actions) {
            if (action.is_default) {
                action_to_remove.push(action.action_id);
            }
        }
        if (actions && actions.length > 0) {
            // add actions with is_default true using add_action
            for (let action of actions) {
                let a = new Action(action);
                a.is_default = true;
                this.actions.push(a);
            }
            // remove adge from trigger to its child
            let trigger = this.get_action('trigger');
            if (trigger.next_action_ids.length > 1) {
                throw new Error('Trigger cannot have multiple children');
            }
            if (trigger.next_action_ids.length === 1) {
                let child_id = trigger.next_action_ids[0];
                this.__remove_edge('trigger', child_id);
                // add edge from trigger to default action
                this.__add_edge('trigger', actions[0].action_id);
                // add edge from last default action to child
                this.__add_edge(actions[actions.length - 1].action_id, child_id);
            }
            if (trigger.next_action_ids.length === 0) {
                // add edge from trigger to default action
                this.__add_edge('trigger', actions[0].action_id);
            }
        }
        // remove actions with is_default true using remove_action
        for (let action_id of action_to_remove) {
            this.__remove_action(action_id);
        }
        // To rerender
        this.setWorkflowactions(this.get_workflow());
    }

    add_trigger(action_info) {
        let action_id = 'trigger'
        // action_info.action_id = action_id;
        // Check if action_id already exists
        let if_exists = false;
        for (let action of this.actions) {
            if (action.action_id === action_id) {
                action.update_action_info(action_info);
                if_exists = true;
                break;
            }
        }
        if (!if_exists) {
            this.actions.push(new Action(action_info));
        }
        // To rerender
        this.setWorkflowactions(this.get_workflow());
    }

    change_action_id(action_id, new_action_id, newactioninfo) {
        let action = this.get_action(action_id);
        action.action_id = new_action_id;
        // lookup every next_action_ids and replace action_id with new_action_id
        for (let action of this.actions) {
            let index = action.next_action_ids.indexOf(action_id);
            if (index !== -1) {
                action.next_action_ids[index] = new_action_id;
            }
        }
        action.update_action_info(newactioninfo)
        this.setWorkflowactions(this.get_workflow());
    }

    change_action_to_unknown(action_id) {
        let action = this.get_action(action_id);
        action.remove_action_info()
        action.platform = "unknown"
        let new_action_id = 'unknown-' + Math.floor(Math.random() * 100000)
        this.change_action_id(action_id, new_action_id, {});
        // this.setWorkflowactions(this.get_workflow());
        return new_action_id;
    }

    __remove_action(action_id) {
        if (action_id === 'trigger') {
            throw new Error('Cannot remove ActionId: trigger');
        }
        let action_to_remove = this.get_action(action_id);
        if (action_to_remove == null) {
        }
        else {
            // child of action_to_remove
            let child_ids = action_to_remove.next_action_ids;
            if (child_ids.length > 1) {
                // Handle case where action_to_remove has multiple children
                throw new Error(`Cannot remove ActionId: ${action_id} with multiple children`);
            }
            // parent of action_to_remove
            let parent_id = this.get_parent(action_id);
            if (parent_id) {
                // Disconnect from parent
                this.__remove_edge(parent_id, action_id);
                if (child_ids.length === 1) {
                    let child_id = child_ids[0];
                    // Disconnect from child
                    this.__remove_edge(action_id, child_id);
                    // Connect parent to child
                    this.__add_edge(parent_id, child_id);
                }
                else {
                    // action_to_remove has no children
                }
            }
        }
        // remve action id from every next_action_ids
        for (let action of this.actions) {
            let index = action.next_action_ids.indexOf(action_id);
            if (index !== -1) {
                action.next_action_ids.splice(index, 1);
            }
        }
        // remove action from list
        this.__delete_action(action_id);
        // To rerender
        // this.setWorkflowactions(this.get_workflow());
    }

    remove_action(action_id) {
        this.__remove_action(action_id);
        this.setWorkflowactions(this.get_workflow());
    }

    move_node(action_id_to_move, before_action_id) {
        // action_id_to_move is the action which is to be moved
        // before_action_id is the action before which the action_id_to_move is to be moved
        if (action_id_to_move === 'trigger' || before_action_id === 'trigger') {
            throw new Error('Cannot move trigger');
        }
        let action_to_move = this.get_action(action_id_to_move);
        // Just to check if before_action_id exists
        this.get_action(before_action_id);
        if (action_to_move == null) {
            throw new Error(`ActionId: ${action_id_to_move} to move does not exist`);
        }
        if (action_to_move.next_action_ids.length > 1) {
            throw new Error(`Cannot move ActionId: ${action_id_to_move} with multiple children`);
        }
        if (action_to_move.next_action_ids.length !== 0) {
            // child of action_to_move is the action which is connected to the action_to_move
            let child_of_action_to_move = action_to_move.next_action_ids[0];
            // Disconnect the child from the action_to_move
            let index = this.__remove_edge(action_id_to_move, child_of_action_to_move);
            // parent of action_to_move
            let old_parent_id = this.get_parent(action_id_to_move);
            if (!old_parent_id) {
                throw new Error(`ActionId: ${action_id_to_move} does not have a parent`);
            }
            // Connect the child of action_to_move to the parent of action_to_move
            this.__add_edge(old_parent_id, child_of_action_to_move, index);
        }
        else {
            // parent of action_to_move
            let old_parent_id = this.get_parent(action_id_to_move);
            if (!old_parent_id) {
                throw new Error(`ActionId: ${action_id_to_move} does not have a parent`);
            }
            // Disconnect from old parent since it has no children
            this.__remove_edge(old_parent_id, action_id_to_move);
        }
        // parent of before_action_id
        let new_parent_id = this.get_parent(before_action_id);
        if (!new_parent_id) {
            throw new Error(`ActionId: ${before_action_id} does not have a parent`);
        }
        // Disconnect the before_action_id from its parent
        let index = this.__remove_edge(new_parent_id, before_action_id);
        // Connect the action_to_move to the parent of before_action_id
        this.__add_edge(new_parent_id, action_id_to_move, index);
        // Connect the before_action_id to the action_to_move
        this.__add_edge(action_id_to_move, before_action_id);
        // To rerender
        this.setWorkflowactions(this.get_workflow());
    }

    // Getter methods
    get_action(action_id) {
        for (let action of this.actions) {
            if (action.action_id === action_id) {
                return action;
            }
        }
        throw new Error(`ActionId: ${action_id} does not exist`);
    }

    get_actions_by_order() {
        if (this.actions.length === 0) {
            return [];
        }

        let trigger = this.get_action('trigger');
        let actions_by_order = [];
        actions_by_order.push(trigger);
        let next_actions = trigger.next_action_ids;
        if (next_actions.length > 1 || next_actions.length === 0) {
            return actions_by_order;
        }

        let next_action = this.get_action(next_actions[0]);
        actions_by_order.push(next_action);

        while (next_action.next_action_ids.length === 1) {
            next_action = this.get_action(next_action.next_action_ids[0]);
            actions_by_order.push(next_action);
        }
        return actions_by_order;
    }

    get_parent(action_id) {
        // one action can have only one parent
        for (let action of this.actions) {
            if (action.next_action_ids.includes(action_id)) {
                return action.action_id;
            }
        }
        return null
        // throw new Error(`ActionId: ${action_id} does not have a parent`);
    }

    get_parent_chain(action_id) {
        let parent_chain = [];
        if (action_id === 'trigger') {
            return parent_chain;
        }
        while (action_id !== 'trigger') {
            let parent_id = this.get_parent(action_id);
            if (!parent_id) {
                break
            }
            parent_chain.push(parent_id);
            action_id = parent_id;
        }
        return parent_chain;
    }

    get_workflow() {
        let workflow = [];
        for (let action of this.actions) {
            workflow.push(action.get_action_info());
        }
        return workflow;
    }

    // private methods, not to be used outside this class
    __delete_action(action_id) {
        // remove the action from list, without handling the edges
        this.actions = this.actions.filter(action => action.action_id !== action_id);
    }

    __add_edge(from_action_id, to_action_id, at_index = null) {
        let from_action = this.get_action(from_action_id);
        if (at_index != null) {
            if (at_index < from_action.next_action_ids.length) {
                from_action.next_action_ids.splice(at_index, 0, to_action_id);
            }
            else if (at_index === from_action.next_action_ids.length) {
                from_action.next_action_ids.push(to_action_id);
            }
            else {
                throw new Error('Cannot add edge at index greater than number of children');
            }
        }
        else {
            from_action.next_action_ids.push(to_action_id);
        }
    }


    __remove_edge(from_action_id, to_action_id) {
        let from_action = this.get_action(from_action_id);
        let removed_index = from_action.next_action_ids.indexOf(to_action_id);
        // from_action.next_action_ids.remove(to_action_id);
        from_action.next_action_ids = from_action.next_action_ids.filter(action_id => action_id !== to_action_id);
        return removed_index;
    }


}

// var workflow = new Workflow("Test", [])
// // # 0 1 3 5 7
// // # 0 1 3 8 9
// // # 0 2 4 6
// workflow.add_action({action_id:'trigger', next_action_ids:[1, 2]})
// // workflow.add_action(action_id=1, next_action_ids=[3])
// workflow.add_action({action_id:1, next_action_ids:[3]})
// // workflow.add_action(action_id=2, next_action_ids=[4])
// workflow.add_action({action_id:2, next_action_ids:[4]})
// // workflow.add_action(action_id=3, next_action_ids=[5, 8])
// workflow.add_action({action_id:3, next_action_ids:[5, 8]})
// // workflow.add_action(action_id=4, next_action_ids=[6])
// workflow.add_action({action_id:4, next_action_ids:[6]})
// // workflow.add_action(action_id=6, next_action_ids=[])
// workflow.add_action({action_id:6, next_action_ids:[]})
// // workflow.add_action(action_id=5, next_action_ids=[7])
// workflow.add_action({action_id:5, next_action_ids:[7]})
// // workflow.add_action(action_id=8, next_action_ids=[9])
// workflow.add_action({action_id:8, next_action_ids:[9]})
// // workflow.add_action(action_id=7, next_action_ids=[])
// workflow.add_action({action_id:7, next_action_ids:[]})
// // workflow.add_action(action_id=9, next_action_ids=[])
// workflow.add_action({action_id:9, next_action_ids:[]})


// // workflow.remove_action(8)

// workflow.move_node(4, 8)