const isValidBox = (elementData,ElementSetting) => {
  //console.log("elementData",ElementSetting)
  //const { isCheck } = ElementSetting;

  let boxValidationResult = {};
  // console.log("optio", ElementSetting);
  // const check = ElementSetting?.some((item) => item.isCheck === true);
  // //const len = ElementSetting?.every((item) => item.isCheck === true);
  // const len = ElementSetting.every((item) => item.isCheck ==+ false);
  // console.log("len", ElementSetting.every((item) => item.isCheck ==+ false));

  // if (check !== true) {
  //   boxValidationResult = {
  //     result: false,
  //     message: "Select at least nj one option",
  //   };
  // }
  return boxValidationResult;
};

const isBoxRequired = (elementData,ElementSetting) => {
  let CheckboxValidationResult = {};
  const { required,options } = ElementSetting;
 // console.log("elr",ElementSetting)
  let check = options?.some((item) => item.isCheck === true);
  
  if ( required && !check) {
    CheckboxValidationResult = {
      result: false,
      message: "Select at least one option",
    };
  } else {
    CheckboxValidationResult = { result: true, message: "" };
  }
  return CheckboxValidationResult;
};

export { isValidBox, isBoxRequired };
