import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { CheckBoxUi} from './CheckBoxUi'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'

export const DndCheckBoxUi = ({elementid,element,oppFields}) => {
    const {board,setboard}=useContext(boardConetxt)
  return (
    <div>
        <ElementsSettingsWidget
        BoardDeleteHandler={BoardDeleteHandler}
        elementid={elementid}
        board={board}
        setboard={setboard}
        element={element}
        />
        <CheckBoxUi elementid={elementid} oppFields={oppFields}/>
    </div>
  )
}
