import React, { useContext, useState} from "react";
import { gql, useMutation } from '@apollo/client';
import { WorkFlowSessionInfo } from '../../../pages/automation-builder/workflows/EditWorkflow'


const CreateFubTrigger = gql`
mutation CreateFubTrigger($type: String!) {
  CreateFubTrigger(type: $type) {
    statusCode
    message
    data
    trigger_id
  }
}`;

export function TriggersList({ platform, triggerdetails, setplatform, setTriggerDetails, availableTriggers, details, setdetails, setIsTriggerCreated}) {

  const { workflow_helper } = useContext(WorkFlowSessionInfo);

  const [
    createtrigger,
    { data: createfubdata, loading: creatingtrigger, error: createerror },
  ] = useMutation(CreateFubTrigger, {
    onCompleted: (createfubdata) => {

      setTriggerDetails({ ...details,test_status:"Not Tested", data_mapper: { ...details.data_mapper, trigger_id: createfubdata?.CreateFubTrigger.trigger_id } });

      workflow_helper.current.add_trigger({ ...details, test_status:"Not Tested",data_mapper: { ...details.data_mapper, trigger_id: createfubdata?.CreateFubTrigger.trigger_id } });

      setIsTriggerCreated("created");

      if(createfubdata?.CreateFubTrigger?.statusCode !== 200){
         createfubdata?.CreateFubTrigger?.data?.errorMessage
          ? alert(createfubdata?.CreateFubTrigger?.data?.errorMessage) 
          : alert("An unkown error occured.")
      }
    },
  });

  if (creatingtrigger) return 'Loading...';

  if (createerror) return `Error! ${createerror.message}`;

  return (
    <>
      <div className="d-flex align-items-center">
        <div
          onClick={() => {
            setplatform("");
          }}
        >
          <i className="iconify me-2 back-btn" data-icon="ep:back"></i>
        </div>
        <p className="m-0">Select Trigger you want to Connect</p>
      </div>
      <div class="my-4 row row-cols-m2 gx-4">
        {Object.entries(availableTriggers).map(
          ([triggerCategory, triggers], index) => (
            
            <div class="p-d0 mb-4">
              <div  
                class="border-bottom d-flex justify-content-between align-items-center py-2 pointer"
                data-bs-toggle="collapse"
                data-bs-target={`#trig-${index}`}
              >
                <span>{triggerCategory} triggers</span>
                <i class="iconify" data-icon="mdi:chevron-down"></i>
              </div>
              <div id={`trig-${index}`} class="ps-lg-2 pt-3 collapse">
                {Object.entries(triggers).map(([triggertype, trigger], i) => (
                  <div
                    class="text-primary d-flex justify-content-between align-items-center py-2 pointer border rounded-2 px-2 mb-2"
                    key={i}
                    data-bs-toggle="modal"
                    data-bs-target="#triggerModal"
                    onClick={() => {
                      if (trigger.trigger_id) {
                        setTriggerDetails({
                          ...triggerdetails,
                          action_id: "trigger",
                          api_name: "trigger",
                          api_type: "trigger",
                          data_mapper: {
                            type: triggertype,
                            name: trigger.name,
                            description: trigger.description,
                            trigger_id: trigger.trigger_id,
                            platform: platform,
                          },
                        });
                        workflow_helper.current.add_trigger({
                          ...triggerdetails,
                          action_id: "trigger",
                          api_name: "trigger",
                          api_type: "trigger",
                          data_mapper: {
                            type: triggertype,
                            name: trigger.name,
                            description: trigger.description,
                            trigger_id: trigger.trigger_id,
                            platform: platform,
                          },
                        });

                        setplatform("");
                        setIsTriggerCreated("creating");
                      } else {

                        setdetails({
                          ...triggerdetails,
                          action_id: "trigger",
                          api_name: "trigger",
                          api_type: "trigger",
                          data_mapper: {
                            type: triggertype,
                            name: trigger.name,
                            description: trigger.description,
                            trigger_id: "",
                            platform: platform,
                          },
                        });

                        createtrigger({
                          variables: {
                            type: triggertype,
                          },
                        });
                        setIsTriggerCreated("creating");
                      }
                    }}
                  >
                    <span>
                      <i class="iconify" data-icon="ic:sharp-bolt"></i>{" "}
                      {trigger.name}
                    </span>
                    <i class="iconify" data-icon="mdi:chevron-right"></i>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
}

export default TriggersList;
