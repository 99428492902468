import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WorkflowHeader from "../../../components/WorkflowHeader";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Loader } from "../../../components/loader";
import { Opp_Forms } from "../../../components/form-builder-components/FormBuilderTemplates/Opp_Froms";
import { template_conditions } from "../../../components/form-builder-components/FormBuilderTemplates/template_conditions"

const getFubData=gql`
query GetFubAllData($type: String!) {
  GetFubAllData(type: $type) {
    output
    status
    info
  }
}`


function CreateForm() {
  const [newForm, setNewForm] = useState({
    name: "",
    description: "",
    form_template: "",
    useTemplate: "",
    form_type: "",
    pipelineName:"",
    StageName:""
  });
  const [form_template, setForm_template] = useState()
  const [template_condition, set_template_conditon] = useState()
  const [formtypeData, setFormtypeData] = useState({})
  const [pipelineData, setPipelineData] = useState([]);
  const [stageData, setStageData] = useState([]);

  const { data: fubData, loading: fubLoading, error: fubError } = useQuery(getFubData, {
    variables: {
      type: "pipelines"
    },
    // onCompleted: (data) => {
    //   setFormtypeData(data.GetFubAllData.output)
    // }
  })
  console.log("fubData", fubData)

  useEffect(() => {
    if (fubData) {
      setPipelineData(fubData.GetFubAllData.output)
    }
  }, [fubData])
  console.log("pipelineData", pipelineData)

  const handleTemplate = () => {
    if (newForm.form_type == "Opportunity Forms" && newForm.form_template == "Appointment_Form" && newForm.useTemplate == "Yes") {
      setForm_template(Opp_Forms.Appointment_Form)
      set_template_conditon(template_conditions.Appointment_Form_Conditions)

    } else if (newForm.form_type == "Opportunity Forms" && newForm.useTemplate == "Yes" && newForm.form_template == "Transaction_Form") {
      setForm_template(Opp_Forms.Transaction_Form)
      set_template_conditon(template_conditions.Transaction_Form_Conditions)
    } else if (newForm.form_type == "Opportunity Forms" && newForm.useTemplate == "Yes" && newForm.form_template == "Disposition_Form") {
      setForm_template(Opp_Forms.Disposition_Form)
      set_template_conditon(template_conditions.Disposition_Form_Conditions)
    } else if (newForm.form_type == "Opportunity Forms" && newForm.useTemplate == "Yes" && newForm.form_template == "Terminate_Form") {
      setForm_template(Opp_Forms.Terminate_Form)
      set_template_conditon(template_conditions?.Terminate_Form_Conditions)
    } else if (newForm.form_type == "Opportunity Forms" && newForm.useTemplate == "No") {
      setForm_template(["InputField-opp-key", "InputField-Follow-up-boss-lead-id"])
      set_template_conditon({})
    } else if (newForm.form_type == "Lead Forms" && newForm.useTemplate == "No") {
      setForm_template(["InputField-lead-key"])
      set_template_conditon({})
    }
  }
  useEffect(() => {
    handleTemplate()
  }, [newForm.form_template, newForm.form_type, newForm.useTemplate])
  console.log("newForm", form_template)

  const navigate = useNavigate();

  const CreateForm = gql`
  mutation CreateForm($formData: GraphQLJSON) {
    CreateForm(form_data: $formData) {
      team_id
      form_id
    }
  }
  `;

  const [creatForm, { data, loading, error }] = useMutation(CreateForm, {
    onCompleted: (data) => {
      const form_id = data.CreateForm.form_id;
      navigate(`/forms/build/${form_id}`);
    },
  });

  const templateOptions = ['Appointment_Form', 'Transaction_Form', 'Disposition_Form', 'Terminate_Form']
  const formtype = ["Lead Forms", "Opportunity Forms"]

  console.log("formtypeData", formtypeData)
  if (loading) return <div dangerouslySetInnerHTML={{ __html: Loader }} />;



  console.log("newForm", form_template)
  const createNewForm = (payload) => {
    creatForm({
      variables: {
        formData: {
          form_name: payload.name,
          form_description: payload.description,
          base_form_template: payload.form_template,
          form_fields: form_template == undefined ? {} : form_template,
          form_conditions: template_condition == undefined ? {} : template_condition,
          form_type: payload.form_type,
           useTemplate: payload.useTemplate,
          pipelineName:payload.pipelineName,
          StageName:payload.StageName,
          status: true,
        },
      },
    });
  };

  const inputHandler = (e) => {
    //console.log("e.target.name", e.target.name,e.target.value);
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setNewForm({ ...newForm, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createNewForm({ name: newForm.name, description: newForm.description, form_template: newForm.form_template, form_type: newForm.form_type, useTemplate: newForm.useTemplate, pipelineName:newForm.pipelineName,StageName:newForm.StageName});
  };
  const option = ["Choose", "Yes", "No"]
console.log("newForm",newForm)


  console.log("form_template", newForm)
  return (
    <>
      <div className="main container mx-auto" id="body-pd">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5" />
              <i className="bx bx-arrow-to-right me-2 fs-5" />
              <i className="bx bx-desktop me-2 fs-5" />
            </div>
            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens
              are not efficient. To learn more click here
            </span>
          </div>
        </div>
        <section className="col-xl-7 mx-auto">
          <div className="bg-white p-4 rounded-3 border">
            <h5>New Form</h5>
            <p className="text-secondary mt-2">
              Add a title and description for your form
            </p>
            <form className="mt-lg-5 mt-4" action="" onSubmit={handleSubmit}>
              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <input
                    placeholder="Workspace title "
                    className="form-control input-bn"
                    type="text"
                    required
                    maxLength={30}
                    name="name"
                    value={newForm.name}
                    onChange={inputHandler}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="ri:flow-chart"
                      />
                      <span>Form Name</span>
                    </span>
                  </label>
                </div>
                {/* placeholder for errors */}
              </div>
              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <textarea
                    placeholder="Description "
                    className="form-control input-bn"
                    type="text"
                    // required
                    maxLength={100}
                    name="description"
                    value={newForm.description}
                    onChange={inputHandler}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:text"
                      />
                      <span>Form Description</span>
                    </span>
                  </label>
                </div>
              </div>

              <div className="mb-4">
                <div className="form-floating w-100 form-group">
                  <select
                    className="form-select input-bn"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    name="form_type"
                    required
                    value={newForm.form_type}
                    onChange={inputHandler}
                  >
                    <option value="">Selcet Form Type</option>
                    {formtype?.map((item, index) => {
                      return (
                        <option key={index} value={item}>{item}</option>
                      )
                    })}
                  </select>
                  <label htmlFor="floatingSelect">Select Form Type</label>
                </div>
              </div>

                              {/* {/* Temo */}
                              <div className="mb-4">
                      <div className="form-floating w-100 form-group">
                        <select
                          className="form-select input-bn"
                          placeholder="Please Select"
                          //onChange={inputHandler}
                          onChange={inputHandler}
                          name="pipelineName"
                        >
                          <option value="" disabled selected hidden>Choose</option>
                          {
                            pipelineData?.map((item, index) => {
                              return (
                                <option key={index} value={item.name}>{item.name}</option>
                              )
                            }
                            )
                          }
                        </select>
                      <label>
                          <span className="d-flex align-items-center">
                            <i
                              className="iconify me-1 mr-1 fs-5"
                              data-icon="mdi:text"
                            />
                            <span>Select pipeline</span>
                          </span>
                        </label>

                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="form-floating w-100 form-group">
                        <select
                          className="form-select input-bn"
                          placeholder="Please Select"
                          //onChange={inputHandler}
                          onChange={inputHandler}
                          name="StageName"
                        >
                          <option value="" disabled selected hidden>Choose</option>
                          {
                            pipelineData.map((item, index) => {
                              if(item.name==newForm.pipelineName){
                                return (
                                  item.stages.map((item,index)=>{
                                    return(
                                      <option key={index} value={item.name}>{item.name}</option>
                                    )
                                  })
                                )
                              }
                            }
                            )
                          }
                        </select>
                      <label>
                          <span className="d-flex align-items-center">
                            <i
                              className="iconify me-1 mr-1 fs-5"
                              data-icon="mdi:text"
                            />
                            <span>SelectStage</span>
                          </span>
                        </label>

                      </div>
                    </div>




                    {/* //template */}





              {
                newForm.form_type == "Opportunity Forms" && (
                  <>
                    <div className="mb-4">
                      <div className="form-floating w-100 form-group">
                        <select
                          className="form-select input-bn"
                          placeholder="Please Select"
                          //onChange={inputHandler}
                          onChange={inputHandler}
                          name="useTemplate"
                        >
                          <option value="" disabled selected hidden>Choose</option>
                          {
                            option.map((item, index) => {
                              return (
                                <option key={index} value={item}>{item}</option>
                              )
                            }
                            )
                          }
                        </select>

       




                        <label>
                          <span className="d-flex align-items-center">
                            <i
                              className="iconify me-1 mr-1 fs-5"
                              data-icon="mdi:text"
                            />
                            <span>Do You want to Use Templates</span>
                          </span>
                        </label>

                      </div>
                    </div>

          

                    {newForm.useTemplate == "Yes" &&
                      <div className="mb-4">
                        <div className="form-floating w-100 form-group">

                          <select
                            className="form-select input-bn"
                            placeholder="Please Select"
                            onChange={inputHandler}
                            name="form_template"
                          >
                            <option value="" disabled selected hidden>Choose Template</option>
                            {templateOptions.map((option, index) => {
                              const optionValue = option.replace(/_/g, " ");
                              //console.log("option", optionValue);
                              return <option key={index} value={option}>{optionValue}</option>;
                            })}
                          </select>
                          <label>
                            <span className="d-flex align-items-center">
                              <i
                                className="iconify me-1 mr-1 fs-5"
                                data-icon="mdi:text"
                              />
                              <span>Choose Template</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    }
                  </>)
              }


              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="d-flex align-items-center btn btn-primary"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
}

export default CreateForm;
