import { useContext, useEffect, useRef, useState } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import Validation from "../../../utils/Validation";
import { TimePicker } from "antd";
// import TimePicker from 'react-time-picker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';

import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
export const TimeUi = ({
  elementid,
  oppFields,
  PreviewData,
  showHide,
  previewFlag = false,
}) => {
  const { board, setboard } = useContext(boardConetxt);
  const timeRef = useRef(null);
  const [errorMessage, seterrorMessage] = useState({});
  const [val, setval] = useState(null);
  const [copy,setCopy]=useState(false);

  const {
    register,
    formState: { errors },
    control,setValue
  } = useFormContext();
  const Location=useLocation();
  const params=new URLSearchParams(Location.search);

  useEffect(()=>{
    if(elementid,params.get(elementid.replace(/-\d+$/, ''))){
      setValue(elementid,elementid,params.get(elementid.replace(/-\d+$/, '')))
    } 
  
  },[elementid,params.get(elementid.replace(/-\d+$/, ''))])

  const contactValidationResult = (e) => {
    e.preventDefault();
    let elementData = {};
    elementData.Time = timeRef.current.value;

    let elementSettings = {};
    let euuid = e.target.id + "-" + elementid;
    let validation = Validation(euuid, elementData, elementSettings);
    let { TimeCheck } = validation.validateTime();

    seterrorMessage(TimeCheck);
  };

  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const { Time, placeHolder, instructions, required, hidden } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const onChange = (time, timeString) => {
    setval(time);
  };
  //console.log("val", errors);
  if (val !== null) {
    var { $H, $M } = val;
  }
  const Times = `${$H}:${$M}`;
  const format = "HH:mm a";

  useEffect(() => {
    const newboard = board?.map((item) => {
      if (item?.elementid === elementid) {
        return { ...item, settings: { ...item.settings, Time: Times } };
      }
      return item;
    });
    setboard(newboard);

  }, [val]);
  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])
  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])
  const label = board.find((item) => item?.elementid === elementid).name;
  return (
    <div
      className={previewFlag && GetStyleClasses(elementid) ||  ""}
      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating form-group">
        {/* <input
          placeholder="Sample Input "
          className="form-control input-bn"
          type="time"
          required={true}
          name="Time"
          ref={timeRef}
          value={Time !== "" ? Time : timeRef.current?.value}
          onBlur={(e) => contactValidationResult(e)}
          onChange={(e) => contactValidationResult(e)}
          id={elementid}
        /> */}
        <Controller
          name={elementid}
          control={control}
          rules={{  required: {
            value: required === true ? true : false,
            message: "This Field is Required",
          } }}
          defaultValue=""
          render={({ field:{onChange} }) => (
            <TimePicker
            className="form-control input-bn"
              format={format}
              onChange={(date, dateString) => {
                //console.log("date", dateString);
                onChange(dateString);
                setval(date);
              }}
              // onChange={(value,valueString) => {
              //   //console.log("value>", value)
              //   field.onChange(valueString);
              //   // setval(valueString);
              // }}
              // value={field.value}
            />
          )}
        />
        {/* <Controller
          name="Time"
          control={controls}
          defaultValue={Time !== "" ? Time : timeRef.current?.value}
          render={({ field }) => (
            <TimePicker
              className="form-control input-bn"
              format={format}
              onChange={(date, dateString) => {
                //console.log("date", dateString);
                field.onChange(dateString);
                setval(date);
              }}
            ></TimePicker>
          )}
        /> */}
        {/* <TimePicker
          className="form-control input-bn"
          format={format} onChange={onChange}
        ></TimePicker> */}
        <label>
          <span className="d-flex align-items-center">
            <span>
              {/* {placeHolder ? placeHolder : label} */}
              {copy ? placeHolder == undefined ? label : placeHolder+"-copy": placeHolder ? placeHolder : label}

              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      </div>

      <small className="text-secondary">{instructions}</small>
      {/* placeholder for errors */}
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
      <ElementsErrorMessage errorMessage={errorMessage} />
    </div>
  );
};
