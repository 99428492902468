import React, { useState } from "react";

const AccordionDefaultOptions = ({
  setSelectedOption,
  set_insert_value,
  field_id,
  select_modal,
  options,
  insert_value,
  text_value,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleValueSearchChange = (e) => {
    // Handles the search input change in value field modal
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  function getOptions() {
    const filteredOptions = options?.filter((option) => {
      const keyShowString = option.keyShow ? option.keyShow.toString() : "";
      const valueString = option.value ? option.value.toString() : "";
      const keyString = option.key ? option.key.toString() : "";

      return (
        keyShowString
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchValue.toLowerCase().split(" ").join("")) ||
        valueString
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchValue.toLowerCase().split(" ").join("")) ||
        keyString
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchValue.toLowerCase().split(" ").join(""))
      );
    });
    const filtered = filteredOptions?.map((option, i) => (
      <>
        <div
          className="pointer p-3 df-options d-flex align-items-center border-bottom"
          key={option}
          onClick={(event) => {
            setSelectedOption(option.value);
            set_insert_value(option.key);
          }}
        >
          <input
            className="me-2 pointer form-check-input"
            type="radio"
            value={insert_value}
            checked={insert_value === option.key || text_value === option.key}
          />
          <label
            className="form-check-label pointer mt-1"
            htmlFor={"flexRadioDefault" + option.key}
          >
            <div>
              <span className="small">
                <b>{option.value}</b>
              </span>
            </div>
            <div className="fs-label">
              <span className="text-secondary opacity-70">{option.keyShow}</span>
            </div>
          </label>
        </div>
      </>
    ));
    return (
      <div>
        {filtered?.length !== 0 ? (
          filtered
        ) : (
          <div>
            No matches found.
            {/* Use{" "}
            <div
              className="btn p-0 search-input-btn"
              onClick={(event) => {set_insert_value(searchInput)
                setSelectedOption(searchInput)
              }}
            >
              <u>{searchInput}</u>
            </div> */}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="collapse w-100 px-0 pb-1 bg-white col-12 f-drop border shadow-lg"
      id={"modal_" + field_id}
      style={{ position: "absolute", maxWidth: "100%" }}
    >
      <div className="w-100 p-3 d-flex align-items-center justify-content-between bg-color-1 border-bottom">
        <h5 className="fs-6 m-0">Select Data</h5>
        <div onClick={(e) => select_modal("")}>
          <i className="pointer iconify fs-5" data-icon="mdi:close"></i>
        </div>
      </div>
      <div className="p-3 pb-0 f-bd-ac">
        <input
          type="search"
          className="form-control search-fgy input-bn"
          style={{ height: "40px !important", display: "block" }}
          placeholder="Search"
          onChange={handleValueSearchChange}
          value={searchValue}
        />
        <div className="mt-3 overflow-y-scroll gx-up">{getOptions()}</div>
      </div>
    </div>
  );
};

export default AccordionDefaultOptions;
