import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'
import { DropDownUi } from './DropDownUi'

export const DndDropDownUi = ({elementid,element,oppFields}) => {
  const {board,setboard} = useContext(boardConetxt)
  return (
    <div className="mb-4" id={elementid}>
        <ElementsSettingsWidget
        elementid={elementid}
        BoardDeleteHandler={BoardDeleteHandler}
        board={board}
        setboard={setboard}
        element={element}
/>
        <DropDownUi elementid={elementid} oppFields={oppFields}/>

    </div>
  )
}
