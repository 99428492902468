import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useFormApiHelper from '../../../utils/useFormApiHelper'
import { Loaderr } from '../../../components/Loaderr';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

const getFubData = gql`
query GetFubAllData($type: String!) {
  GetFubAllData(type: $type) {
    output
    status
    info
  }
}`

export default function SettingsPage() {
  const { GetFormData } = useFormApiHelper();
  const parmas = useParams();
  const { formDataLoading, formDataError, formData } = GetFormData(parmas);
  const [FormEntryData, setFormEntryData] = React.useState({
    "pipelineName": "",
    "StageName": "",
  });
  const { data: fubData, loading: fubLoading, error: fubError } = useQuery(getFubData, {
    variables: {
      type: "pipelines"
    },

  })
  console.log("fubData", fubData);
  const inputHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormEntryData((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  // useEffect(()=>{
  //   setFormEntryData((prev)=>({
  //     ...prev,
  //     "pipelineName":formData?.formData?.pipelineName,
  //     "StageName": formData?.formData?.StageName
  //   }))
  // },[formData])
  console.log("FormEntryData", FormEntryData, formData)

  if (formDataLoading) return <Loaderr />
  return (
    <>
      <div className="main fmc-main">
        <section className="fm-condition">
          <section className="col-xxl-5 col-lg-6 col-md-9 mb-5 pb-5 col-12 mx-auto">
            <div className="d-flex flex-wrap justify-content-between align-items-center mb-2 py-4">
              <h5 className="m-0">
                <i className="iconify me-1 nav_icon" data-icon="fa-solid:cog" />{" "}
                Settings
              </h5>
            </div>
            {/* -------------------------------------------------------------- */}
            <div className="bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4 ">
              <h5 className="fs-6 m-0 mb-4">Form Settings</h5>
              <div className="mb-3 disable-default-field-div">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="tdesign:form"
                  />{" "}
                  Form Type
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3 disabled">
                  <option >{formData?.formData?.form_type}</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:house-user"
                  />
                  Buyer or Seller
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3"
                  onChange={inputHandler}
                  name="pipelineName"
                // value={FormEntryData?.pipelineName}
                >
                  <option value="" disabled selected hidden>Choose</option>
                  {fubData?.GetFubAllData?.output?.map((item, index) => (
                    <option key={index}>{item.name}</option>
                  ))


                  }

                </select>
              </div>
              <div className="iconify mb-3" data-icon="cil:arrow-bottom" />
              <div className="mb-3">
                <label className="mb-2 d-flex align-items-center">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:bars-staggered"
                  />
                  Select stage
                </label>
                <select className="form-control form-select input-bn search-fgy mb-3"
                  onChange={inputHandler}
                  name="StageName"
                // value={FormEntryData?.StageName}
                >
                  <option value="" disabled selected hidden>Choose</option>
                  {

                    fubData?.GetFubAllData?.output.map((item, index) => {
                      console.log("item", FormEntryData?.pipelineName)
                      if (item.name === FormEntryData?.pipelineName) {
                        return (
                          item.stages.map((items, index) => {
                            return (
                              <option key={index} value={items.name}>{items.name}</option>
                            )
                          })
                        )
                      }
                    }
                    )
                  }
                  {/* <option>-- Select Stage --</option>
            <option>Appointment Met</option> */}
                </select>
              </div>
            </div>
            {/* --------------------------------------------------------------- */}
            <div className="bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4 ">
              <div className="d-flex justify-content-between">
                <h5 className="fs-6 m-0">Field Mapping</h5>
                <i
                  className="iconify fs-6 me-2 nav_icon pointer"
                  data-icon="fa6-solid:arrow-up-right-from-square"
                />
              </div>
              <hr className="h op-10" />
              <div>
                <p className="fs-6 m-0 mt-3">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:arrows-split-up-and-left"
                  />
                  Total mapped field : <b className="text-primary">14</b>
                </p>
                <p className="text-secondary small mt-2">
                  There are 14 form field connected and mapped to FUB or respective
                  CRM
                </p>
              </div>
            </div>
            {/* --------------------------------------------------------------- */}
            <div className="bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-5 p-4 ">
              <div className="d-flex justify-content-between">
                <h5 className="fs-6 m-0">Automation Trigger </h5>
                <i
                  className="iconify fs-6 me-2 nav_icon pointer"
                  data-icon="fa6-solid:arrow-up-right-from-square"
                />
              </div>
              <hr className="h op-10" />
              <div>
                <p className="fs-6 m-0 mt-3">
                  <i
                    className="iconify fs-6 me-2 nav_icon"
                    data-icon="fa6-solid:bolt"
                  />
                  Total Workflow triggered : <b className="text-primary">8</b>
                </p>
                <p className="text-secondary small mt-2">
                  Forms can be used to trigger workflow in automation builder, this
                  could be upon submission of form or selection of a field.{" "}
                </p>
              </div>
            </div>
            {/* --------------------------------------------------------------- */}
          </section>
        </section>
      </div>

    </>

  )
}
