import React, { useContext } from "react";
import { ElementContext } from "../../../pages/form-builder/dashboard/SettingContext";
import uuidGenerator from "../../../utils/uuidGenerator";


export function ElementsSettingsWidget({BoardDeleteHandler, elementid,board,setboard,element}) {
  const {setSetElement}=useContext(ElementContext);
  ////console.log("ElementSetting",setSetElement)
  const handelSettings=(e)=>{
    e.preventDefault();
    setSetElement({elementid:elementid,type:element.type});
    ////console.log("ElementSetting",elementid)
  }
  const handelCopy=(eleId,e)=>{
    e.preventDefault();
    const newBoard=board.find((item)=>item.elementid===eleId);
    const newObj={
      elementid: `${newBoard.type}-${Math.floor(Math.random() * 10000)}-copy`,
      name:`${newBoard.name}-copy`,
      type:newBoard.type,
      settings:newBoard.settings,
    }
    
   
    
    
    setboard([...board,newObj]);
    //console.log("newboard",board)
  }
  return (
    <div className="py-2 fm-widget w-75">
      <i className="iconify mx-1 fs-6" data-icon="ci:grid-vertical" />
      <span onClick={(e)=>{handelCopy(elementid,e)}}>

      <i className="iconify mx-1 fs-6" data-icon="fad:duplicate" />
      </span>
      {/* data-bs-toggle="modal" data-bs-target="#option-setting2" */}
      <span data-toggle="modal"  onClick={handelSettings} > 
        <i
          className="iconify mx-1 fs-6"
          data-icon="akar-icons:settings-horizontal"
          id={elementid}
        />
      </span>
      <span onClick={(e) => BoardDeleteHandler(e, elementid, board, setboard)}>
        <i
          className="iconify mx-1 fs-6 text-danger"
          data-icon="bx:trash"
          id={elementid}
        />
      </span>
    </div>
  );
}
