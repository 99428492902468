import { isValidEmail } from "./ElementValidation/ValidEmail";
import {isValidContact} from "./ElementValidation/ValidContact";
import {isValidNumber} from "./ElementValidation/ValidNumberField";
import {isValidTextarea,} from "./ElementValidation/ValidTextArea";
import { isValidDate } from "./ElementValidation/ValidDate";
import { isValidTime } from "./ElementValidation/ValidTime";
import { isValidBox } from "./ElementValidation/ValidCheckbox";
import {isValidInput,} from "./ElementValidation/ValidInputField";
import { isValidRadio } from "./ElementValidation/ValidateRadioBtn";
import {isValidDropDown} from "./ElementValidation/ValidateDropDown"


const Validation = (elementData, ElementSetting) => {

  let validationResult = [];
  
  let result,
    message = "";
  function validateEmail() {
    let emailChecks = {
      isValidEmail: ({ result, message } = isValidEmail(
        elementData,ElementSetting
      )),
    };

    return { ...validationResult, emailChecks };
  }

  function validateContact() {
    let contactChecks = {
      isValidContact: ({ result, message } = isValidContact(
        elementData,
        ElementSetting
      )),
    };

    return { ...validationResult, contactChecks };
  }

  function validateNumberField() {
    let numberChecks = {
      isValidNumber: ({ result, message } = isValidNumber(
        elementData,

        ElementSetting
      )),
    };
    return { ...validationResult, numberChecks };
  }

  function validateTextarea() {
    let textAreaChecks = {
      isValidTextarea: ({ result, message } = isValidTextarea(
        elementData,

        ElementSetting
      )),
    };
    return { ...validationResult, textAreaChecks };
  }

  function validateDate() {
    let dateCheck = {
      isValidDate: ({ result, message } = isValidDate(
        elementData,

        ElementSetting
      )),
    };
    return { ...validationResult, dateCheck };
  }

  function validateTime() {
    let TimeCheck = {
      isValidTime: ({ result, message } = isValidTime(
        elementData,

        ElementSetting
      )),
    };
    return { ...validationResult, TimeCheck };
  }

  function ValidateBox() {
    let Check = {
      isValidBox: ({ result, message } = isValidBox(elementData,ElementSetting)),
    };
    return { ...validationResult, Check };
  }
  function validateInput() {
    let inputCheck = {
      isValidInput: ({ result, message } = isValidInput( elementData,ElementSetting)),
    };
    return { ...validationResult, inputCheck };
  }
    function RadioCheck(){
      let RadioBtnCheck={
          isValidRadio:({result,message}=isValidRadio(elementData,ElementSetting)),
      }
      return {...validationResult,RadioBtnCheck}
    }

    function validateDropDown(){
      let DropDownCheck={
        isValidDropDown:({result,message}=isValidDropDown(elementData,ElementSetting)),}
    return {...validationResult,DropDownCheck}
  }

  return {
    validateEmail,
    validateInput,
    validateContact,
    validateNumberField,
    validateTextarea,
    validateDate,
    validateTime,
    ValidateBox,
    RadioCheck,
    validateDropDown
    
  };
};

export default Validation;
