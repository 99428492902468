export const template_conditions={
    Disposition_Form_Conditions:[
        {
            
            ConditionName: "conditionName",
            Fields: ["Date-opp_appt_met_date"],
            Visibility: "show",
            condtions:[
                    [{      
                        data_type: "dropdown",
                        expected_value: "yes",
                        operator: "equals",
                        field_id: "DropDown-opp_appt_disposition",
                    }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-85471254"],
            Visibility: "show",
            condtions:[
                    [     
                       { data_type: "dropdown",
                        expected_value: "no",
                        operator: "equals",
                        field_id: "DropDown-854782014",}
            ],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-854752155"],
            Visibility: "show",
            condtions:[
                   [ {      
                        data_type: "dropdown",
                        expected_value: "no",
                        operator: "equals",
                        field_id: "DropDown-opp_appt_disposition",
                    }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Headings-25448752"],
            Visibility: "show",
            condtions:[
                    [{      
                        data_type: "dropdown",
                        expected_value: "rescheduled",
                        operator: "equals",
                        field_id: "DropDown-opp_appt_disposition",
                    }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Date-rescheduled-app-date"],
            Visibility: "show",
            condtions:[
               [ {      
                    data_type: "dropdown",
                    expected_value: "rescheduled",
                    operator: "equals",
                    field_id: "DropDown-opp_appt_disposition",
                }],
                
            
            ]
        }
        ,{
            ConditionName: "conditionName",
            Fields: ["Time-rescheduled-time"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "rescheduled",
                    operator: "equals",
                    field_id: "DropDown-opp_appt_disposition",
                }],
                
            
            ]
        }
    ],
    Transaction_Form_Conditions:[
        {
            ConditionName: "conditionName",
            Fields: ["InputField-fub_deal_id"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Update Existing Transaction",
                    operator: "equals",
                    field_id: "DropDown-creating-updating-transaction",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Date-2225844558"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed",
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Date-2255744558"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed",
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Date-2255531558"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Pending", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Date-2255448558"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Closed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Date-2255445758"],
            Visibility: "show",
            condtions:[
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }]],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Pending", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }]],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Headings-888255785"],
            Visibility: "show",
            condtions:[
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Pending", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Closed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],]      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-87545221"],
            Visibility: "show",
            condtions:[
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Closed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Headings-888255785"],
            Visibility: "show",
            condtions:[
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Pending", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Closed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],]      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["FreeText-854752250"],
            Visibility: "show",
            condtions:[
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Pending", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Closed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],]      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Date-2255445458"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Accepted", 
                    operator: "equals",
                    field_id: "DropDown-pending-stage",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-555547852"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                },
            {
                data_type: "dropdown",
                    expected_value: "Seller", 
                    operator: "equals",
                    field_id: "DropDown-client-type",

            }
                ],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-555474145"],
            Visibility: "show",
            condtions:[
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Agreement Signed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Pending", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],],
                [ [{      
                    data_type: "dropdown",
                    expected_value: "Closed", 
                    operator: "equals",
                    field_id: "DropDown-transaction-stage",
                }],]      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-857854555"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes", 
                    operator: "equals",
                    field_id: "DropDown-857854275",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-5847457822"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes", 
                    operator: "equals",
                    field_id: "DropDown-894785245",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-5874857822"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes", 
                    operator: "equals",
                    field_id: "DropDown-894785245",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Contact-5874457822"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes", 
                    operator: "equals",
                    field_id: "DropDown-894785245",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Contact-8547214522"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes", 
                    operator: "equals",
                    field_id: "DropDown-552255485",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Email-854785245"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes", 
                    operator: "equals",
                    field_id: "DropDown-552255485",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Contact-854785245"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes", 
                    operator: "equals",
                    field_id: "DropDown-552255485",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-854785245"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-555224878",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-520258585"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-555224878",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Email-854248214"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-555224878",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-854785245"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-52225555",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-55785205"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-52225555",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Email-552478245"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-52225555",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Contact-854785245"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-52225555",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-85214742"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Loan", 
                    operator: "equals",
                    field_id: "DropDown-55247852",
                }],
                [{      
                    data_type: "dropdown",
                    expected_value: "Conventional", 
                    operator: "equals",
                    field_id: "DropDown-55247852",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-52245782"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-85214742",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-52245782"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-85214742",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Email-52245782"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-85214742",
                }],
                
            
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Contact-52245782"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Other", 
                    operator: "equals",
                    field_id: "DropDown-85214742",
                }],
                
            
            ]
        },









    ],   
     Appointment_Form_Conditions:[
        {
            ConditionName: "conditionName",
            Fields: ["Headings-12454124","NumberField-12121451","NumberField-11455444514","NumberField-1144544514",
 "FreeText-87455824484","InputField-8574521545","InputField-874521145","InputField-8745521545","InputField-8784521545",
"DropDown-8741242144","NumberField-235451254","NumberField-2354251254","NumberField-2354154254","InputField-254785425",
"DropDown-225478524","InputField-852545575","DropDown-85478547","DropDpon-25252457","InputField-32124521"       ],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Seller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["FreeText-87455824484","InputField-8574521545","InputField-8745521545","InputField-8784521545","DropDown-8741242144",
        ],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Seller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["NumberField-11455444514"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Seller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },

        {
            ConditionName: "conditionName",
            Fields: ["FreeText-874558244","InputField-8574521545","InputField-874521145","InputField-8745521545","InputField-8784521545",
        "DropDown-8741242144",],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Seller",
                    operator: "equals",
                    field_id: "DropDown-Appt-Location",
                }],
                     
            ]
        },{
            ConditionName: "conditionName",
            Fields: ["Headings-214578214","DropDown-32475474","InputField-25487421","NumberField-458452145","NumberField-582457852",
        "DropDown-585145214","FreeText-8574558244","InputField-857452145","InputField-875452145","InputField-87455257145","InputField-8745552145",
        "DropDown-8741244214","InputField-3145874","InputField-23547832","DropDown-822455235"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Buyer",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["NumberField-582457852"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Buyer",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["NumberField-458452145"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Buyer",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["NumberField-582457852"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Buyer",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-8745887"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "No Not Primary decision maker",
                    operator: "equals",
                    field_id: "DropDown-85478214",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "Yes Primary but only decision maker",
                    operator: "equals",
                    field_id: "DropDown-85478214",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-85124785"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "No Not Primary decision maker",
                    operator: "equals",
                    field_id: "DropDown-85478214",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "Yes Primary but only decision maker",
                    operator: "equals",
                    field_id: "DropDown-85478214",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-opp_isa"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type:"dropdown",
                    expected_value: "ISA",
                    operator: "equals",
                    field_id: "DropDown-Appointment-Set-By",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-Admin"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type:"dropdown",
                    expected_value: "ISA",
                    operator: "equals",
                    field_id: "DropDown-Appointment-Set-By",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["NumberField-1144544514"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Seller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["FreeText-8574558244","InputField-857452145","InputField-875452145",
            "InputField-87455257145","InputField-8745552145","DropDown-8741244214"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Buyer",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-32475474"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Buyer",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["NumberField-12121451"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Seller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-25487421"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Rent",
                    operator: "equals",
                    field_id: "DropDown-32475474",
                }],
                    
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-21245872"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes",
                    operator: "equals",
                    field_id: "DropDown-854752145",
                }],
                    
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["NumberField-00000000000"],//Remining
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Seller Property  Address", 
                    operator: "equals",
                    field_id: "DropDown-appt_set_lead_type",
                },
                {
                    data_type: "dropdown",
                    expected_value: "BuyerSeller",
                    operator: "equals",
                    field_id: "DropDown-Appt-Location",
                }
            ]
                    
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Date-opp_appt_date"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes",
                    operator: "equals",
                    field_id: "DropDown-854782214",
                }],
                    
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-fub_appt_start_time"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes",
                    operator: "equals",
                    field_id: "DropDown-854782214",
                }],
                    
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-Appt-Location"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes",
                    operator: "equals",
                    field_id: "DropDown-854782214",
                }],
                    
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["InputField-zoom-link"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Zoom",
                    operator: "equals",
                    field_id: "DropDown-Appt-Location",
                }],
                    
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Contact-87417854"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "No Not Primary decision maker",
                    operator: "equals",
                    field_id: "DropDown-85478214",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "Yes Primary but only decision maker",
                    operator: "equals",
                    field_id: "DropDown-85478214",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["Email-52145875"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "No Not Primary decision maker",
                    operator: "equals",
                    field_id: "DropDown-85478214",
                }],
                [{
                    data_type: "dropdown",
                    expected_value: "Yes Primary but only decision maker",
                    operator: "equals",
                    field_id: "DropDown-85478214",
                }],      
            ]
        },
        {
            ConditionName: "conditionName",
            Fields: ["DropDown-app-type"],
            Visibility: "show",
            condtions:[
                [{      
                    data_type: "dropdown",
                    expected_value: "Yes",
                    operator: "equals",
                    field_id: "DropDown-854782214",
                }],
                    
            ]
        }
        
    ]
}