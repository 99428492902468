const isValidTextarea = (elementData, ElementSetting) => {
  let textAreaValidationResult = {};
  const { maxValue, required,minValue  } = ElementSetting;
  let Max
  if(maxValue==undefined){
    Max=30
  }else if(maxValue== ""){
    Max=30
  }else{
    Max=maxValue
  }
  let textArea = elementData?.textarea;
  //console.log(textArea)
  let textRegex = /^[\w ]+[a-zA-Z0-9_~!@#$%^&*()+={}[]:;'"><.,?]*$/;
  if (textRegex.test(textArea)) {
    textAreaValidationResult = {
      result: true,
      message: "",
    };
  } if(textArea?.length >= 1 &&textArea?.length >= Max) {
    textAreaValidationResult = {
      result: false,
      message: "You have reached your maximum limit of characters allowed",
    };
  }

  return textAreaValidationResult;
};

const isTextareaReqired = (elementData, ElementSetting) => {
  const { required } = ElementSetting;
  let textValidationResult = {};
//console.log(elementData?.textarea?.length)
  if (required && +elementData?.textarea?.length <= 0) {
    textValidationResult = { result: false, message: "Text Filed is required" };
  } else {
    textValidationResult = { result: true, message: "" };
  }
  return textValidationResult;
};

export { isValidTextarea, isTextareaReqired };
