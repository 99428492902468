import React, { useContext, useState } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";

export const FreeTextUi = ({
  elementid,
  PreviewData,
  showHide,
  previewFlag = false,
}) => {
  const [errorMessage, setErrorMessage] = useState({});
  const { board } = useContext(boardConetxt);
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;

  const { description, position,hidden } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const label = board.find((item) => item?.elementid === elementid).name;
  const ElementSettings = board.find((item) => item?.elementid === elementid);
  return (
    <>
      <div
        className={previewFlag && GetStyleClasses(elementid) ||  ""}
        style={initStyle}
        hidden={hidden && previewFlag}
      >
        <div className="mt-2">
          {description ? (
            <h6>{description}</h6>
          ) : ElementSetting ? (
            ElementSettings.name
          ) : (
            <h6>Sample Text</h6>
          )}
        </div>
      </div>
      <ElementsErrorMessage errorMessage={errorMessage} />
    </>
  );
};
