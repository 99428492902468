const isValidTime = (elementData) => {
    let TimeValidationResult = {};
    let Time = elementData.Time;
    let TimeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/;
    if (TimeRegex.test(Time)==true) {
      TimeValidationResult = {
        result: true,
        message: "",
      };
  }
    else if (Time == "") {
      TimeValidationResult = {
        result: false,
        message: "Please Enter Correct Time",
      };
    }
    else {
      TimeValidationResult = {
        result: true,
        message: "",
      };
    }
    return TimeValidationResult;
  };
  
  const isTimeRequired = (elementData, elementSettings) => {
    let TimeValidationResult = {};
    
    if (elementSettings?.required && elementData.Time.length === 0) {
      TimeValidationResult = { result: false, message: "Time is required" };
    } else {
      TimeValidationResult = { result: true, message: "" };
    }
    return TimeValidationResult;
  };
  
  
  export {isValidTime,isTimeRequired};
  