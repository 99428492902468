import { NavLink } from "react-router-dom";
import logo from "../assets/images/LOGO-BLUE.png";


const get_hostname = () => {
  let url = window.location.host
  let hostname = ""
  if (url === "app.sandbox.datalabz.re") {
    hostname = "https://sandbox.datalabz.re/payments/billing/"
  }
  else if (url === "app.test.datalabz.re") {
    hostname = "https://app.test.datalabz.re/payments/billing/"
  }
  else if (url === "app.datalabz.re") {
    hostname = "https://datalabz.re/payments/billing/"
  }
  else{
    hostname = "http://localhost:8000/payments/billing/"
  }
  return hostname;
}

const Header = () => {
  return (
    <>
      <header className="header bg-body border-bottom" id="header" style={{position:"relative"}}>
      <div>
          <NavLink
            to={get_hostname()}
          >
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width={30} alt="" />
              </span>
              <i className="bi bi-chevron-left nav_icon mx-md-2 mx-1"></i>

              <span className="p-0 m-0">Dashboard</span>
            </div>
          </NavLink>
        </div>

        <div className="d-flex align-items-center">
          <NavLink to="/automation-builder/create-workflow">
            <div
              className="d-flex align-items-center btn btn-primary ms-3"
              data-bs-target="#workflowModal"
            >
              <i
                className="iconify me-md-1 nav_icon"
                data-icon="ri:flow-chart"
              ></i>
              <span className="d-md-block d-none">Create New workflow</span>
            </div>
          </NavLink>
        </div>
      </header>
    </>
  );
};

export default Header;
