import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeApiCall } from "../modules/MakeApiRequest";
import { WorkFlowSessionInfo } from "../pages/automation-builder/workflows/EditWorkflow";

const TestFilterAction = ({ action_id, setTestAction, set_test_result, setShowDetails, selected_action, testAgain}) => {
  const { workflow_id } = useParams();
  const {session, setsession, workflow_helper} = useContext(WorkFlowSessionInfo);
  const [testActionStatus, setTestActionStatus] = useState("Hello");
  const [filterResult, setFilterResult] = useState(session[action_id]?.filter_result ? session[action_id].filter_result : true );

  useEffect(() => {
    if(testAgain){
      makeApiCall("POST", "user/test_action/", {"action_id": action_id,
                                                  "workflow_id": workflow_id}).then((data) => {
      setFilterResult(data?.action_output?.filter_result)
      setTestActionStatus(data?.action_status);
      set_test_result(data?.action_status)
      setsession({...session, [action_id]: data})
      workflow_helper.current.update_action_info(action_id)({test_status: data?.action_status})
    }
    );
  }
}, [testAgain]);

useEffect(() => { 
workflow_helper.current.update_action_info(action_id)({test_status: testActionStatus})
}, [testActionStatus]);

  const testActions=()=>{
    let test="Loading...!"
  if(testActionStatus === "FAILED"){
    test= "Test failed!"
  }else if(testActionStatus===undefined){
    test="Test Failed Please Test Again"
 }else if(testActionStatus === "SUCCESS"){
    test= "Test successful!"
  }
  test = testAgain? test : session?.[action_id]?.action_status === "SUCCESS" ? "Test successful!" : "Test failed!"
  return test
}

  return (
    <>
        <>
          <div
            id="test_automation"
            className=" show accordion-collapse collapse border mt-3 rounded"
          >
          <div className="modal-header p-3 pb-0">
            <h6 className="modal-title fs-6">
              Test Details
            </h6>
            {/* <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={()=> setTestAction("test_action")}
            ></button> */}
          </div>
            <div className="p-3">
              <div className={`badge ${testActionStatus === "SUCCESS" || !testAgain ? "bg-success" : testActionStatus=== "FAILED" ? "bg-danger":testActionStatus===undefined ? "bg-danger":"bg-dark"} mb-2 fw-light rounded-pill px-3`}>
                { testActions() }
              </div>
              {/* <p className="small text-secondary">
                This call was found in your account
                learn more about testing
              </p> */}
              <div className="p-3 border bg-color-1 mb-3 rounded-3 text-trigger-box">
                <div className="m-0 ps-2 small border-start border-3 border-primary">
                 { 
                   testActions() === "Loading...!" ? <div>Fetching data...</div> :
                 filterResult === false ? <p>Your Workflow would <b>not</b> have continued for this test data</p> : 
                 <p>Your Workflow would have continued for this test data</p> }
                </div>
              </div>
              {/* <div className={`btn btn-primary ${testActionStatus !== "SUCCESS" && testActionStatus !== "FAILED" ? "disabled" : ""}`}  onClick={()=> setTestAction("test_action_again")}>
                Continue
              </div> */}
            </div>
          </div>
        </>
    </>
  );
}

export default TestFilterAction;
