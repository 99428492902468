import { Elements } from "../../../components/Elements";
import DragableElement from "../../../components/DragableElement";
import { Droppable } from "react-beautiful-dnd";
import { useCallback } from "react";
import { ElementsUIGenrate } from "../../../components/ElementsUIGenrate";

const FormBuilderLeftSideBar = () => {
  const Element = useCallback(({ id }) => {
    const Store = ElementsUIGenrate(id);

    return (
      <div
        className="d-flex p-2 my-2 border rounded align-items-center gap-2"
        id={id}
      >
        <i className="fs-3 iconify" data-icon={Store?.icon} />
        <span>{id}</span>
      </div>
    );
  }, []);

  return (
    <div className="w-100 pb-5 mb-5">
      <div className="d-flex px-2 px-xxl-0 justify-content-between align-items-center border-bottom pb-3">
        <span className="fs-6p">Form elements</span>
      </div>

      <Droppable droppableId="Elements" isDropDisabled={true}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="fm-component-cont fm-component-cont-2 mt-4 row row-cols-1 p-0 m-0"
          >
            {Elements?.map((element, index) => {
              return (
                <DragableElement
                  id={element.id}
                  key={element.id}
                  index={index}
                  element={<Element id={element.id} />}
                />
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default FormBuilderLeftSideBar;
