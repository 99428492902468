const isValidRadio = (elementData, ElementSetting) => {
  //console.log("Data", ElementSetting);
  let RadioBtnValidationResult = {};

  return RadioBtnValidationResult;
};

const isRadioRequired = (elementData, ElementSetting = {}) => {
  let RadioBtnValidationResult = {};
  //console.log("Data", ElementSetting);
  const { required, options } = ElementSetting;
  //console.log("elr", ElementSetting);
  let check = options?.some((item) => item.isCheck == true);
//console.log("RadioButton",ElementSetting)
//console.log("RadioButton",check)
  if (required && !check) {
    RadioBtnValidationResult = {
      result: false,
      message: "Select atleast one option",
    };
  } else {
    RadioBtnValidationResult = { result: true, message: "" };
  }

  return RadioBtnValidationResult;
};

export { isValidRadio, isRadioRequired };
