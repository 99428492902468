import React, { useState, useEffect } from "react";
import TriggersList from "./TriggersList"
import { gql, useQuery } from '@apollo/client';

const GetAvailableTriggers = gql`
  query GetAvailableTriggers {
    GetAvailableTriggers {
      data
      statusCode
    }
  }
`;

const platform_names = {
  "FUB": "Follow Up Boss",
  "SISU": "Sisu",
}
export function PlatformTriggersList({ triggerdetails, setTriggerDetails, details, setdetails, setIsTriggerCreated }) {
  const { loading, error, data, refetch } = useQuery(GetAvailableTriggers, {});
  const [platform, setplatform] = useState("")
  useEffect(() => {
      }
    , [platform])

  if (loading) return '';

  if (error) return `Error! ${error.message}`;
  var availableTriggers = data.GetAvailableTriggers.data[platform]
  var platforms = Object.keys(data?.GetAvailableTriggers.data || {})
 // TriggerDetails.platform is present we show TriggersList and pass setTriggerDetails to it
  return (
    <>
         <div
      className="modal fade right"
      id="triggerModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="triggerModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0">
        <div className="modal-content h-100 rounded-0">
          <div className="modal-header bg-color-1">
         
            <h5 className="modal-title fs-6" id="platformTriggersLable">
              Select Trigger
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setplatform("");
              }}
            ></button>
          </div>
          <div className="modal-body py-4 p-md-4">
          {platform ? (
            <TriggersList setplatform={setplatform} triggerdetails={triggerdetails} platform={platform} setTriggerDetails={setTriggerDetails} availableTriggers={availableTriggers}
            details={details}
            setdetails={setdetails}
            setIsTriggerCreated={setIsTriggerCreated}
             />
          ) :
          (
            <>
                <p>Select Platform you want to Connect</p>
                <div className="my-4 row row-cols-2 gx-4 ">
                {platforms.map((_platform) => (

                    <div key={_platform} className="p-d0 mb-4 w-100"
                    onClick={() => {
                      setplatform(_platform);
                    }}
                    >
                      <h5 className=" border rounded-2 px-3 d-flex justify-content-between align-items-center py-2 pointer">
                        <span>
                           {platform_names[_platform]}
                        </span>
                        <i className="iconify" data-icon="mdi:chevron-right"></i>
                      </h5>
                    </div>
                ))}
                  
                    </div>
                    </>)
          }
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default PlatformTriggersList;
