import React, { useContext } from "react";
import { boardConetxt } from "../../../../pages/form-builder/dashboard/ContextHelper";
import { gql, useMutation } from "@apollo/client";
const UpdateFormFields = gql`
mutation UpdateField($fieldId: String, $fieldData: GraphQLJSON) {
  UpdateField(field_id: $fieldId, field_data: $fieldData) {
    field_id
  }
}`
export function ModalFooter({ ElementSettingData, closeModal, elementid }) {
  const { board, setboard } = useContext(boardConetxt);

const [UpdateField, { data, loading, error }] = useMutation(UpdateFormFields);

  const handelSumbit = (e) => {
    e.preventDefault();
    const newboard = board.map((item) => {
      console.log("item>>", item);
        const obj={...item}
        const name=obj?.id === elementid ? obj?.name : obj?.name
       
      if (obj?.elementid === elementid) {
        console.log("obj>>>>",name,"Main Placeholder",ElementSettingData?.placeHolder,"Conditon",ElementSettingData?.placeHolder == ""  ? name : ElementSettingData?.placeHolder);
        const FieldName=ElementSettingData?.placeHolder == "" ? name : ElementSettingData?.placeHolder;
        obj.settings = ElementSettingData;
        console.log("obj>>>>",FieldName)
        UpdateField({
          variables: {
            fieldId: elementid,
            fieldData: {
              field_name: FieldName,
              input_type: item?.type, 
              settings: ElementSettingData,
            },
          },
        });
      }
      
      return obj;

    });
    // console.log("newboard", baord)
   
   
    //console.log("newboard", newboard)
    setboard(newboard);

    closeModal(e);
  };

  return (
    <div className="modal-footer">
      <button
        type="button"
        onClick={(e) => {
          closeModal(e);
        }}
        className="btn btn-primary-outline"
      >
        Close
      </button>
      <button type="button" onClick={handelSumbit} className="btn btn-primary">
        Save changes
      </button>
    </div>
  );
}
