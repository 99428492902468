import { DndHeadingUi } from "./form-builder-components/BoardElementsUI/DndHeadingUi";
import { DndFreeTextUi } from "./form-builder-components/BoardElementsUI/DndFreeTextUi";
import { DnDEmail } from "./form-builder-components/BoardElementsUI/DnDEmail";
import { DnDContact } from "./form-builder-components/BoardElementsUI/DnDContact";
import { DnDInput } from "./form-builder-components/BoardElementsUI/DnDInput";
import { DndNumberFieldUi } from "./form-builder-components/BoardElementsUI/DndNumberFieldUi";
import { DndTextareUi } from "./form-builder-components/BoardElementsUI/DndTextareUi";
import { DndDropDownUi } from "./form-builder-components/BoardElementsUI/DndDropDownUi";
import { DndRadioBtnUi } from "./form-builder-components/BoardElementsUI/DndRadioBtnUi";
import { DndCheckBoxUi } from "./form-builder-components/BoardElementsUI/DndCheckBoxUi";
import { DndTimeUi } from "./form-builder-components/BoardElementsUI/DndTimeUi";
import { DndDateUi } from "./form-builder-components/BoardElementsUI/DndDateUi";
import { DndStarRatingUi } from "./form-builder-components/BoardElementsUI/DndStarRatingUi";
export const ElementsUIGenrate = (id) => {
  // console.log("id ElementsUIGenrate", id)
  const Ele = {
    // id: {ui: UI, icon: "iconify"}
    Headings: { ui: DndHeadingUi, icon: "gridicons:heading" },
    FreeText: { ui: DndFreeTextUi, icon: "bi:textarea-t" },
    Email: { ui: DnDEmail, icon: "carbon:email" },
    Contact: { ui: DnDContact, icon: "carbon:phone" },
    InputField: { ui: DnDInput, icon: "bi:input-cursor" },
    NumberField: { ui: DndNumberFieldUi, icon: "bi:input-cursor" },
    TextArea: { ui: DndTextareUi, icon: "bi:textarea-resize" },
    DropDown: { ui: DndDropDownUi, icon: "mdi:form-dropdown" },
    RadioButton: { ui: DndRadioBtnUi, icon: "bx:radio-circle-marked" },
    CheckBox: { ui: DndCheckBoxUi, icon: "majesticons:checkbox-list-line" },
    Date: { ui: DndDateUi, icon: "bi:calendar2-date" },
    Time: { ui: DndTimeUi, icon: "carbon:time" },
    Ratings: { ui: DndStarRatingUi, icon: "carbon:star" },
  };
  return Ele[id];
};
