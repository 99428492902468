import React, { useEffect, useState } from "react";
import { get_action_icon } from "../assets/logos";
import Accordion from "react-bootstrap/Accordion";
import { useRef } from "react";

const AccordionReusable = ({ keys, set_insert_value, acccordion_title }) => {
  const [searchValue, setSearchValue] = useState("");
  const [activeKey, setActiveKey] = useState([]);
  const activeKeysList = useRef(activeKey);

  useEffect(() => {
    // Update activeKey when searchValue changes
    if (
      searchValue.trim() === "" ||
      searchValue === "" ||
      searchValue === null ||
      searchValue === " "
    ) {
      setActiveKey([]);
    }
    else{ 
    setActiveKey(activeKeysList.current);
    }

  }, [searchValue]);

  const reversedKeys = Object.entries(keys)
    .reverse()
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

  const handleSearchChange = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  const handleAccordionItemClick = (index) => {
    // Function to manually expand/collapse accordion items
    const newActiveKey = activeKey.includes(index)
      ? activeKey.filter((key) => key !== index)
      : [...activeKey, index];
  
    setActiveKey(newActiveKey);
  };

  function searchList(options, searchValue, action_logo) {
    const filteredOptions = options?.filter((option) => {
      // Check if the key_show is a string before applying string methods
      const keyShowString = option.key_show ? option.key_show.toString() : "";
      const valueString = option.value ? option.value.toString() : "";
      const keyString = option.key ? option.key.toString() : "";

      // Check if the search term is present in either the key or the value
      return (
        keyShowString
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchValue.toLowerCase().split(" ").join("")) ||
        valueString
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchValue.toLowerCase().split(" ").join("")) ||
        keyString
          .toLowerCase()
          .split(" ")
          .join("")
          .includes(searchValue.toLowerCase().split(" ").join(""))
      );
    });

    const renderedOptions = filteredOptions?.map((option, i) => (
      <div
        key={i}
        className="d-flex align-items-center card-g bg-white p-2 border rounded-2 mx-auto m-2"
        onClick={(event) => {
          set_insert_value(option.key);
        }}
      >
        <img className="me-2 p-0 fs-5" height="20" src={action_logo} alt="" />
        <span>
          <b>{option.key_show}</b>{" "}
          {option?.value != null ? option.value.toString() : "null"}
        </span>
      </div>
    ));
    

    return (
      <div>
        {renderedOptions?.length !== 0 ? (
          renderedOptions
        ) : (
          <div>No matches found.</div>
        )}
      </div>
    );
  }

  const createAccordion = (data) => {
    let accordion_list = [];
  let activeKeys = [];
    for (const [index, [key, value]] of Object.entries(data).entries()) {
      let action_logo = get_action_icon(key);
      const foundInSearch = searchList(value, searchValue, action_logo);

      if (foundInSearch && foundInSearch.props.children.length > 0) {
        if (!activeKeys.includes(index.toString())) {
          activeKeys.push(index.toString());
        }
      }
      accordion_list.push(
        <Accordion.Item key={index.toString()} eventKey={index.toString()}>
         <Accordion.Header onClick={() => handleAccordionItemClick(index.toString())}>
           <div className=" card-g bg-white p-1 border rounded-2 m-2">
              <img className="p-0 fs-5" height="20" src={action_logo} alt="" />
            </div>
            <span>
              {" "}
              {index + 1 + "."} {key}{" "}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="accordion-body">
              {Object.values(value).length > 0 && value[0]?.key_show !== "" ? (
                <div>{searchList(value, searchValue, action_logo)}</div>
              ) : (
                <p>
                  Please test the action successfully to get relevant fields!
                </p>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      );
    }
    activeKeysList.current = activeKeys;
    return accordion_list;
  };

  return (
    <div className="f-bd-ac p-3">
      <div className="search-container">
        <input
          type="search"
          className="form-control search-fgy input-bn"
          style={{ height: "50px !important", display: "block" }}
          placeholder="Search"
          onChange={handleSearchChange}
          value={searchValue}
        />
      </div>
      <div className="mt-2 overflow-y-scroll gx-up">
        <div className="pb-3">
          {activeKeysList.current.length === 0 && searchValue !== "" ?  <div className="text-left">No matches found.</div> : null}
        </div>
        <Accordion activeKey={activeKey}>
          {createAccordion(reversedKeys)}
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionReusable;
