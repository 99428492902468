import { React } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/LOGO-BLUE.png";

export function FormEntryDataTable({
  editable,
  formData,
  formEntryData,
  handleInputChange,
}) {
  const navigate = useNavigate();
  return (
    <>
      <header className="header bg-white border-bottom" id="header">
        <div>
          <div className="pointer" onClick={() => navigate("/forms/")}>
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img className="img-fluid" src={logo} width="30" alt="" />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0 text-decoration-underline">
                Form Dashboard
              </span>
            </div>
          </div>
        </div>
      </header>
      <div className="p-4 bg-white border rounded-3 mb-3 mt-3">
        <div className="d-flex flex-column gap-5 mb-3 mt-3">
          {
            // List all the form data fields and map them to the form entry data
            formData?.form_fields?.map((entry, index) => (
              <div className="fs-6" key={index}>
                <div className="text-black-50 fw-bold">
                  { entry?.type === "Headings" ? entry?.settings.title : 
                    entry?.type === "FreeText" ? entry?.settings.description : 
                    entry?.name
                  }
                </div>
                {(entry?.type !== "Headings" && entry?.type !== "FreeText") &&
                <>
                <hr className="hr op-10" />
                <input
                  className="col-5 form-control"
                  id={entry?.elementid}
                  value={
                    formEntryData?.formEntry?.form_entry_data[0][
                      entry?.elementid
                    ]
                  }
                  onChange={(e) => handleInputChange(e)}
                  disabled={editable}
                />
                </>
               }
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
}
