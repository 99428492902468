import React, { useState, useContext, useEffect } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import Validation from "../../../utils/Validation";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useForm, Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const DropDownUi = ({
  elementid,
  message = {},
  showHide,
  oppFields,
  previewFlag = false,
}) => {
  const [copy,setCopy]=useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const { board, setboard } = useContext(boardConetxt);
  const {
    register,
    formState: { errors },control,setValue
  } = useFormContext();
  const Location=useLocation();
  const params=new URLSearchParams(Location.search);
  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])
  
  // useEffect(()=>{
  //   if(params.get(elementid.replace(/-\d+$/, ''))){
  //     setValue(elementid,params.get(elementid.replace(/-\d+$/, '')))
  //   } 
  
  // },[params.get(elementid.replace(/-\d+$/, ''))])
  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])

  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const { label, instructions, options, required, hidden } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  console.log("ElementSetting", ElementSetting);
  // const [value, setValue] = useState("");
  // const handelChange = (e) => {
  //   setValue(e.target.value);
  // };
  // const handelData=()=>{
  //   const newboard = board?.map((product) => {
  //     if (product?.elementid === elementid) {
  //       product?.settings?.options?.map((item)=>{
  //         if(item?.value===value){
  //           item.isCheck=true;
  //         }else{
  //           item.isCheck=false
  //         }
  //         return item;
  //       })
  //     }
  //     return product;
  //   });
  //   setboard(newboard);
  // }

  // useEffect(()=>{
  //   handelData(options);
  //   let elementData={}
  //   let validation = Validation(elementData,ElementSetting);
  //    let { DropDownCheck } = validation.validateDropDown();

  //    handelData()
  //    setErrorMessage(DropDownCheck);
  // },[value,options])

  // useEffect(() => {
  //   if (message[elementid]?.result === false) {
  //     setErrorMessage(message);
  //   }else{

  //   }
  // }, [message]);
  const labelName = board.find((item) => item?.elementid === elementid).name;
  return (
    <div
      className={previewFlag && GetStyleClasses(elementid) ||  ""}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating form-group">
        {/* <select
        className="form-select input-bn"
        placeholder="Please Select"
        value={value}
        onChange={handelChange}
        id={elementid}
        name="dropdown"
      >
        <option  >Please Select</option>
        {options ? (
          options.map((item) => {
          //  //console.log("items",item)
            return (
              <option key={item.id} value={item?.value} >
                {item?.value}
              </option>
            );
          })
        ) : (
          <>
            <option value="option 1">option 1</option>
            <option value="option 2">option 2</option>
          </>
        )}
      </select> */}
      <Controller
        
        name={elementid}
        control={control}
        rules={{
          required: {
            value: required === true ? true : false,
            message: "Please Select an option",
          },
        }}
        render={({ field }) => (
          <select
          id={elementid}
            className="form-select input-bn"
            placeholder="Please Select"
            {...field}
          >
         <option value="" >Choose an option</option>
            {options ? (
              options.map((item) => {
                return (
                  <option key={item.id} value={item?.value}>
                    {item?.value}
                  </option>
                );
              })
            ) : (
              <>
                <option value="option 1">option 1</option>
                <option value="option 2">option 2</option>
              </>
            )}
          </select>
        )}
      />


        {/* <select
          className="form-select input-bn"
          placeholder="Please Select"
          {...register(elementid, {
            required: {
              value: required === undefined ? false : required,
              message: "Please Select an option",
            },
            
          })}
        >
          {options ? (
            options.map((item) => {
              return (
                <option key={item.id} value={item?.value}>
                  {item?.value}
                </option>
              );
            })
          ) : (
            <>
              <option value="option 1">option 1</option>
              <option value="option 2">option 2</option>
            </>
          )}
        </select> */}
        <label>
          <span className="d-flex align-items-center">
            <span>
              {/* {label ? label : labelName}
               */}
               {copy ? `${label}-copy`: label ? label : labelName}
               {/* {copy ? placeHolder == undefined ? label : placeHolder+"-copy": placeHolder ? placeHolder : label} */}

              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      </div>
      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
      {/* placeholder for errors */}
      {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
    </div>
  );
};
