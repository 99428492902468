import { useContext, useState } from "react";
import PropTypes from "prop-types";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { SideElements } from "./BoardElementsSettingsUi/SidebarElement";
import { ElementContext } from "../../../pages/form-builder/dashboard/SettingContext";
import { gql, useQuery } from "@apollo/client";

const GetElementSetting = gql`
query GetFieldById($fieldId: String) {
  GetFieldById(field_id: $fieldId) {
    field_id
    field_name
    field_type
    input_type
    settings
  }
}`

const ElementsSettingsModal = ({ elementid, type }) => {
  const { board } = useContext(boardConetxt);

  const { setSetElement } = useContext(ElementContext);
  const [showModel, setShowmodel] = useState(true);

  const ele = SideElements.find((e) => e.id === type);

  const UI = ele.ui;

  const styles = showModel ? { display: "block" } : { display: "none" };

  const OutsideModel = (e) => {
    if (e.target.id === "option-setting2") {
      setShowmodel(!showModel);
      setSetElement({});
    }
  };
  const CloseModel = (e) => {
    e.stopPropagation();
    setShowmodel(!showModel);
    setSetElement({});
  };

  const { loading, error, data } = useQuery(GetElementSetting, {
    variables: { fieldId: elementid },
  });
 
console.log("Data>>>",data?.GetFieldById) 
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;

  return (
    <>
      <div
        className="modal fade show"
        onClick={OutsideModel}
        id="option-setting2"
        style={styles}
      >
        <div
          style={{ float: "right", width: "100%" }}
          className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0"
        >
          <div className="modal-content h-100">
            <div className="modal-header bg-color-1">
              <h5 className="modal-title fs-6" id="staticBackdropLabel">
                Customise element
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                className="btn-close"
                onClick={(e) => {
                  CloseModel(e);
                }}
              />
            </div>
            {/* Rendring UI */}
            <UI
              elementid={elementid}
              ElementSetting={ElementSetting}
              board={board}
              closeModal={CloseModel}
            />
          </div>
        </div>
      </div>

      {showModel && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

// ElementsSettingsModal.propTypes = {
//   elementid: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired,
//   settings: PropTypes.object.isRequired,
// };

export default ElementsSettingsModal;
