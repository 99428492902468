import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { TextareaUi } from './TextareaUi'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'

export const DndTextareUi = ({elementid,element,oppFields}) => {
  const  {board,setboard}=useContext(boardConetxt);
  return (
    <div className="fm-comp" id={elementid}>
        <ElementsSettingsWidget
        BoardDeleteHandler={BoardDeleteHandler}
        board={board}
        setboard={setboard}
        elementid={elementid}
        element={element}
        />
        <TextareaUi elementid={elementid} oppFields={oppFields} />
    </div>
  )
}
