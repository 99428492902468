const isValidContact = (elementData, ElementSetting) => {
  //console.log("Runn Contat",elementData,ElementSetting)

  const { maxValue, predefine, minValue } = ElementSetting;
  let Max
  let Min
  
  if(maxValue==undefined){
    Max=11
  }else if(maxValue== ""){
    Max=11
  }else{
    Max=maxValue + 1;
  }
  
  if(minValue==undefined){
    Min=9
  }else if(minValue== ""){
    Min=9
  }else{
    Min=minValue-1
  }
console.log("Min",Min,"Max",Max)
  let contactValidationResult = {};
  let contact = elementData?.contact;
 // console.log("Min",Min,"Max",Max,"contact",contact)
// console.log("testing",contactReg.test(contact))
  // let contactReg = /^[0-9\b]+$/;
  // if(contactReg.test(contact)===false){
  //   contactValidationResult = {
  //     result: false,
  //     message: "Contact Number is Invalid",
  //   };
  // }
console.log("contact",+contact?.length)
  if (+contact?.length >= Max) {
    contactValidationResult = {
      result: false,
      message: "Contact Number is too Long",
    };
  } else if (+contact?.length > 0 && +contact?.length <= Min) {
    contactValidationResult = {
      result: false,
      message: "Contact Number is too Short",
    };
  }
  return contactValidationResult;
};
export {  isValidContact };
