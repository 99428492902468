import React, { useState, useRef, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";
import Switch from "react-switch";
import Trigger from "../triggers/Trigger";
import WorkflowHeader from "../../../components/WorkflowHeader";
import Actions from "../automation/Actions";
import { gql, useMutation, useQuery } from "@apollo/client";
import { WorkflowHelper } from "../../../utils/WorkflowHelper";
import cloneDeep from "lodash/cloneDeep";
import { Loaderr } from "../../../components/Loaderr";
import { ErrorPage } from "../../../utils/ErrorPage";
import TypingDots from "../../../assets/images/typing-dots.gif";
const WorkFlowSessionInfo = createContext();

const UpdateWorkflowTrigger = gql`
  mutation UpdateWorkflowTrigger(
    $workflow_id: String!
    $trigger_data: GraphQLJSON!
  ) {
    UpdateWorkflowTrigger(
      workflow_id: $workflow_id
      trigger_data: $trigger_data
    ) {
      statusCode
    }
  }
`;

const UpdateWorkflowActions = gql`
  mutation UpdateWorkflowActions(
    $workflow_id: String!
    $actions_info: GraphQLJSON!
  ) {
    UpdateWorkflowActions(
      workflow_id: $workflow_id
      actions_info: $actions_info
    ) {
      statusCode
    }
  }
`;
const GETWORKFLOW = gql`
  query GetWorkflow($workflow_id: String!) {
    Workflow(workflow_id: $workflow_id) {
      actions {
        action_id
        next_action_ids
        data_mapper
        action_path
        api_name
        api_type
        automation_name
        test_status
        is_default
        platform
        meta_data
      }
      workflow_name
      workflow_description
      trigger_id
      status
    }
  }
`;

const UpdateToggleButton = gql`
  mutation updateworkflowstatus($workflowId: String!, $status: Boolean!) {
    UpdateWorkflowStatus(workflow_id: $workflowId, status: $status) {
      statusCode
    }
  }
`;

const UpdateWorkflowDetails = gql`
  mutation UpdateWorkflowDetails($workflowId: String!, $workflowName: String) {
    UpdateWorkflowDetails(workflow_id: $workflowId, workflow_name: $workflowName) {
      statusCode
      message
      data
    }
  }
`;

const TestSession = gql`
  query TestSession($workflowId: String!) {
    TestSession(workflow_id: $workflowId) {
      statusCode
      message
      data
    }
  }
`;

function EditWorkflow() {
  const { workflow_id } = useParams();
  const { loading, error, data } = useQuery(GETWORKFLOW, {
    variables: { workflow_id: workflow_id },
  });
  const [runWorkflow, setRunWorkflow] = useState(true);
  const [disable, setDisable] = useState(data?.Workflow?.status);
  const [workflowdactions, setWorkflowactions] = useState([{}]);
  const [actionsCount, setActionsCount] = useState(0);
  const workflow_helper = useRef(
    new WorkflowHelper(cloneDeep(workflowdactions), setWorkflowactions)
  );
  const [session, setsession] = useState({});
  const [rendercount, setRenderCount] = useState(0);
  const [triggerdetails, setTriggerDetails] = useState({});
  const inputRef = useRef(null);
  const [workflowName, setWorkflowName] = useState("");
  const [editWorkflowTitle, setEditWorkflowTitle] = useState(true);
  const old_trigger_id = useRef(null);
  const [saveTrigger, setSaveTrigger] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [
    selecttrigger,
    {
      data: triggerselectdata,
      loading: updatingtrigger,
      error: triggerupdateerror,
    },
  ] = useMutation(UpdateWorkflowTrigger);

  const [
    update_actions,
    { data: updatedata, loading: updating, error: updateerror },
  ] = useMutation(UpdateWorkflowActions);

  const [
    update_status,
    { data: updateStatus, loading: updatingStatus, error: updateerrorStatus },
  ] = useMutation(UpdateToggleButton, {
    onCompleted: (data) => {
      data?.UpdateWorkflowStatus?.statusCode !== 200
        ? data?.UpdateWorkflowStatus?.message
          ? alert(data?.UpdateWorkflowStatus?.message)
          : alert("An unkown error occured.")
        : setDisable(!disable);
    },
    onError: (error) => {
      alert("Something went wrong. Please try again.");
    },
  });

  const { data: testSessionData } = useQuery(TestSession, {
    variables: { workflowId: workflow_id },
  });

  const [updateWorkflowDetails] = useMutation(UpdateWorkflowDetails);

  useEffect(() => {
    if (testSessionData?.TestSession?.statusCode === 200) {
      setsession(testSessionData?.TestSession?.data);
    }
  }, [testSessionData]);

  useEffect(() => {
    if (
      triggerdetails.data_mapper?.trigger_id &&
      old_trigger_id.current !== triggerdetails.data_mapper?.trigger_id
    ) {
      selecttrigger({
        variables: {
          workflow_id: workflow_id,
          trigger_data: triggerdetails,
        },
      });

      old_trigger_id.current = triggerdetails.data_mapper?.trigger_id;
    }
  }, [triggerdetails, triggerdetails.data_mapper]);

  useEffect(() => {
    if (data?.Workflow?.actions) {
      setWorkflowactions(data?.Workflow?.actions);
    }
    if (data?.Workflow?.actions.length > 0) {
      setTriggerDetails(
        data?.Workflow?.actions.find((action) => action.action_id === "trigger")
      );
      old_trigger_id.current = data?.Workflow?.actions.find(
        (action) => action.action_id === "trigger"
      ).trigger_id;
    }
  }, [data?.Workflow?.actions]);

  useEffect(() => {
    if ((disable === undefined || disable === null) && data?.Workflow) {
          setDisable(data?.Workflow?.status);
    }
  }, [disable, data]);

  useEffect(() => {
    const sessionKeysArray = Object.keys(session);
    const actionsArray = workflow_helper.current.get_actions_by_order();
  
    const areAllActionsTested = () => {
      if (session) {
        const sessionKeysInActionsArray = sessionKeysArray.filter(key =>
          actionsArray.some(action => action.action_id === key)
        );
        if (sessionKeysInActionsArray.length > 0) {
          return sessionKeysInActionsArray.every(
            key => session[key]?.action_status?.toLowerCase() === 'success'
          );
        }
      }
      return false;
    };
  
    const allActionIdsPresent = actionsArray.every(action =>
      sessionKeysArray.includes(action.action_id)
    );
  
    if (allActionIdsPresent) {
      setRunWorkflow(areAllActionsTested());
    } else {
      setRunWorkflow(false);
    }
  }, [session, actionsCount]);

  useEffect(() => {
    if (!runWorkflow) {
      update_status({
        variables: {
          workflowId: workflow_id,
          status: false,
        },
      });
    }
  }, [runWorkflow]);
  

  useEffect(() => {
    workflow_helper.current = new WorkflowHelper(
      cloneDeep(workflowdactions),
      setWorkflowactions
    );
    update_actions({
      variables: {
        workflow_id: workflow_id,
        actions_info: workflow_helper.current.get_workflow(),
      },
    });
    setRenderCount(rendercount + 1);
  }, [workflowdactions, saveTrigger]);

  useEffect(() => {
    setWorkflowName(data?.Workflow.workflow_name);
  }, [data?.Workflow?.workflow_name]);

  if (updateerror !== undefined) return <ErrorPage updateerror={updateerror} />;
  if (loading) return <Loaderr />;

  var workflowDetails = data?.Workflow;

  const workflowNameHandler = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setWorkflowName(value);
  };

  const editWorkflowName = () => {
    inputRef.current.focus();
    if(!editWorkflowTitle){
    updateWorkflowDetails({
      variables: {
        workflowId: workflow_id,
        workflowName: workflowName,
      },
    })
  };
    setEditWorkflowTitle(!editWorkflowTitle);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSave = () => {
    workflow_helper.current = new WorkflowHelper(
      cloneDeep(workflowdactions),
      setWorkflowactions
    );
    update_actions({
      variables: {
        workflow_id: workflow_id,
        actions_info: workflow_helper.current.get_workflow(),
      },
    });
  };
  const handelModal = (event) => {
    event.preventDefault();

    if (event.target === event.currentTarget) {
      setIsModalOpen(!isModalOpen);
    }
  };

  return (
    <>
      <WorkflowHeader
        updatedata={updatedata}
        updating={updating}
        updateerror={updateerror}
        handleSave={handleSave}
      />
      <div
        className="main container mx-auto"
        onClick={(event) => {
          handelModal(event);
        }}
        id="body-pd"
      >
        <section className="col-xl-7 mx-auto">
          <div
            className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
            role="alert"
          >
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex align-items-center my-2">
                <i className="bx bx-mobile fs-5" />
                <i className="bx bx-arrow-to-right me-2 fs-5" />
                <i className="bx bx-desktop me-2 fs-5" />
              </div>
              <span className="my-2">
                Please switch to desktop and optimize this view. Smaller screens
                are not efficient. To learn more click here
              </span>
            </div>
          </div>
          {workflowName && (
            <div className="d-flex justify-content-between">
              <div className="py-4">
                <form
                  action=""
                  className="d-flex align-items-center"
                  onSubmit={handleSubmit}
                >
                  <input
                    ref={inputRef}
                    id="workflow_title"
                    placeholder="Workspace title "
                    className={
                      editWorkflowTitle
                        ? "workflow-title text-dark"
                        : "workflow-title border-bottom"
                    }
                    type="text"
                    name="name"
                    value={workflowName}
                    onChange={workflowNameHandler}
                    disabled={editWorkflowTitle}
                    style={{ width: `${workflowName.length + 2}ch` }}
                  />
                  <span onClick={editWorkflowName}>
                    <i
                      className={
                        editWorkflowTitle
                          ? "fs-4 ms-2 d-flex bx bxs-edit"
                          : "fs-4 ms-2 d-flex bx bx-check"
                      }
                    />
                  </span>
                </form>
                <p className="text-secondary mt-2">
                  {workflowDetails.workflow_description}
                </p>
              </div>
              <div className="py-4">
                
              { updatingStatus ? (
                 <div className="react-loader">
                 <i className="bx bx-loader-circle fs-3"></i>
               </div>
                   ) : ( 
                    <div className="react-switch">
                    <Switch
                      checked={disable && runWorkflow && actionsCount > 1}
                      width={50}
                      className="switch-rect"
                      height={27}
                      offstyle="primary"
                      disabled={disable && !runWorkflow && actionsCount < 2}
                      onChange={() => {
                        if(runWorkflow && actionsCount > 1) {
                          update_status({
                            variables: {
                              workflowId: workflow_id,
                              status: !disable,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                )}
                {actionsCount < 2 ? (
                      <p className="switch-status text-danger">
                      Please add an action to turn on the workflow.
                    </p>
                  ) : (
                    disable && runWorkflow ? (
                      <p className="switch-status">
                        Workflow is turned on.
                      </p>
                    ) : (
                      runWorkflow ? (
                        <p className="switch-status text-success">
                          All actions are tested successfully. You can turn on the workflow.
                        </p>
                      ) : (
                      <p className="switch-status text-danger">
                        You have to test all actions successfully to turn on the workflow.
                      </p>
                      )
                    )
                  )}
              </div>
            </div>
          )}
          <WorkFlowSessionInfo.Provider
            value={{
              session,
              isModalOpen,
              setIsModalOpen,
              setsession,
              workflow_helper,
            }}
          >
            {data?.Workflow?.actions &&
            triggerdetails.data_mapper?.trigger_id ? (
              <Actions
                workflow_helper={workflow_helper}
                triggerdetails={triggerdetails}
                setTriggerDetails={setTriggerDetails}
                setActionsCount={setActionsCount}
              />
            ) : (
              <Trigger
                triggerdetails={triggerdetails}
                setTriggerDetails={setTriggerDetails}
              />
            )}
          </WorkFlowSessionInfo.Provider>
        </section>
      </div>
    </>
  );
}

export default EditWorkflow;
export { WorkFlowSessionInfo };
