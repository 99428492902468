import React, { useState } from "react";
import Generic from "../../../actions/Generic";
import FilterBlocks from "../workflows/FilterBlocks";
import Trigger from "../triggers/Trigger";
import { Automations } from "../../../actions/automations";
import FilterOld from "../workflows/FilterOld";
import SelectAutomation from "./SelectAutomation";
import Fub from "../../../actions/Fub";
import AdvancedFilterBlock from "../workflows/AdvancedFilterBlock";


// [
//   {"action_id": "trigger", "next_action_d"},
//   {"automation_name": "Set Tag"}, # Array Field, tags
//   {"automation_name": "update Custom Fields"}, # custom_fields,
//   {"automation_name": "update Person"}, #
//   {"automation_name": "Apply Action Plan"}, #
//   {"automation_name": "Update Deal"}, #
//   {"automation_name": "Create Deal"}, #
// ]

const Action = ({
  action_index,
  action_info,
  triggerdetails,
  setTriggerDetails,
  action_id,
  workflowHelper,
  selected_action,
  setSelected_action
}) => {
  const showAction = () => {
    let action_platform = action_id === "trigger"? "trigger": action_info.platform?.toLowerCase() || action_id.toLowerCase().split("#")[0];
    var action = <></>;
    if (action_platform === "trigger") {
      action = (
        <Trigger
          action_index={action_index}
          triggerdetails={triggerdetails}
          setTriggerDetails={setTriggerDetails}
          removeAction={workflowHelper}
          selected_action={selected_action}
          setSelected_action={setSelected_action}
        />
      );
    } else if (action_platform.includes("fub")) {
      action = (
        <Fub
          action_index={action_index}
          UpdateAction={workflowHelper.update_action_info(action_id)}
          action_info={action_info}
          workflowHelper={workflowHelper}
          action_id={action_id}
          // Automations={Automations["FUB"]}
          selected_action={selected_action}
          setSelected_action={setSelected_action}
          triggerdetails={triggerdetails}
        />
      );
    } else if (action_platform === "filter") {
      action = (
        <AdvancedFilterBlock
          action_index={action_index}
          action_info={action_info}
          UpdateAction={workflowHelper.update_action_info(action_id)}
          data_mapper={action_info.data_mapper}
          workflowHelper={workflowHelper}
          selected_action={selected_action}
          setSelected_action={setSelected_action}
          action_id={action_id}
          triggerdetails={triggerdetails}
          setTriggerDetails={setTriggerDetails}
        />
      );
    }else if(action_platform === "unknown")  {
      action=(
        <SelectAutomation
        key={"select_automation"}
        action_id={action_id}
        action_platform={action_platform}
        setSelected_action={setSelected_action}
        
        />
      )
    }

    
    return action;
  };

  return <>{showAction()}</>;
};

export default Action;
