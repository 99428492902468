import React, { useContext, useEffect, useRef, useState } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import Validation from "../../../utils/Validation";
import { isValidEmail } from "../../../utils/ElementValidation/ValidEmail";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useForm, Controller, useFormContext } from "react-hook-form";
import {
  boardConetxt,
  ErrorResult,
  conditionCheckContext,
} from "../../../pages/form-builder/dashboard/ContextHelper";
import { useLocation } from "react-router-dom";

export function EmailUi({
  elementid,
  message = {},
  settings,
  PreviewData,
  showHide,
  oppFields,
  setElementVisiblity,
  previewFlag = false,
}) {
  // const [errorMessage, setErrorMessage] = useState({});
  // ////console.log("email",useFormContext())
  const { board, setboard } = useContext(boardConetxt);
  // const {} = useFormContext();
  const {register,formState:{errors},control,setValue}=useFormContext({
    mode: "onFocus",
    reValidateMode: "onChange",
  });
  const [copy,setCopy]=useState(false);

  const Location=useLocation();
  const params=new URLSearchParams(Location.search);

  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])
  

  // useEffect(()=>{
  //   if(params.get(elementid.replace(/-\d+$/, ''))){
  //     setValue(elementid,params.get(elementid.replace(/-\d+$/, '')))
  //   } 
  
  // },[params.get(elementid.replace(/-\d+$/, ''))])
  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const {
    placeHolder,
    inputType,
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden
  } = ElementSetting;
  // const { hideUnhide } = useHandleCondition();
  // ////console.log("email", showHide)
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  const label = board.find((item) => item?.elementid === elementid).name;
  // const settingValuses=()=>{
  //   const newboard = board.map((product) => {
  //     if (product.elementid === elementid) {
  //       product.settings.predefine = getValues(elementid);
  //     }
  //     return product;
  //   });
  //   setboard(newboard);
  // }
  // useEffect(()=>{
  //   settingValuses()
  // },[watch(elementid)])
  // ////console.log("email",board)
  // const emailRef = useRef(null);

  // const emailValidationResult = (e) => {
  //   e.preventDefault();
  //   let elementData = {};
  //   elementData.email = emailRef.current?.value;
  //   let validation = Validation(elementData, ElementSetting);
  //   let { emailChecks } = validation.validateEmail();
  //   setErrorMessage(emailChecks);

  //   const newboard = board.map((product) => {
  //     if (product.elementid === elementid) {
  //       product.settings.predefine = emailRef.current?.value;
  //     }
  //     return product;
  //   });

  //   // setboard(newboard);
  // };
  // useEffect(() => {
  //   let elementData = {};
  //   elementData.email = predefine ? predefine : emailRef.current?.value;
  //   let validation = Validation(elementData, ElementSetting);
  //   let { emailChecks } = validation.validateEmail();
  //   setErrorMessage(emailChecks);
  // }, [predefine]);

  // useEffect(() => {
  //   if (message[elementid]?.result === false) {
  //     setErrorMessage(message);
  //   }
  // }, [message]);
  useEffect(()=>{
    setValue(elementid,predefine)
  },[predefine])

  return (
    <div
      className={previewFlag && GetStyleClasses(elementid) ||  ""}
      id={elementid}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating w-100 form-group">
        {/* <input
          placeholder="Email Address"
          className="form-control input-bn"
          type={inputType ? inputType : "email"}
          maxLength={maxValue ? maxValue : 31}
          minLength={minValue ? minValue : 0}
          name="email"
          // required
          defaultValue={predefine !== "" ? predefine : emailRef.current?.value}
          id={elementid}
          ref={emailRef}
          onChange={(e) => {
            emailValidationResult(e);
            hideUnhide(setElementVisiblity, e);
          }}
        /> */}
       {/* {
        ElementSetting ? ( <input
          type="email"
          className="form-control input-bn"
          name="email"
          placeholder="Enter Email"
          {...register(elementid, {
           
        
            
            maxLength: {
              value: 30,
              message: `Please Enter less then 30 Characters`,
            },

            pattern: {
              value:  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid Email Address",
            },
          })}
        />):( <input
          type={inputType ? inputType : "email"}
          className="form-control input-bn"
          name="email"
          defaultValue={predefine === undefined ? "" : predefine}
          placeholder="Enter Email"
          {...register(elementid, {
            required: {
              value: required === undefined ? false : required,
              message: "This field is required",
            },
            minLength: {
              value: minValue ===  "" ? 0 : minValue,
              message: `Please Enter More then ${
                minValue === "" ? 0 : minValue
              } Characters`,
            },
            
            maxLength: {
              value: maxValue == "" ? 30 : maxValue,
              message: `Please Enter less then ${maxValue == "" ? 30 : maxValue} Characters`,
            },

            pattern: {
              value:  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid Email Address",
            },
          })}
        />)
       } */}
       {Object.keys(ElementSetting).length === 0 ?(
        <Controller 
        control={control}
        name={elementid}
        rules={
          {
            maxLength: {
              value: 50,
              message: `Please Enter less then  30 maxValue Characters`,
            },
            pattern: {
              value:  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid Email Address",
            },


        }
      }
        render={({field})=>(
          <input
          type="email"
          className="form-control input-bn"
          name="email"
          placeholder="Enter Email"
          {...field}
         
        />
        )}
        />
       ):(
        <Controller 
        control={control}
        name={elementid}
        rules={
          {
            required: {
              value: required === true ? true : false,
              message: "This field is required",
            },
            minLength: {
              value: minValue ===  "" ? 0 : minValue,
              message: `Please Enter More then ${
                minValue === "" ? 0 : minValue
              } Characters`,
            },
            maxLength: {
              value: maxValue == "" ? 50 : maxValue,
              message: `Please Enter less then ${maxValue == "" ? 50 : maxValue} Characters`,
            },
            pattern: {
              value:  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid Email Address",
            },


        }
      }
        render={({field})=>(
          <input
          type={inputType ? inputType : "email"}
          className="form-control input-bn"
          name="email"
          defaultValue={predefine === undefined ? "" : predefine}
          placeholder="Enter Email"
          {...field}
         
        />
        )}
        />
       )}
       
        <label>
          <span className="d-flex align-items-center">
            <i className="iconify me-1 mr-1 fs-5" data-icon="carbon:email" />
            <span>
              {/* {placeHolder ? placeHolder : label} */}
              {copy ? placeHolder == undefined ? label : placeHolder+"-copy": placeHolder ? placeHolder : label}

              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      </div>
      <small className="text-secondary">
        {instructions ? instructions : ""}
      </small>
      {/* placeholder for errors */}
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>

      {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
    </div>
  );
}
