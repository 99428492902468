import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";

const WorkflowRunStats = gql`
  query WorkflowRunStats($fubPersonId: ID, $offset: Int, $limit: Int) {
    workflowRunStats(
      fub_person_id: $fubPersonId
      offset: $offset
      limit: $limit
    ) {
      offset
      stats {
        session_id
        team_id
        workflow_id
        trigger_uuid
        fub_person_id
        workflow_status
        created_at
        last_updated
      }
    }
  }
`;

const WorkflowHistory = (props) => {
  const [workflowStats, setWorkflowStats] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const { loading, error, data, fetchMore } = useQuery(WorkflowRunStats, {
    variables: {
      offset: currentPage * postsPerPage - postsPerPage,
      limit: postsPerPage,
    },
  });

  useEffect(() => {
    let stats = data?.workflowRunStats?.stats;
    setWorkflowStats(stats);
  }, [data?.workflowRunStats?.stats]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  let count = data?.workflowRunStats?.stats?.length;
  for (let i = 1; i <= Math.ceil(count / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePageNumbers = async (number) => {
    paginate(number);
    fetchMore({
      variables: {
        offset: String(currentPage * postsPerPage - postsPerPage),
      },
    });
  };

  const filteredEntries = workflowStats?.filter((entry) => {
    const formattedDate = new Date(entry?.created_at).toISOString().split('T')[0];
    const startDateFilter = !startDate || formattedDate >= new Date(startDate).toISOString().split('T')[0];
    const endDateFilter = !endDate || formattedDate <= new Date(endDate).toISOString().split('T')[0];
  
    const workflowIdMatches = entry?.workflow_id
      ?.toString()
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return startDateFilter && endDateFilter && ( workflowIdMatches );
  });

  return (
    <div>
      <header className="header bg-white border-bottom" id="header">
        <div>
          <a href="https://interface.re/">
            <div className="d-flex align-items-center ms-2">
              <span className="navbar-brand m-0 p-0">
                <img
                  className="img-fluid"
                  src="assets/images/LOGO-BLUE.png"
                  width="30"
                  alt=""
                />
              </span>
              <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
              <span className="p-0 m-0">Go back</span>
            </div>
          </a>
        </div>
      </header>

      <div className="main fmc-main px-0">
        <section className="container mt-5">
          <section className="bg-white p-3 rounded-3">
            <p>Filter</p>

            <div className="p-0 row row-cols-4">
              <div className=" mb-3">
                <input
                  placeholder="Search workflow"
                  className="form-control input-bn"
                  required=""
                  type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <select className="form-select input-bn" required="">
                  <option>Status</option>
                  <option>Active</option>
                  <option>failed</option>
                </select>
              </div>

              <div className="mb-3">
                <div className="form-floating w-100 form-group">
                  <input
                    className="form-control input-bn"
                    required=""
                    type="date"
                    value={startDate}
                    onChange={(e)=>setStartDate(e.target.value)}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:calendar-day"
                      ></i>
                      <span>Starting</span>
                    </span>
                  </label>
                </div>
              </div>

              <div className="mb-3 ">
                <div className="form-floating w-100 form-group">
                  <input
                    className="form-control input-bn"
                    required=""
                    type="date"
                    value={endDate}
                    onChange={(e)=>setEndDate(e.target.value)}
                  />
                  <label>
                    <span className="d-flex align-items-center">
                      <i
                        className="iconify me-1 mr-1 fs-5"
                        data-icon="mdi:calendar-day"
                      ></i>
                      <span>ending</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </section>

          <section className="d-flex flex-column gap-3">
            <div className="main fmc-main px-0">
              <div className="d-md-flex flex-wrap border d-none fm-list-container-thead">
                <div className="d-flex flex-wrap col-12 col-md-3 align-items-center justify-content-center pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
                  <b>ID</b>
                </div>
                <div className="d-flex align-items-center justify-content-center px-4 col col-md-4 border-0 border-end border">
                  <b>Workflow</b>
                </div>
                <div className="d-flex align-items-center justify-content-center px-4 col col-md-1 border-0 border-end border">
                  <b>Status</b>
                </div>
                <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                  <b>Date</b>
                </div>
                <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                  <b>Time</b>
                </div>
              </div>

              {/* <section className="d-flex flex-column py-4 gap-3"> */}
              {filteredEntries?.map((workflow) => (
                <div className="d-md-flex flex-wrap border d-none bg-white">
                  <div className="d-flex flex-wrap col-12 col-md-3 align-items-center justify-content-between pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
                    <p className="p-0 m-0">{workflow?.workflow_id}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-4 border-0 border-end border">
                    <p className="p-0 m-0">not available</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-1 border-0 border-end border">
                    <span>
                      {workflow?.workflow_status === "failed" ? (
                        <i className=" bx bxs-x-circle fs-5 text-danger "></i>
                      ) : (
                        <i className=" bx bxs-check-circle fs-5 text-success "></i>
                      )}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                    {new Date(workflow?.created_at).toLocaleDateString()}
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                    {new Date(workflow?.created_at).toLocaleTimeString()}
                  </div>
                </div>
              ))}
              {/* </section> */}
            </div>
          </section>
          <div className="d-flex justify-content-center m-4">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination pagination-mb">
                      {pageNumbers.map((number) => (
                        <li key={number} className="page-item">
                          <div
                            onClick={() => handlePageNumbers(number)}
                            className="page-link px-3 text-dark"
                          >
                            {number}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
        </section>
      </div>
    </div>
  );
};

export default WorkflowHistory;
