import { useState } from "react";
import PropTypes from "prop-types";
import { ModalFooter } from "./ModalFooter";

const HeadingsSettings = ({ elementid, closeModal, ElementSetting }) => {
  //console.log("element", element);
  const initialvalue = {
    title: "",
    size: "left",
    inputType: "text",
    required: false,
    // duplicate: true,
    minValue: "",
    maxValue: "",
    instructions: "",
  };

  const [Headings, setHeadings] = useState(
    Object.keys(ElementSetting).length !== 0 ? ElementSetting : initialvalue
  );

  const handelChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setHeadings({ ...Headings, [name]: value });
  };

  return (
    <>
      <div className="modal-body py-4 px-lg-5 p-md-4">
        <h5 className="mb-4">
          <i
            className="iconify fs-5 pointer me-1"
            data-icon="tabler:checkbox"
          />
          Field Settings
        </h5>

        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Form Title</span>
            </span>
          </label>
          <input
            placeholder="Placeholder for your Form Title"
            defaultValue={Headings.title || ""}
            name="title"
            onBlur={handelChange}
            className="form-control input-bn"
            type="text"
            maxLength="20"
            minLength="20"
          />
          {/* placeholder for errors */}
        </div>

        <hr className="hr my-5" />
        <h5 className="mb-4">
          <i className="iconify fs-5 pointer me-1" data-icon="bx:slider" />
          General Settings
        </h5>
        {/* dropdowns----------- */}
        <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Size</span>
                {/* <div>
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="icon-park-outline:to-left"
                  />
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="entypo:align-horizontal-middle"
                  />
                  <i
                    className="iconify fs-6 text-secondary ms-1"
                    data-icon="icon-park-outline:to-right"
                  />
                </div> */}
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={Headings.size || ""}
              name="size"
              onChange={handelChange}
            >
            <option value="large">Left</option>
            {/* <option value="medium">Center</option>
            <option value="small">Right</option> */}
            </select>
          </div>
        </div>
        {/* dropdowns----------- */}
        {/* <div className="mt-4">
          <div className="mb-4">
            <label className="w-100">
              <span className=" mb-2 d-flex justify-content-between align-items-center">
                <span className="me-2">Input type</span>
              </span>
            </label>
            <select
              className="form-select input-bn"
              value={Headings.inputType || ""}
              name="inputType"
              onChange={handelChange}
            >
              <option value="Text">Text</option>
              <option value="Password">Password</option>
              <option value="Numbers">Numbers</option>
            </select>
          </div>
        </div> */}
            {/* <div className="me-3">
        <div className="mb-4">
          <span>Options</span>
          <div className="mt-2 d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Headings.required || ""}
                onChange={handelChange}
                name="required"
                id="check-1"
              />
              <label className="form-check-label ms-1" htmlFor="check-1">
                Required
              </label>
            </div> */}
            {/* <div className="me-3 ">
              <input
                className="form-check-input"
                type="checkbox"
                checked={Headings.duplicate || ""}
                onChange={handelChange}
                name="duplicate"
                id="check-2"
              />
              <label className="form-check-label ms-1" htmlFor="check-2">
                No Duplicate
              </label>
          </div>
          </div>
            </div> */}
        {/*
        <div className="mb-4">
          <label>
            <span className="d-flex mb-2 align-items-center">
              <span>Range</span>
            </span>
          </label>
          <div className=" d-flex">
            <div className="col-6 pe-1">
              <input
                placeholder="min value"
                defaultValue={Headings.maxValue || ""}
                onBlur={handelChange}
                name="maxValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
            <div className="col-6 ps-1">
              <input
                placeholder="max value"
                defaultValue={Headings.minValue || ""}
                onBlur={handelChange}
                name="minValue"
                className="form-control input-bn col-5"
                type="number"
              />
            </div>
          </div>
          
        </div> */}
        {/* text-area--------- */}
        {/* <div className="mb-4">
          <div className="form-floating w-100 form-group">
            <textarea
              style={{ minHeight: "100px !important" }}
              defaultValue={Headings.instructions || ""}
              name="instructions"
              onBlur={handelChange}
              placeholder="Instructions for Users (max 100 char)"
              className="form-control input-bn"
              type="text"
              maxLength="100"
            />
            <label>
              <span className="d-flex align-items-center">
                <i className="iconify me-1 mr-1 fs-5" data-icon="mdi:text" />
                <span>Instructions for Users (max 100 char)</span>
              </span>
            </label>
          </div>
          
        </div> */}
      </div>
      <ModalFooter
        ElementSettingData={Headings}
        closeModal={closeModal}
        elementid={elementid}
      />
    </>
  );
};

HeadingsSettings.propTypes = {};

export default HeadingsSettings;
