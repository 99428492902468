import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import { useLazyQuery, gql } from "@apollo/client";
import {Loaderr} from "../../../components/Loaderr";

const FormData = gql`
  query FormData($formId: String) {
    formData(form_id: $formId) {
      form_id
      form_name
      form_description
      form_fields
      form_conditions
      status
      form_created_date
      form_updated_date
      update_by
    }
  }
`;
function ConditionsAll({ condition, setCondition, formData, refetch }) {
  const navigate = useNavigate();
  const params = useParams();
  const { UpdateForm } = useFormApiHelper();
  const [inferCondition, setInferCondition] = useState(condition);
  const [
    loadFormData,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useLazyQuery(FormData, {
    variables: { formId: params.id },
    onCompleted: (data) => {
      console.log("updateData", data);
      //updateForm formCondition
      // updateForm({
      //   variables: {
      //     formId: params.id,
      //     formData: {
      //       ...data.formData,
            
      //       form_conditions: inferCondition,
      //     },
      //   },
      // });
      // refetch();
    },
  });
  console.log("condition>>", inferCondition);
  const handelEdit = (event, index) => {
    event.stopPropagation();
    navigate(`/forms/conditions/${params.id}/create-edit/${index}`, {
      state: { index },
    });
  };

  const { updateForm, updateFormLoading, updateFormError, updateFormData } =
    UpdateForm();

  const handelDelete = (event, index) => {
    console.log("index", index);
    setInferCondition((prev) => {
      let newCondition = [...prev];
      newCondition.splice(index, 1);
      return newCondition;
    });
  };
  useEffect(() => {
    loadFormData();
  }, [inferCondition]);
  
  if (updateLoading) return <Loaderr />;

  return inferCondition?.map((item, index) => (
    <div
      className="bg-white d-flex flex-column flex-wrap rounded-3 border position-relative mb-4"
      key={index}
    >
      <div className="d-flex w-100 flex-wrap rounded-top justify-content-between align-items-center border-bottom bg-color-1 p-4 py-3">
        <span className="m-0">{item?.ConditionName}</span>
        <div className="d-flex align-items-center">
          <div className="pointer" onClick={(e) => handelEdit(e, index)}>
            <i
              className="iconify me-md-1 nav_icon"
              data-icon="mdi:pencil"
              id={index}
            />
          </div>
          <div onClick={(e) => handelDelete(e, index)}>
            <i
              className="iconify me-md-1 nav_icon pointer text-danger ms-2"
              data-icon="mdi:trash"
            />
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="d-flex flex-wrap align-items-center">
          <b>IF</b>
          {item?.condtions?.map((condtion, index) => (
            <div key={index}>
              {condtion?.map((cond, index) => (
                <div
                  className="border dot-border dt-cont p-2 mx-2 rounded"
                  id={index}
                  key={index}
                >
                  {formData?.formData?.form_fields?.find(
                    (obj) => cond.field_id === obj.elementid
                  )?.settings?.placeHolder ||
                  formData?.formData?.form_fields?.find(
                    (obj) => cond.field_id === obj.elementid
                  )?.settings?.label ||
                    formData?.formData?.form_fields?.find(
                      (obj) => cond.field_id === obj.elementid
                    )?.name ||
                    ""}
                  {/* {cond.field_id} */}
                </div>
              ))}
              {index < item.condtions.length - 1 ? (
                <span id={index}>Or</span>
              ) : (
                <></>
              )}
            </div>
          ))}
          {/* <b>{item.and_or}</b>
          <div className="border dot-border dt-cont p-2 mx-2 rounded">
            User is a follow up boss subscriber with an active plan
          </div> */}
        </div>
        <hr className="hr op-10 my-4" />
        <div className="d-flex flex-wrap align-items-center">
          <b>THEN</b>
          <b className="text-primary ms-3">{item?.Visibility}</b>
          {item?.Fields?.map((field, index) => (
            <div
              className="border dot-border dt-cont p-2 mx-2 rounded"
              key={index}
            >
              {formData?.formData?.form_fields?.find(
                (obj) => field === obj.elementid
              )?.settings?.placeHolder ||
              formData?.formData?.form_fields?.find(
                (obj) => field === obj.elementid
              )?.settings?.label ||
                formData?.formData?.form_fields?.find(
                  (obj) => field === obj.elementid
                )?.name ||
                ""}
              {/* {field} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  ));
}

export default ConditionsAll;
