import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/LOGO-BLUE.png";

const WorkflowHeader = ({updatedata,updating,updateerror,handleSave}) => {
  const navigate = useNavigate();

  let disbale="disabled"
  let ErrorMessage
  if(updateerror!==undefined){
    ErrorMessage = <span style={{fontSize:"11px"}}>Error</span>
  }else if(updating===true){
    ErrorMessage = <span className="text-secondary d-flex align-items-center" style={{fontSize:"11px"}}> <i className="bx bx-loader-circle fs-4"></i> <span className="d-none d-lg-block">Saving</span></span>
  }else if(updatedata!==undefined){
    disbale=null
    ErrorMessage = <span className="d-flex align-items-center" style={{fontSize:"11px" ,color:"green"}}><i className="bx bx-check-double fs-4"></i> <span className="d-none d-lg-block">Workflow Saved</span></span>
  }

  return (
    <>
    <header className="header bg-body border-bottom"  id="header">

      <div className="pointer" onClick={() => navigate("/automation-builder/")}>
        <div className="d-flex align-items-center ms-2">
          <span className="navbar-brand m-0 p-0">
            <img className="img-fluid" src={logo} width="30" alt="" />
          </span>
          <i className="bx bx-chevron-left nav_icon mx-md-2 mx-1"></i>
          <span className="p-0 m-0 text-decoration-underline">Go-back</span>
        </div>
      </div>

      <div>
        <div className="d-flex align-items-center">

          <div className="mx-2">{ErrorMessage}</div>

          <div className={`d-flex align-items-center btn btn-primary btn-${disbale}`} >
            <i
              className="iconify me-md-1 nav_icon"
              data-icon="material-symbols:save-rounded"
              ></i>
            <div className="d-md-block d-none" onClick={()=>{handleSave()}}>Save workflow</div>
          </div>

        </div>
      </div>

    </header>
     
        
     
    </>
  );
};

export default WorkflowHeader;
