import React, { useContext, useEffect, useState, useRef } from "react";
import { boardConetxt } from "../dashboard/ContextHelper";
import logo from "../../../assets/images/FULL-LOGO-BLUE.png";
import { BoardElemForPre } from "./BoardElemForPre";
import { useLocation } from "react-router-dom";
import { conditionContext } from "../dashboard/ContextHelper";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { get_fields_to_show } from "../../../utils/conditions_parser";
import { useParams } from "react-router-dom";
import { Loaderr } from "../../../components/Loaderr";
import useFormApiHelper from "../../../utils/useFormApiHelper";

function Preview() {
  const { board, setboard } = useContext(boardConetxt);
  const { condition, setCondition } = useContext(conditionContext);
  const [OppDatas, setOppData] = useState([]);
  const [data, setData] = React.useState({});

  const [error, setError] = React.useState([]);
  const [elementVisiblity, setElementVisiblity] = useState({});
  const [fieldsToShow, setFieldsToShow] = useState([]);
  const [initElementVisiblity, setInitElementVisiblity] = useState({});

  const useWatchdata = useWatch();
  const methods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { watch } = methods;
  const FormValues = useRef(watch());
  const params = useParams();
  const Location = useLocation();
  const getURLParams = new URLSearchParams(Location.search);

  const { CreateFormEntry, GetFormData, GetOppData, CreateOpp, UpdateOpp } =
    useFormApiHelper();
  const previewFlag = true;
  const {
    handleSubmit,
    getValues,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    FormValues.current = watch();
  }, [watch()]);

  const { oppData } = GetOppData(
    String(getURLParams.get("InputField-opp-key"))
  );
  const { createOpp,createOppLoading } = CreateOpp();
  const { updateOpp ,updateOppLoading} = UpdateOpp();
  const { formDataLoading, formData } = GetFormData(params);
  const { createFormEntry,createFormEntryLoading } = CreateFormEntry();

  console.log("createOppLoading", createFormEntryLoading,updateOppLoading,createOppLoading);

  const onSubmit = (data) => {
    createFormEntry({
      variables: {
        formId: params.id,
        // teamId: formData?.formData?.team_id,
        formEntryData: [data],
      },
    });


    const example1Data = data;

    const newState = board.reduce((acc, item) => {
      const value = example1Data[item.elementid];
      if (value !== undefined) {
        acc[item.name] = value;
      }
      return acc;
    }, {});
    setData(() => newState);

    if (getURLParams.get("InputField-opp-key")) {
      console.log("data>>  Running Update Opp");
      updateOpp({
        variables: {
          opp_key: String(getURLParams.get("InputField-opp-key")),
          opp_data: data,
          form_id: params.id,
        },
      });
    } else {
      console.log("data>>  Running Create Opp");
      createOpp({
        variables: {
          opp_data: data,
          form_id: params.id,
        },
      });
    }
  };

  useEffect(() => {
    setOppData(oppData);
  }, [oppData?.opportunity]);
  console.log("oppData", OppDatas);

  useEffect(() => {
    setboard(() => formData?.formData.form_fields);
    setCondition(() => formData?.formData.form_conditions);
  }, [formData?.formData]);

  useEffect(() => {
    let elemVisiblity = {};
    let Visiblity = "";
    //console.log("board", board);
    board?.map((element) => {
      let elemkey = "";
      Visiblity = condition?.map((item) => {
        //console.log("item", item);
        const found = item?.Fields?.find((element) => {
          //console.log("element", element);
          elemVisiblity[element] = item?.Visibility;
          //console.log("elemVisiblity", elemVisiblity);
        })?.Visibility;
        // //console.log("found", found);
      });
      // //console.log("Visiblity", Visiblity);

      //elemVisiblity = { ...elemVisiblity, [element.elementid]: Visiblity };
    });
    // //console.log("condition_data", response);
    setInitElementVisiblity(() => elemVisiblity);
    //console.log("initElementVisiblity", initElementVisiblity);
    setElementVisiblity((prev) => ({ ...prev, ...elemVisiblity }));
    // });
  }, [board]);

  useEffect(() => {
    //console.log("useWatchdata", useWatchdata);
    let getfieldsToShow = Array.from(
      get_fields_to_show(useWatchdata, condition)
    );
    setFieldsToShow(getfieldsToShow);
  }, [useWatchdata]);

  useEffect(() => {
    setElementVisiblity((prev) => {
      let newElementVisiblity = prev;
      //console.log("newStartElementVisiblity", newElementVisiblity);
      if (fieldsToShow?.length === 0) {
        //console.log("fieldsToShow initElementVisiblity", initElementVisiblity);
        return { ...initElementVisiblity };
      }
      for (let key in initElementVisiblity) {
        if (fieldsToShow.includes(key)) {
          newElementVisiblity[key] = "hide";
        } else {
          newElementVisiblity[key] = "show";
        }
      }
      //console.log("newEndElementVisiblity", newElementVisiblity);
      return { ...newElementVisiblity };
    });
    // } else didMount.current = true;
  }, [fieldsToShow]);

  useEffect(() => {
    //console.log("elementVisiblityUseEffect", elementVisiblity);
  }, [elementVisiblity]);
  const col = "";
  // localStorage.getItem("color")

  const styles = col
    ? { backgroundColor: col, marginTop: "0px" }
    : { backgroundColor: "#065697", marginTop: "0px" };

  if (formDataLoading) return <Loaderr />;
  if(createFormEntryLoading || updateOppLoading || createOppLoading) return <Loaderr />;

  return (
    <div className="main fmc-main px-0" style={styles}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="fm-condition mt-0">
          <section className="container-fluid mb-5 pb-5 col-lg-12 mx-auto">
            <div
              className="bg-white d-flex flex-wrap justify-content-center align-items-center py-3 px-4 mb-lg-5 mb-4 fixed-top"
              style={{ marginTop: "0px" }}
            >
              <span className="m-0 d-flex align-items-center">
                <i
                  className="iconify nav_icon me-1 fs-4"
                  data-icon="ic:baseline-dynamic-form"
                />
                {formData?.formData?.form_name}
              </span>
            </div>
            <section className="cols-xxl-6 col-xl-7 col-lg-8 col-md-8 col-sm-10 col-12 mb-5 pb-5 mx-auto d-flex align-items-center justify-content-center mt-5 pt-3">
              <div className="bg-white p-4 p-xl-5 mb-5 rounded-3 border w-100">
                <div className="d-flex border-bottom pb-3 mb-4">
                  <img
                    className="img-fluid mx-lg-auto"
                    src={logo}
                    width={130}
                    alt=""
                  />
                </div>
                <div className="container">
                  <div className="row">
                    {board?.length > 0 &&
                      board?.map((element, index) => {
                        const showHide = elementVisiblity[element?.elementid];
                        return (
                          <BoardElemForPre
                            element={element}
                            key={index}
                            Error={error}
                            oppData={OppDatas}
                            showHide={showHide}
                            setElementVisiblity={setElementVisiblity}
                            previewFlag={previewFlag}
                          />
                        );
                      })}
                  </div>
                </div>
                {
                  <div className="d-flex justify-content-end mt-4 w-100">
                    <div className="d-flex align-items-center">
                      <button className="btn btn-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                }
              </div>
            </section>
          </section>
        </section>
      </form>
    </div>
  );
}

export default Preview;
