import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeApiCall } from "../../../modules/MakeApiRequest";
import { gql } from '@apollo/client';
import { WorkFlowSessionInfo } from '../../../pages/automation-builder/workflows/EditWorkflow'
import {generatekeys_per_action} from "../../../utils/parser";
import { generatekeys } from "../../../utils/parser";


const TestTrigger = ({ trigger_id,setTriggerDetails,triggerdetails, setTestTrigger, set_test_result, setShowDetails, selected_action, testAgain }) => {
  const { workflow_id } = useParams();
  const {session, setsession, workflow_helper} = useContext(WorkFlowSessionInfo);
  const [testTriggerOutput, setTestTriggerOutput] = useState(session ? session?.trigger?.action_output : []);
  const [outputArray, setOutputArray] = useState([]);
  const [testActionStatus, setTestActionStatus] = useState("");
  useEffect(() => {
    if(testAgain){
    if (trigger_id) {
      makeApiCall("POST", "user/test_action/", {"action_id": "trigger", "trigger_id": trigger_id,
                                                  "workflow_id": workflow_id}).then((data) => {
      setTestTriggerOutput(data?.action_output);
      if(data?.action_output?.errorMessage){
        setTestTriggerOutput({ Error: data?.action_output?.errorMessage})
      }
      else if(data?.action_info?.error){
        setTestTriggerOutput({ Error: data?.action_info?.error})
      }
      else{
        setTestTriggerOutput(data?.action_output)
      }
      setTestActionStatus(data?.action_status);
      set_test_result(data?.action_status)
      setsession({...session, trigger: data})
      workflow_helper.current.update_action_info("trigger")({test_status: data?.action_status})
      }
      );
    }
  }
  }, [testAgain]);

  useEffect(()=>{
    setTriggerDetails({...triggerdetails, test_status: testActionStatus})
  },[testActionStatus])


  const handleOutput = () => {
    let keys = generatekeys({ trigger :testTriggerOutput})
    setOutputArray(keys?.trigger)
  };

  useEffect(() => {
    handleOutput();
  }, [testTriggerOutput, selected_action, testAgain]);

  const setOutput = (data) => {
    let output = [];
    var keys_ = generatekeys_per_action(data);
    for (const [index, [key, value]] of Object.entries(keys_).entries()) {
      value.map((option) =>
      option?.key_show !== "todays_date" &&
        output.push(
          <p>
            {" "}
            <b>{option.key_show}</b> {option.value}
          </p>
        )
      );
    }
    return output;
  };
 
  const testAction=()=>{
    let test="Loading...!"
  if(testActionStatus === "FAILED"){
    test= "Test failed!"
  }else if(testActionStatus===undefined){
    test="Test Failed Please Test Again"
 } else if(testActionStatus === "SUCCESS"){
    test= "Test successful!"
  }
  test = testAgain? test : session?.trigger?.action_status === "SUCCESS" ? "Test successful!" : "Test failed!"
  return test
}
  return (
    <>
        <>
          <div
            className="border rounded mt-3"
          >
            <div className="modal-header p-3 pb-0">
              <h6 className="modal-title fs-6">
                Test Details
              </h6>
            </div>
            <div className="p-3">
              <div className={`badge ${ testAction() === "Test successful!" ? "bg-success" : testAction() === "Loading...!" ? "bg-dark" : "bg-danger"} mb-2 fw-light rounded-pill px-3`}>
               {testAction()}
                </div>
              <div className="p-3 border bg-color-1 mb-3 rounded-3 text-trigger-box">
              {outputArray.length > 0 ? (
                <div className="m-0 ps-2 small border-start border-3 border-primary">
                  { testAction() === "Loading...!" ? <div>Fetching data...</div> :
                 outputArray.map((item) =>
                      <p>{item.key_show + ": " + item.value}</p>
                  )}
                </div>
                  ) : (
                    testAction() === "Loading...!" ? <div>Fetching data...</div> :
                    <div>Something went wrong.</div> 
                  )}
              </div>
            </div>
          </div>
        </>
    </>
  );
};

export default TestTrigger;
