import { React, useState } from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

const ConditionRuleStateUi = ({
  data_type,
  board,
  handleChange,
  Ruleindex,
  conditionIndex,
  selectedElementId,
  item,
}) => {
  console.log("data_type", data_type);
  let Ui = null;
  switch (data_type.toLowerCase()) {
    case "text":
    case "contact":
    case "email":
    case "inputfield":
    case "textarea":
    case "freetext":
    case "headings":
      Ui = (
        <input
          name="expected_value"
          required
          onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
          className="form-control input-bn"
          value={item.expected_value}
          type="text"
        />
      );
      break;
    case "numberfield":
      Ui = (
        <input
          name="expected_value"
          required
          onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
          className="form-control input-bn"
          value={item.expected_value}
          type="number"
        />
      );
      break;
    case "date":
      Ui = (
        <DatePicker
          format="MM/DD/YYYY"
          className="date-picker-input form-control input-bn w-100"
          onChange={(date, dateString) => {
            let e = {
              target: {
                name: "expected_value",
                value: dateString,
              },
            };
            console.log("eDatePicker", e, item.expected_value);
            handleChange(e, Ruleindex, conditionIndex);
          }}
          name="expected_value"
          defaultValue={""}
          value={item.expected_value !== "" ? dayjs(item?.expected_value) : item?.expected_value }
        />
        // <input
        //   name="expected_value"
        //   required
        //   onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
        //   className="form-control input-bn"
        //   type="date"
        // />
      );
      break;
    case "time":
      Ui = (
        <TimePicker
        format={"HH:mm"}
        minuteStep={5}
          className="form-control input-bn"
          onChange={(time,timeString) => {
            let e = {
              target: {
                name: "expected_value",
                value: timeString,
              },
            };
            console.log("eDatePicker", e, item.expected_value);
            handleChange(e, Ruleindex, conditionIndex);
            // console.log("value>", value)
            // field.onChange(val);
            // setval(value);
          }}
          // value={field.value}
          name="expected_value"
          defaultValue={item.expected_value}
        />
        // <input
        //   name="expected_value"
        //   required
        //   onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
        //   className="form-control input-bn"
        //   type="time"
        //   value={item.expected_value}
        // />
      );
      break;
    // case "Datetime":
    //   Ui = (
    //     <input
    //       name="expected_value"
    //       required
    //       onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
    //       className="form-control input-bn"
    //       type="datetime"
    //     />
    //   );
    //   break;
    // case "Email":
    //   Ui = (
    //     <input
    //       name="expected_value"
    //       required
    //       onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
    //       className="form-control input-bn"
    //       type="email"
    //       value={item.expected_value}
    //     />
    //   );
    //   break;
    case "radiobutton":
    case "checkbox":
    case "dropdown":
      Ui = (
        <select
          className="form-select input-bn"
          name="expected_value"
          value={item.expected_value === undefined ? "" : item.expected_value}
          required
          onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
        >
          <option value="">Please select a Option</option>
          {board.map(
            (field) => (
              console.log("-----------", field.elementid, selectedElementId),
              field.elementid === selectedElementId &&
                field?.settings?.options?.map((field) => (
                  <option value={field.value}>{field.value}</option>
                ))
            )
          )}
        </select>
      );
      break;
    // case "Radio":
    //   Ui = (
    //     <select
    //       className="form-select input-bn"
    //       id
    //       name="expected_value"
    //       required
    //       onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
    //     >
    //       {board.map((field) => (
    //         <option value={field.elementid}>{field.type}</option>
    //       ))}
    //     </select>
    //   );
    //   break;
    // case "Checkbox":
    //   Ui = (
    //     <select
    //       className="form-select input-bn"
    //       id
    //       name="expected_value"
    //       required
    //       onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
    //     >
    //       {board.map((field) => (
    //         <option value={field.elementid}>{field.type}</option>
    //       ))}
    //     </select>
    //   );
    //   break;
    case "ratings":
      Ui = (
        <select
          className="form-select input-bn"
          id
          name="expected_value"
          required
          onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
          value={item.expected_value === undefined ? "" : item.expected_value}
        >
          <option value="">Please select a Option</option>
          <option value="1">1 Star</option>
          <option value="2">2 Star</option>
          <option value="3">3 Star</option>
          <option value="4">4 Star</option>
          <option value="5">5 Star</option>
        </select>
        // <input
        //   name="expected_value"
        //   required
        //   onChange={(e) => handleChange(e, Ruleindex, conditionIndex)}
        //   className="form-control input-bn"
        //   type="number"
        //   value={item.expected_value}
        //   max={5}
        //   min={1}
        // />
      );
      break;
  }

  return Ui;
};

export default ConditionRuleStateUi;
