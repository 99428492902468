import React from "react";

export function ElementsErrorMessage({errorMessage}) {
  // console.log("errorMessage", errorMessage)

  return (
    <div className="d-flex flex-column gap-2">
      <small className="text-danger">
        {
          Object.keys(errorMessage).length > 0 &&
          Object.keys(errorMessage).map((key, index) => (errorMessage[key].message))
        }
      </small>
    </div>
  );
}
