export const Opp_Forms = {
  Terminate_Form:[
    {
      elementid: "InputField-Follow-up-boss-lead-id",
      type: "InputField",
      linked_to: "Follow_up_boss_lead_id",
      name: "Follow Up Boss Lead Id",
      settings: {
        placeHolder: "Follow Up Boss Lead ID",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: true,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-opp-key",
      type: "InputField",
      linked_to: "opp key",
      name: "Opp key",
      settings: {
        placeHolder: "Opp Key",
        predefine: "",
        size: "large",
        inputType: "text",
        required: "",
        hidden:true,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-Reason-for-termination",
      type: "InputField",
      linked_to: "Follow_up_boss_lead_id",
      name: "Reason For the Termination",
      settings: {
        placeHolder: "Reason For the Termination",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },


],
  Appointment_Form: [
    {
      elementid: "Headings-87458750",
      name: "Appointment Form 2.0: Template",
      type: "Headings",
      linked_to: "Appointment_Form_2.0:_Template",
      settings: {
        title: "Appointment Form 2.0: Template",
        size: "left",
        inputType: "text",
      
      },
    },
    {
      elementid: "FreeText-87547852",
      name: "This is the form for scheduling appointments that are new opportunities. A deal will be created in Follow Up Boss and a transaction will be created in SISU. ",
      type: "FreeText",
      linked_to:
        "This_is_the_form_for_scheduling_appointments_that_are_new_opportunities._A_deal_will_be_created_in_Follow_Up_Boss_and_a_transaction_will_be_created_in_SISU.",
      settings: {
        description:
          "This is the form for scheduling appointments that are new opportunities. A deal will be created in Follow Up Boss and a transaction will be created in SISU. ",
      
      },
    },
    {
      elementid: "InputField-Follow-up-boss-lead-id",
      type: "InputField",
      linked_to: "Follow_up_boss_lead_id",
      name: "Follow Up Boss Lead Id",
      settings: {
        placeHolder: "Follow Up Boss Lead ID",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-opp-key",
      type: "InputField",
      linked_to: "opp key",
      name: "Opp key",
      settings: {
        placeHolder: "Opp Key",
        predefine: "",
        size: "large",
        inputType: "text",
        required: "",
        hidden:true,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-Appointment-Set-By",
      type: "DropDown",
      linked_to: "Appointment_Set_By",
      name: "Appointment Set By",
      settings: {
        options: [
          { id: 1, value: "ISA", isCheck: false },
          { id: 2, value: "OSA", isCheck: false },
          { id: 3, value: "Admin", isCheck: false },
        ],
        label: "Appt Set By",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-opp_isa",
      type: "DropDown",
      linked_to: "opp_isa",
      name: "Opp ISA",
      settings: {
        options: [
          { id: 1, value: "Daniel Poston", isCheck: false },
          { id: 2, value: "Deeksha T", isCheck: false },
        ],
        label: "ISAs",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-Admin",
      type: "DropDown",
      linked_to: "Admin",
      name: "Admin",
      settings: {
        options: [{ id: 1, value: "Jim Smith", isCheck: false }],
        label: "Admin",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-appt_set_lead_type",
      type: "DropDown",
      linked_to: "appt_set_lead_type",
      name: "Appt Set Lead Type",
      settings: {
        options: [
          { id: 1, value: "Buyer", isCheck: false },
          { id: 21, value: "Seller", isCheck: false },
          { id: 511255, value: "BuyerSeller", isCheck: false },
        ],
        label: "Lead Type",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-Lead-Last-Name",
      type: "InputField",
      linked_to: "Lead_Last_Name",
      name: "Lead Last Name",
      settings: {
        placeHolder: "Lead Last Name",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-87458524",
      name: "Are you folks planning on making a move in the next 3-6 Months?",
      type: "DropDown",
      linked_to:
        "Are_you_folks_planning_on_making_a_move_in_the_next_3-6_Months?",
      settings: {
        options: [
          { id: 1, value: "0 - 3 Months", isCheck: false },
          { id: 21, value: "4 - 6 Months", isCheck: false },
          { id: 5141, value: "7 - 12 Months", isCheck: false },
        ],
        label:
          "Are you folks planning on making a move in the next 3-6 Months?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-851485177",
      name: "Do you have an idea on when you'd like to close?",
      type: "InputField",
      linked_to: "Do_you_have_an_idea_on_when_you'd_like_to_close?",
      settings: {
        placeHolder: "Do you have an idea on when you'd like to close?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-522578521",
      name: "If you were to move, would you be staying in the area or moving out of the area?",
      type: "InputField",
      linked_to:
        "If_you_were_to_move,_would_you_be_staying_in_the_area_or_moving_out_of_the_area?",
      settings: {
        placeHolder:
          "If you were to move, would you be staying in the area or moving out of the area?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-875478524",
      name: "What's your primary motivation for making a move?",
      type: "InputField",
      linked_to: "What's_your_primary_motivation_for_making_a_move?",
      settings: {
        placeHolder: "What's your primary motivation for making a move?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-854752145",
      name: "Do you have a realtor to help you when the time is right?",
      type: "DropDown",
      linked_to: "Do_you_have_a_realtor_to_help_you_when_the_time_is_right?",
      settings: {
        options: [
          { id: 1, value: "Yes", isCheck: false },
          { id: 21, value: "No", isCheck: false },
        ],
        label: "Do you have a realtor to help you when the time is right?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-21245872",
      name: "Are you 100% committed to that realtor?",
      type: "DropDown",
      linked_to: "Are_you_100%_committed_to_that_realtor?",
      settings: {
        options: [
          { id: 1, value: "Yes", isCheck: false },
          { id: 21, value: "No", isCheck: false },
        ],
        label: "Are you 100% committed to that realtor?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Headings-23458745",
      name: "Appointment Details",
      type: "Headings",
      linked_to: "Appointment_Details",
      settings: {
        title: "Appointment Details",
        size: "left",
        inputType: "text",
      },
    },
    {
      elementid: "DropDown-854782214",
      name: "Did you schedule an appointment?",
      type: "DropDown",
      linked_to: "Did_you_schedule_an_appointment?",
      settings: {
        options: [
          { id: 2510, value: "", isCheck: false },
          { id: 1, value: "Yes", isCheck: false },
          { id: 21, value: "No", isCheck: false },
        ],
        label: "Did you schedule an appointment?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Date-opp_appt_date",
      type: "Date",
      linked_to: "opp_appt_date",
      name: "Opp Appt Date",
      settings: {
        placeHolder: "Appointment Date",
        required: false,
        instructions: "",
      },
    },
    {
      elementid: "DropDown-fub_appt_start_time",
      type: "DropDown",
      linked_to: "fub_appt_start_time",
      name: "Fub Appt Start Time",
      settings: {
        options: [
          { id: 2115, value: "07:30 AM", isCheck: false },
          { id: 1, value: "08:00 AM", isCheck: false },
          { id: 21, value: "08:15 AM", isCheck: false },
          { id: 4751, value: "08:30 AM", isCheck: false },
          { id: 7801, value: "08:45 AM", isCheck: false },
          { id: 7051, value: "09:00 AM", isCheck: false },
          { id: 5, value: "09:15 AM", isCheck: false },
          { id: 7401, value: "09:30 AM", isCheck: false },
          { id: 7551, value: "09:45 AM", isCheck: false },
          { id: 87001, value: "10:00 AM", isCheck: false },
          { id: 81, value: "10:15 AM", isCheck: false },
          { id: 7541, value: "10:30 AM", isCheck: false },
          { id: 7421, value: "10:45 AM", isCheck: false },
          { id: 2771, value: "11:00 AM", isCheck: false },
          { id: 54441, value: "11:15 AM", isCheck: false },
          { id: 716, value: "11:30 AM", isCheck: false },
          { id: 7014, value: "11:45 AM", isCheck: false },
          { id: 3701, value: "12:00 PM", isCheck: false },
          { id: 941, value: "12:15 PM", isCheck: false },
          { id: 7127, value: "12:30 PM", isCheck: false },
          { id: 71557, value: "12:45 PM", isCheck: false },
          { id: 731, value: "01:00 PM", isCheck: false },
          { id: 7771, value: "01:15 PM", isCheck: false },
          { id: 7178, value: "01:30 PM", isCheck: false },
          { id: 7871, value: "01:45 PM", isCheck: false },
          { id: 2701, value: "02:00 PM", isCheck: false },
          { id: 351, value: "02:15 PM", isCheck: false },
          { id: 7182, value: "02:30 PM", isCheck: false },
          { id: 721, value: "02:45 PM", isCheck: false },
          { id: 3721, value: "03:00 PM", isCheck: false },
          { id: 4771, value: "03:15 PM", isCheck: false },
          { id: 7881, value: "03:30 PM", isCheck: false },
          { id: 712, value: "03:45 PM", isCheck: false },
          { id: 4871, value: "04:00 PM", isCheck: false },
          { id: 571, value: "04:15 PM", isCheck: false },
          { id: 713, value: "04:30 PM", isCheck: false },
          { id: 711, value: "04:45 PM", isCheck: false },
          { id: 7501, value: "05:00 PM", isCheck: false },
          { id: 761, value: "05:15 PM", isCheck: false },
          { id: 7114, value: "05:30 PM", isCheck: false },
          { id: 521, value: "05:45 PM", isCheck: false },
          { id: 7108, value: "06:00 PM", isCheck: false },
          { id: 7157, value: "06:15 PM", isCheck: false },
          { id: 715, value: "06:30 PM", isCheck: false },
          { id: 781, value: "06:45 PM", isCheck: false },
          { id: 719, value: "07:00 PM", isCheck: false },
          { id: 70321, value: "07:15 PM", isCheck: false },
          { id: 7910, value: "07:30 PM", isCheck: false },
          { id: 871, value: "07:45 PM", isCheck: false },
          { id: 710, value: "08:00 PM", isCheck: false },
          { id: 7911, value: "08:15 PM", isCheck: false },
          { id: 54541, value: "08:30 PM", isCheck: false },
        ],
        label: "Appointment Time",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-App-timezone",
      type: "DropDown",
      linked_to: "App_timezone",
      name: "App timezone",
      settings: {
        options: [
          { id: 1425, value: "America/New_York", isCheck: false },
          { id: 1, value: "America/Los_Angeles", isCheck: false },
        ],
        label: "Appointment DropDownzone",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-Appt-Location",
      type: "DropDown",
      linked_to: "Appt_Location",
      name: "Appt Location",
      settings: {
        options: [
          { id: 2015, value: "Office", isCheck: false },
          { id: 2221, value: "Other Address", isCheck: false },
          { id: 5552, value: "Seller Property Address", isChek: false },
          { id: 52254, value: "Buyer Inquiry Address", isCheck: false },
          { id: 788, value: "Zoom", isCheck: false },
          { id: 245, value: "Phone", isCheck: false },
        ],
        label: "Appointment Location",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-app-type",
      type: "DropDown",
      linked_to: "app_type",
      name: "App Type",
      settings: {
        options: [
          { id: 25515, value: "No Type", isCheck: false },
          { id: 2541, value: "Buyer consultation", isCheck: false },
          { id: 52557, value: "Listing", isChek: false },
          { id: 55754, value: "Showing", isCheck: false },
        ],
        label: "Appointment Type",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-zoom-link",
      type: "InputField",
      linked_to: "zoom_link",
      name: "Zoom Link",
      settings: {
        placeHolder: "Zoom Link",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "FreeText-874558244",
      name: "Address of Appointment",
      type: "FreeText",
      linked_to: "Address_of_Appointment",
      settings: {
        description: "Address of Appointment",
      },
    },
    {
      elementid: "InputField-opp_address",
      type: "InputField",
      linked_to: "opp_address",
      name: "Opp Address",
      settings: {
        placeHolder: "Street Address",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    // {
    //   elementid: "InputField-opp_address2",
    //   type: "InputField",
    //   linked_to: "opp_address2",
    //   name: "Opp Address2",
    //   settings: {
    //     placeHolder: "Address Line 2",
    //     predefine: "",
    //     size: "large",
    //     inputType: "text",
    //     required: false,
    //     hidden: false,
    //     minValue: "0",
    //     maxValue: "",
    //     instructions: "",
    //   },
    // },
    {
      elementid: "InputField-opp_city",
      type: "InputField",
      linked_to: "opp_city",
      name: "Opp City",
      settings: {
        placeHolder: "City",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-opp_state",
      type: "InputField",
      linked_to: "opp_state",
      name: "Opp State",
      settings: {
        placeHolder: "State",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-opp_postal_code",
      type: "InputField",
      linked_to: "opp_postal_code",
      name: "Opp Postal Code",
      settings: {
        placeHolder: "Zip Code",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-opp_country",
      type: "DropDown",
      linked_to: "opp_country",
      name: "Opp Country",
      settings: {
        options: [
          { id: 25545, value: "USA", isCheck: false },
          { id: 2551, value: "Canada", isCheck: false },
        ],
        label: "Country",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "TextArea-App-Description",
      type: "TextArea",
      linked_to: "App_Description",
      name: "App Description",
      settings: {
        placeHolder: "Appointment Description",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions:
          "This field will be added to the appointment description and the client will be able to read it.",
      },
    },
    {
      elementid: "DropDown-85478214",
      name: "Are you the primary decision maker, and are there any other decision makers?",
      type: "DropDown",
      linked_to:
        "Are_you_the_primary_decision_maker,_and_are_there_any_other_decision_makers?",
      settings: {
        options: [
          { id: 505541, value: "", isCheck: false },
          {
            id: 21,
            value: "Yes - Primary and Only Decision Maker",
            isCheck: false,
          },
          {
            id: 55451,
            value: "Yes - Primary but NOT Only Decision Maker",
            isCheck: false,
          },
          { id: 5102, value: "No - Not Primary Decision Maker", isCheck: false },
        ],
        label:
          "Are you the primary decision maker, and are there any other decision makers?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-85124785",
      name: "Will they be available for the appointment?",
      type: "DropDown",
      linked_to: "Will_they_be_available_for_the_appointment?",
      settings: {
        options: [
          { id: 55881, value: "", isCheck: false },
          { id: 21, value: "Yes", isCheck: false },
          { id: 55541, value: "No", isCheck: false },
        ],
        label: "Will they be available for the appointment?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-8745887",
      name: "Name",
      type: "InputField",
      linked_to: "Name",
      settings: {
        placeHolder: "Name",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Contact-87417854",
      name: "Secondary Contact Phone Number",
      type: "Contact",
      linked_to: "Secondary_Contact_Phone_Number",
      settings: {
        placeHolder: "Secondary Phone Number",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Email-52145875",
      name: "Secondary Contact Email",
      type: "Email",
      linked_to: "Secondary_Contact_Email",
      settings: {
        placeHolder: "Secondary Email",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-852214723",
      name: "May I ask where you received our name and information from or who can we thank for referring you?",
      type: "InputField",
      linked_to:
        "May_I_ask_where_you_received_our_name_and_information_from_or_who_can_we_thank_for_referring_you?",
      settings: {
        placeHolder:
          "May I ask where you received our name and information from or who can we thank for referring you?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-25478524",
      name: "Second Source",
      type: "InputField",
      linked_to: "Second_Source",
      settings: {
        placeHolder: "Second Source",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Headings-214578214",
      name: "Buyer Section",
      type: "Headings",
      linked_to: "Buyer_Section",
      settings: {
        title: "Buyer Section",
        size: "left",
        inputType: "text",
      },
    },
    {
      elementid: "DropDown-32475474",
      name: "Do you currently rent or own your own home?",
      type: "DropDown",
      linked_to: "Do_you_currently_rent_or_own_your_own_home?",
      settings: {
        options: [
          { id: 1, value: "Rent", isCheck: false },
          { id: 21, value: "Own", isCheck: false },
        ],
        label: "Do you currently rent or own your own home?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-25487421",
      name: "When does your lease end?",
      type: "InputField",
      linked_to: "When_does_your_lease_end?",
      settings: {
        placeHolder: "When does your lease end?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "NumberField-458452145",
      name: "Home Search Price Low",
      type: "NumberField",
      linked_to: "Home_Search_Price_Low",
      settings: {
        placeHolder: "Home Search Price Low",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "NumberField-582457852",
      name: "Home Search Price High",
      type: "NumberField",
      linked_to: "Home_Search_Price_High",
      settings: {
        placeHolder: "Home Search Price High",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-585145214",
      name: "Have you been pre-approved by a mortgage company?",
      type: "DropDown",
      linked_to: "Have_you_been_pre-approved_by_a_mortgage_company?",
      settings: {
        options: [
          { id: 1451, value: "Yes", isCheck: false },
          { id: 21, value: "No", isCheck: false },
        ],
        label: "Have you been pre-approved by a mortgage company?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "FreeText-8574558244",
      name: "Inquiry Address",
      type: "FreeText",
      linked_to: "Inquiry",
      settings: {
        description: "Inquiry Address",
      },
    },
    {
      elementid: "InputField-857452145",
      name: "Street Address",
      type: "InputField",
      linked_to: "Address",
      settings: {
        placeHolder: "Street Address",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-875452145",
      name: "City",
      type: "InputField",
      linked_to: "City",
      settings: {
        placeHolder: "City",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-87455257145",
      name: "State",
      type: "InputField",
      linked_to: "State",
      settings: {
        placeHolder: "State",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-8745552145",
      name: "Zip Code",
      type: "InputField",
      linked_to: "Zip_Code",
      settings: {
        placeHolder: "Zip Code",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-8741244214",
      name: "Country",
      type: "DropDown",
      linked_to: "Country",
      settings: {
        options: [
          { id: 1, value: "USA", isCheck: false },
          { id: 21, value: "Canada", isCheck: false },
        ],
        label: "Country",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-3145874",
      name: "What areas are you most interested in?",
      type: "InputField",
      linked_to: "What_areas_are_you_most_interested_in?",
      settings: {
        placeHolder: "What areas are you most interested in?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-23547832",
      name: "What are the specifics you're looking for in a home?",
      type: "InputField",
      linked_to: "What_are_the_specifics_you're_looking_for_in_a_home?",
      settings: {
        placeHolder: "What are the specifics you're looking for in a home?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-822455235",
      name: "Rental",
      type: "DropDown",
      linked_to: "Rental_Section",
      settings: {
        options: [
          { id: 3, value: "", isCheck: false },
          { id: 1, value: "Yes", isCheck: false },
          { id: 21, value: "No", isCheck: false },
        ],
        label: "Rental",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Headings-12454124",
      name: "Seller Section",
      type: "Headings",
      linked_to: "Seller_Section",
      settings: {
        title: "Seller Section",
        size: "left",
        inputType: "text",
      },
    },
    {
      elementid: "NumberField-12121451",
      name: "Do you know what you owe on your home?",
      type: "NumberField",
      linked_to: "Do_you_know_what_you_owe_on_your_home?",
      settings: {
        placeHolder: "Do you know what you owe on your home?",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "NumberField-11455444514",
      name: "Home Value Low",
      type: "NumberField",
      linked_to: "Home_Value_Low",
      settings: {
        placeHolder: "Home Value Low",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "NumberField-1144544514",
      name: "Home Value High",
      type: "NumberField",
      linked_to: "Home_Value_High",
      settings: {
        placeHolder: "Home Value High",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "FreeText-87455824484",
      name: "The address of the home you are selling is:",
      type: "FreeText",
      linked_to: "Inquiry",
      settings: {
        description: "The address of the home you are selling is:",
      },
    },
    {
      elementid: "InputField-8574521545",
      name: "Street Address",
      type: "InputField",
      linked_to: "Address",
      settings: {
        placeHolder: "Street Address",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-874521145",
      name: "City",
      type: "InputField",
      linked_to: "City",
      settings: {
        placeHolder: "City",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-8745521545",
      name: "State",
      type: "InputField",
      linked_to: "State",
      settings: {
        placeHolder: "State",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-8784521545",
      name: "Zip Code",
      type: "InputField",
      linked_to: "Zip_Code",
      settings: {
        placeHolder: "Zip Code",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-8741242144",
      name: "Country",
      type: "DropDown",
      linked_to: "Country",
      settings: {
        options: [
          { id: 1, value: "USA", isCheck: false },
          { id: 21, value: "Canada", isCheck: false },
        ],
        label: "Country",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "NumberField-235451254",
      name: "Number of bedrooms",
      type: "NumberField",
      linked_to: "Number_of_bedrooms",
      settings: {
        placeHolder: "Number of bedrooms",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "NumberField-2354251254",
      name: "Number of bathrooms",
      type: "NumberField",
      linked_to: "Number_of_bathrooms",
      settings: {
        placeHolder: "Number of bathrooms",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "NumberField-2354154254",
      name: "Number of garage spaces",
      type: "NumberField",
      linked_to: "Square_footage",
      settings: {
        placeHolder: "Number of garage spaces",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-254785425",
      name: "What are the most important things to you in the sale of your home?",
      type: "InputField",
      linked_to:
        "What_are_the_most_important_things_to_you_in_the_sale_of_your_home?",
      settings: {
        placeHolder:
          "What are the most important things to you in the sale of your home?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-225478524",
      name: "Would you prefer to buy before listing your home, or do you want to sell first?",
      type: "DropDown",
      linked_to:
        "Would_you_prefer_to_buy_before_listing_your_home,_or_do_you_want_to_sell_first?",
      settings: {
        options: [
          { id: 1, value: "Buy First", isCheck: false },
          { id: 21, value: "Sell First", isCheck: false },
        ],
        label:
          "Would you prefer to buy before listing your home, or do you want to sell first?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-852545575",
      name: "What have you done to your home, if anything, since you bought it?",
      type: "InputField",
      linked_to:
        "What_have_you_done_to_your_home,_if_anything,_since_you_bought_it?",
      settings: {
        placeHolder:
          "What have you done to your home, if anything, since you bought it?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-85478547",
      name: "Listing Type",
      type: "DropDown",
      linked_to: "Listing_Type",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 21, value: "Regular Listing", isCheck: false },
          { id: 52, value: "GO Listing", isCheck: false },
          { id: 2, value: "ZO Listing", isCheck: false },
        ],
        label: "Listing Type",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDpon-25252457",
      name: "Is the basement finished?",
      type: "DropDown",
      linked_to: "Is_the_basement_finished?",
      settings: {
        options: [
          { id: 85, value: "", isCheck: false },
          { id: 1, value: "Yes", isCheck: false },
          { id: 21, value: "No", isCheck: false },
        ],
        label: "Is the basement finished?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-32124521",
      name: "Bedroom and bathroom breakdown by level",
      type: "InputField",
      linked_to: "Bedroom_and_bathroom_breakdown_by_level",
      settings: {
        placeHolder: "Bedroom and bathroom breakdown by level",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Headings-25478524",
      name: "Additional Information",
      type: "Headings",
      linked_to: "Additional_Information",
      settings: {
        title: "Additional Information",
        size: "left",
        inputType: "text",
      },
    },
    {
      elementid: "DropDown-Appointment-Assignment",
      type: "DropDown",
      linked_to: "Appointment_Assignment",
      name: "Appointment Assignment",
      settings: {
        options: [
          { id: 5, value: "", isCheck: false },
          { id: 2502, value: "Assign OSA Now", isCheck: false },
          { id: 1, value: "Assign OSA Later", isCheck: false },
        ],
        label: "Appointment Assignment",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-Send-Confirmation",
      type: "DropDown",
      linked_to: "Send_Confirmation",
      name: "Send Confirmation",
      settings: {
        options: [
          { id: 5, value: "", isCheck: false },
          { id: 2545, value: "Yes", isCheck: false },
          { id: 1, value: "No", isCheck: false },
        ],
        label: "Send Confirmation",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-opp_assigned_osa",
      type: "DropDown",
      linked_to: "opp_assigned_osa",
      name: "Opp Assigned OSA",
      settings: {
        options: [
          { id: 5, value: "Carol Danvers", isCheck: false },
          { id: 2845, value: "Clark Kent", isCheck: false },
          { id: 1, value: "Daniel Poston", isCheck: false },
        ],
        label: "Opp Assigned OSA",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "CheckBox-D.I.S.C-Profile",
      type: "CheckBox",
      linked_to: "D.I.S.C_Profile",
      name: "D.I.S.C Profile",
      settings: {
        options: [
          { id: 1, value: "(D)ominant", isCheck: false },
          { id: 21, value: "(I)nfluencer", isCheck: false },
          { id: 1, value: "(S)upportive", isCheck: false },
          { id: 21, value: "(C)onscientiousness", isCheck: false },
        ],
        label: "D.I.S.C Profile",
        size: "large",
        inputType: "checkbox",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "TextArea-Notes",
      type: "TextArea",
      linked_to: "Notes",
      name: "Notes",
      settings: {
        placeHolder: "Notes",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
  ],

  Transaction_Form: [
    {
      elementid: "Headings-87458750",
      name: "Transaction Form: Template",
      type: "Headings",
      linked_to: "Transaction_Form:_Template",
      settings: {
        title: "Transaction Form: Template",
        size: "left",
        inputType: "text",
      
      },
    },
    {
      elementid: "InputField-Follow-up-boss-lead-id",
      type: "InputField",
      linked_to: "Follow_up_boss_lead_id",
      name: "Follow Up Boss Lead Id",
      settings: {
        placeHolder: "Follow Up Boss Lead Id",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-client-last-name",
      name: "Client Last Name",
      type: "InputField",
      linked_to: "Client_Last_Name",
      settings: {
        placeHolder: "Client Last Name",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      },
    },
    {
      elementid: "DropDown-client-type",
      name: "Client Type",
      type: "DropDown",
      linked_to: "Client_Type",
      settings: {
        options: [
          { id: 15551, value: "", isCheck: false },
          { id: 10021, value: "Seller", isCheck: false },
          { id: 241, value: "Buyer", isCheck: false },
        ],
        label: "Client Type",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      },
    },
    {
      elementid: "InputField-opp-key",
      type: "InputField",
      linked_to: "opp key",
      name: "Opp key",
      settings: {
        placeHolder: "Opp key",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
    
      },
    },
    {
      elementid: "DropDown-creating-updating-transaction",
      name: "Creating or Updating Transaction?",
      type: "DropDown",
      linked_to: "Creating_or_Updating_Transaction?",
      settings: {
        options: [
          { id: 1002, value: "", isCheck: false },
          { id: 20001, value: "Update_Existing_Transaction", isCheck: false },
          { id: 55041, value: "Create_New_Transaction", isCheck: false },
        ],
        label: "Creating or Updating Transaction?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      },
    },
    {
      elementid: "InputField-fub_deal_id",
      type: "InputField",
      linked_to: "fub_deal_id",
      name: "Fub Deal ID",
      settings: {
        placeHolder: "Fub Deal ID",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      },
    },
    {
      elementid: "InputField-fub-seller-deal-id",
      name: "FUB Seller Deal ID",
      type: "InputField",
      linked_to: "FUB_Seller_Deal_ID",
      settings: {
        placeHolder: "FUB Seller Deal ID",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-transaction-stage",
      name: "Transaction Stage",
      type: "DropDown",
      linked_to: "Transaction_Stage",
      settings: {
        options: [
          { id: 1012, value: "", isCheck: false },
          { id: 4421, value: "Agreement_Signed", isCheck: false },
          { id: 5251, value: "Pending", isCheck: false },
          { id: 504511, value: "Closed", isCheck: false },
          { id: 5011, value: "Terminated", isCheck: false },
        ],
        label: "Transaction Stage",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-pending-stage",
      name: "Pending Stage",
      type: "DropDown",
      linked_to: "Pending_Stage",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 21, value: "Accepted", isCheck: false },
          { id: 2551, value: "Under Contract", isCheck: false }
        ],
        label: "Pending Stage",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-transaction-type",
      name: "Transaction Type",
      type: "DropDown",
      linked_to: "Transaction_Type",
      settings: {
        options: [
          { id: 10015, value: "", isCheck: false },
          { id: 2011, value: "New Build", isCheck: false },
          { id: 501151, value: "Short Sale", isCheck: false },
          { id: 5551, value: "Traditional", isCheck: false },
        ],
        label: "Transaction Type",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-property-type",
      name: "Property Type",
      type: "DropDown",
      linked_to: "Property_Type",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 21, value: "Condo", isCheck: false },
          { id: 521, value: "Land", isCheck: false },
          { id: 55441, value: "Mobile Home", isCheck: false },
          { id: 551, value: "New Build", isCheck: false },
          { id: 561, value: "PUD", isCheck: false },
          { id: 571, value: "SFR", isCheck: false },
          { id: 581, value: "Townhouse", isCheck: false },


        ],
        label: "Property Type",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-appt_set_lead_type",
      type: "DropDown",
      linked_to: "appt_set_lead_type",
      name: "Appt Set Lead Type",
      settings: {
        options: [
          { id: 11115, value: "", isCheck: false },
          { id: 20241, value: "Agent Generated", isCheck: false },
          { id: 5122, value: "Team Generated", isCheck: false },
        ],
        label: "Appt Set Lead Type",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
      
    },
    {
      elementid: "DropDown-8224524235",
      name: "Rental",
      type: "DropDown",
      linked_to: "Rental_Section",
      settings: {
        options: [
          { id: 3, value: "", isCheck: false },
          { id: 1, value: "Yes", isCheck: false },
          { id: 21, value: "No", isCheck: false },
        ],
        label: "Rental",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Headings-888255785",
      name: "Transaction Data",
      type: "Headings",
      linked_to: "Transaction_Data",
      settings: {
        title: "Transaction Data",
        size: "left",
        inputType: "text",
      
      
      },
    },
    {
      elementid: "FreeText-854752250",
      name: "Address",
      type: "FreeText",
      linked_to: "Address",
      settings: {
        description: "Address",
      
      
    
      },
    },
    {
      elementid: "InputField-87452145",
      name: "Street Address",
      type: "InputField",
      linked_to: "Address",
      settings: {
        placeHolder: "Street Address",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      },
    },
    {
      elementid: "InputField-88784578",
      name: "Address Line 2",
      type: "InputField",
      linked_to: "Address_Line_2",
      settings: {
        placeHolder: "Address Line 2",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
    
      },
    },
    {
      elementid: "InputField-87452145",
      name: "City",
      type: "InputField",
      linked_to: "City",
      settings: {
        placeHolder: "City",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-87452145",
      name: "State",
      type: "InputField",
      linked_to: "State",
      settings: {
        placeHolder: "State",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-87452145",
      name: "Zip Code",
      type: "InputField",
      linked_to: "Zip_Code",
      settings: {
        placeHolder: "Zip Code",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "DropDown-874124214",
      name: "Country",
      type: "DropDown",
      linked_to: "Country",
      settings: {
        options: [
          {id:5,value:"",isCheck:false},
          { id: 1, value: "USA", isCheck: false },
          { id: 21, value: "Canada", isCheck: false },
        ],
        label: "Country",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-87545221",
      name: "Transaction Amount",
      type: "InputField",
      linked_to: "Transaction_Amount",
      settings: {
        placeHolder: "Transaction Amount",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-555547852",
      name: "Listing Amount",
      type: "InputField",
      linked_to: "Listing_Amount",
      settings: {
        placeHolder: "Listing Amount",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      },
    },
    {
      elementid: "InputField-854782452",
      name: "Gross Commission Percentage",
      type: "InputField",
      linked_to: "Gross_Commission_Percentage",
      settings: {
        placeHolder: "Gross Commission Percentage",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      },
    },
    {
      elementid: "InputField-555474145",
      name: "Agent Commission Percentage",
      type: "InputField",
      linked_to: "Agent_Commission_Percentage",
      settings: {
        placeHolder: "Agent Commission Percentage",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-854782452",
      name: "Agent Commission Amount",
      type: "InputField",
      linked_to: "Agent_Commission_Amount",
      settings: {
        placeHolder: "Agent Commission Amount",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-85485215",
      name: "TC Fee",
      type: "InputField",
      linked_to: "TC_Fee",
      settings: {
        placeHolder: "TC Fee",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-785488522",
      name: "Referral Fee",
      type: "DropDown",
      linked_to: "Referral_Fee",
      settings: {
        options: [
          { id: 1022, value: "", isCheck: false },
          { id: 2021, value: "Yes", isCheck: false },
          { id: 5111, value: "No", isCheck: false },
        ],
        label: "Referral Fee",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "InputField-888745875",
      name: "Referral Fee: Company",
      type: "InputField",
      linked_to: "Referral_Fee:_Company",
      settings: {
        placeHolder: "Referral Fee: Company",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-857545258",
      name: "Referral Fee: Agent",
      type: "InputField",
      linked_to: "Referral_Fee:_Agent",
      settings: {
        placeHolder: "Referral Fee: Agent",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-874525153",
      name: "Referral Fee: Percent",
      type: "InputField",
      linked_to: "Referral_Fee:_Percent",
      settings: {
        placeHolder: "Referral Fee: Percent",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },

    },
    {
      elementid: "DropDown-88778878",
      name: "Is there a Transaction/Admin fee?",
      type: "DropDown",
      linked_to: "Is_there_a_Transaction/Admin_fee?",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 21, value: "Yes", isCheck: false },
          { id: 5121, value: "No", isCheck: false },
        ],
        label: "Is there a Transaction/Admin fee?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      },
    },
    {
      elementid: "InputField-87452145",
      name: "Transaction/Admin Fee Amount",
      type: "InputField",
      linked_to: "Transaction/Admin_Fee_Amount",
      settings: {
        placeHolder: "Transaction/Admin Fee Amount",
        predefine: "",
        size: "large",
        inputType: "number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      },
    },
    {
      elementid: "Headings-225544532",
      name: "Transaction Dates",
      type: "Headings",
      linked_to: "Transaction_Dates",
      settings: {
        title: "Transaction Dates",
        size: "left",
        inputType: "text",
      
      
      
      },
    },
    // {
    //   elementid: "Date-2225844558",
    //   name: "Signed Date",
    //   type: "Date",
    //   linked_to: "Signed_Date",
    //   settings: {
    //     placeHolder: "Signed Date",
    //     predefine: "",
    //     size: "large",
    //     inputType: "date",
    //     required: false,
    //     hidden: false,
    //     minValue: "",
    //     maxValue: "",
    //     instructions: "",
    //   },
    // },
    {
      elementid: "Date-2255744558",
      name: "Agreement Expiration Date",
      type: "Date",
      linked_to: "Agreement_Expiration_Date",
      settings: {
        placeHolder: "Agreement Expiration Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Date-2254784558",
      name: "Offer Date",
      type: "Date",
      linked_to: "Offer_Date",
      settings: {
        placeHolder: "Offer Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      },
    },
    {
      elementid: "Date-2255531558",
      name: "Under Contract Date",
      type: "Date",
      linked_to: "Under_Contract_Date",
      settings: {
        placeHolder: "Under Contract Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      },
    },
    {
      elementid: "Date-2255448558",
      name: "Settlement Date",
      type: "Date",
      linked_to: "Settlement_Date",
      settings: {
        placeHolder: "Settlement Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Date-2255445578",
      name: "Sign Install Date",
      type: "Date",
      linked_to: "Sign_Install_Date",
      settings: {
        placeHolder: "Sign Install Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Date-2255445584",
      name: "Open House Date",
      type: "Date",
      linked_to: "Open_House_Date",
      settings: {
        placeHolder: "Open House Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Date-2255445585",
      name: "Target Photo Date",
      type: "Date",
      linked_to: "Target_Photo_Date",
      settings: {
        placeHolder: "Target Photo Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Date-2255445858",
      name: "MLS Live Date",
      type: "Date",
      linked_to: "MLS_Live_Date",
      settings: {
        placeHolder: "MLS Live Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Date-2255445458",
      name: "Acceptance Date",
      type: "Date",
      linked_to: "Acceptance_Date",
      settings: {
        placeHolder: "Acceptance Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      },
    },
    {
      elementid: "Date-2255445758",
      name: "Forecasted Close Date",
      type: "Date",
      linked_to: "Forecasted_Close_Date",
      settings: {
        placeHolder: "Forecasted Close Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "Date-254785488",
      name: "Due Dilligence Date",
      type: "Date",
      linked_to: "Due_Dilligence_Date",
      settings: {
        placeHolder: "Due Dilligence Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Date-587458785",
      name: "Home Inspection Date",
      type: "Date",
      linked_to: "Home_Inspection_Date",
      settings: {
        placeHolder: "Home Inspection Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Headings-87458742",
      name: "Listing Fields",
      type: "Headings",
      linked_to: "Listing_Fields",
      settings: {
        title: "Listing Fields",
        size: "left",
        inputType: "text",
      
      
      
      },
    },
    {
      elementid: "DropDown-857854275",
      name: "Staging Needed",
      type: "DropDown",
      linked_to: "Staging_Needed",
      settings: {
        options: [
          { id: 1021, value: "", isCheck: false },
          { id: 2011, value: "Yes", isCheck: false },
          { id: 5251, value: "No", isCheck: false },
        ],
        label: "Staging Needed",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-857854555",
      name: "Who is paying for staging?",
      type: "DropDown",
      linked_to: "Who_is_paying_for_staging?",
      settings: {
        options: [
          { id: 1121, value: "", isCheck: false },
          { id: 2021, value: "Agent", isCheck: false },
          { id: 5110, value: "Client", isCheck: false },
          { id: 8521, value: "Team", isCheck: false },

        ],
        label: "Who is paying for staging?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-857858255",
      name: "Sign Requirements",
      type: "DropDown",
      linked_to: "Sign_Requirements",
      settings: {
        options: [
          { id: 1022, value: "", isCheck: false },
          { id: 2211, value: "Yard Sign", isCheck: false },
          { id: 2151, value: "Yard Sign + Directional Sign", isCheck: false },
          { id: 5251, value: "Directional Sign", isCheck: false },
          { id: 5871, value: "Window Sign (for condos)", isCheck: false },
          { id: 57821, value: "No Sign", isCheck: false },

        ],
        label: "Sign Requirements",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "DropDown-857844255",
      name: "Photo Requirements",
      type: "DropDown",
      linked_to: "Photo_Requirements",
      settings: {
        options: [
          { id: 1022, value: "", isCheck: false },
          { id: 2021, value: "Daytime Photos", isCheck: false },
          { id: 52251, value: "Daytime & Twilight Photos", isCheck: false },
        ],
        label: "Photo Requirements",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      },
    },
    {
      elementid: "InputField-78547852",
      name: "Photo Notes",
      type: "InputField",
      linked_to: "Photo_Notes",
      settings: {
        placeHolder: "Photo Notes",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      },
    },
    {
      elementid: "DropDown-854785245",
      name: "Video Requirements",
      type: "DropDown",
      linked_to: "Video_Requirements",
      settings: {
        options: [
          { id: 2521, value: "", isCheck: false },
          { id: 2021, value: "PROPERTY VIDEO: Photos", isCheck: false },
          { id: 5021, value: "PROPERTY VIDEO: Full Motion", isCheck: false },
          {id:25875,value:"None",isCheck:false}
        ],
        label: "Video Requirements",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-864785245",
      name: "Direct Mail Marketing",
      type: "DropDown",
      linked_to: "Direct_Mail_Marketing",
      settings: {
        options: [
          { id: 1011, value: "", isCheck: false },
          { id: 20141, value: "Just Listed Letters", isCheck: false },
          { id: 5251, value: "None", isCheck: false },
        ],
        label: "Direct Mail Marketing",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-874785245",
      name: "Solar",
      type: "DropDown",
      linked_to: "Solar",
      settings: {
        options: [
          { id: 2551, value: "", isCheck: false },
          { id: 2154, value: "None", isCheck: false },
          { id: 114, value: "Solar - Financed", isCheck: false },
          { id: 25841, value: "Solar - Leased", isCheck: false },
          { id: 3151, value: "Solar - Ownership Unknown", isCheck: false },
          { id: 4541, value: "Solar - Paid in Full", isCheck: false },
          { id: 5121, value: "Solar - PPA", isCheck: false },

        ],
        label: "Solar",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Headings-854752245",
      name: "Transaction Participants",
      type: "Headings",
      linked_to: "Transaction_Participants",
      settings: {
        title: "Transaction Participants",
        size: "left",
        hidden:true,
        inputType: "text",
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-884785245",
      name: "Transaction Coordinator",
      type: "DropDown",
      linked_to: "Transaction_Coordinator",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "First Choice", isCheck: false },
          { id: 221, value: "Second Choice", isCheck: false },
          { id: 241, value: "Third Choice", isCheck: false },

        ],
        label: "Transaction Coordinator",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-894785245",
      name: "Is there HOA",
      type: "DropDown",
      linked_to: "Is_there_HOA",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "Yes", isCheck: false },
          { id: 221, value: "No", isCheck: false },
        ],
        label: "Is there HOA",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "InputField-5847457822",
      name: "HOA Contact Name",
      type: "InputField",
      linked_to: "HOA_Contact_Name",
      settings: {
        placeHolder: "HOA Contact Name",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-5874857822",
      name: "HOA Management Company",
      type: "InputField",
      linked_to: "HOA_Management_Company",
      settings: {
        placeHolder: "HOA Management Company",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Contact-5874457822",
      name: "HOA Management Phone",
      type: "Contact",
      linked_to: "HOA_Management_Phone",
      settings: {
        placeHolder: "HOA Management Phone",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-5874557822",
      name: "Cooperating Agent Name",
      type: "InputField",
      linked_to: "Cooperating_Agent_Name",
      settings: {
        placeHolder: "Cooperating Agent Name",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-5874857822",
      name: "Cooperating Agent Company",
      type: "InputField",
      linked_to: "Cooperating_Agent_Company",
      settings: {
        placeHolder: "Cooperating Agent Company",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Contact-5874457822",
      name: "Cooperating Agent Phone",
      type: "Contact",
      linked_to: "Cooperating_Agent_Phone",
      settings: {
        placeHolder: "Cooperating Agent Phone",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Email-5874557822",
      name: "Cooperating Agent Email",
      type: "Email",
      linked_to: "Cooperating_Agent_Email",
      settings: {
        placeHolder: "Cooperating Agent Email",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-57845545",
      name: "Cooperating TC Name",
      type: "InputField",
      linked_to: "Cooperating_TC_Name",
      settings: {
        placeHolder: "Cooperating TC Name",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "0",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Contact-588745484",
      name: "Cooperating TC Phone",
      type: "Contact",
      linked_to: "Cooperating_TC_Phone",
      settings: {
        placeHolder: "Cooperating TC Phone",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Email-5874557822",
      name: "Cooperating TC Email",
      type: "Email",
      linked_to: "Cooperating_TC_Email",
      settings: {
        placeHolder: "Cooperating TC Email",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      
      },
    },
    {
      elementid: "DropDown-552255485",
      name: "Is there a secondary client?",
      type: "DropDown",
      linked_to: "Is_there_a_secondary_client?",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "Yes", isCheck: false },
          { id: 221, value: "No", isCheck: false },
        ],
        label: "Is there a secondary client?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "Contact-8547214522",
      name: "Secondary Contact Name",
      type: "Contact",
      linked_to: "Secondary_Contact_Name",
      settings: {
        placeHolder: "Secondary Contact Name",
        predefine: "",
        size: "large",
        inputType:"number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Email-854785245",
      name: "Secondary Contact Email",
      type: "Email",
      linked_to: "Secondary_Contact_Email",
      settings: {
        placeHolder: "Secondary Contact Email",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Contact-854785245",
      name: "Secondary Contact Phone",
      type: "Contact",
      linked_to: "Secondary_Contact_Phone",
      settings: {
        placeHolder: "Secondary Contact Phone",
        predefine: "",
        size: "large",
        inputType:"number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "DropDown-555224878",
      name: "Title Company",
      type: "DropDown",
      linked_to: "Title_Company",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "First Choice", isCheck: false },
          { id: 221, value: "Second Choice", isCheck: false },
          { id: 241, value: "Other", isCheck: false },

        ],
        label: "Title Company",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-854785245",
      name: "Title Company Name",
      type: "InputField",
      linked_to: "Title_Company_Name",
      settings: {
        placeHolder: "Title Company Name",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-520258585",
      name: "Title Rep Name",
      type: "InputField",
      linked_to: "Title_Rep_Name",
      settings: {
        placeHolder: "Title Rep Name",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Email-854248214",
      name: "Title Rep Email",
      type: "Email",
      linked_to: "Title_Rep_Email",
      settings: {
        placeHolder: "Title Rep Email",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "DropDown-52225555",
      name: "Escrow Company",
      type: "DropDown",
      linked_to: "Escrow_Company",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "First Choice", isCheck: false },
          { id: 221, value: "Second Choice", isCheck: false },
          { id: 241, value: "Other", isCheck: false },

        ],
        label: "Escrow Company",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-854785245",
      name: "Escrow Company Name",
      type: "InputField",
      linked_to: "Escrow_Company_Name",
      settings: {
        placeHolder: "Escrow Company Name",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      },
    },
    {
      elementid: "InputField-55785205",
      name: "Escrow Officer Name",
      type: "InputField",
      linked_to: "Escrow_Officer_Name",
      settings: {
        placeHolder: "Escrow Officer Name",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      },
    },
    {
      elementid: "Email-552478245",
      name: "Escrow Officer Email",
      type: "Email",
      linked_to: "Escrow_Officer_Email",
      settings: {
        placeHolder: "Escrow Officer Email",
        predefine: "",
        size: "large",
        inputType:"email",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      },
    },
    {
      elementid: "Contact-854785245",
      name: "Escrow Officer Phone",
      type: "Contact",
      linked_to: "Escrow_Officer_Phone",
      settings: {
        placeHolder: "Escrow Officer Phone",
        predefine: "",
        size: "large",
        inputType:"number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-55247852",
      name: "Financing Type",
      type: "DropDown",
      linked_to: "Financing_Type",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "Conventional", isCheck: false },
          { id: 221, value: "FHA Loan", isCheck: false },
          { id: 241, value: "VA Loan", isCheck: false },
          { id: 241, value: "Cash", isCheck: false },
          { id: 241, value: "Other", isCheck: false },

        ],
        label: "Financing Type",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
      },
    
    {
      elementid: "DropDown-85214742",
      name: "Loan Officer",
      type: "DropDown",
      linked_to: "Loan_Officer",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "First Choice", isCheck: false },
          { id: 221, value: "Second Choice", isCheck: false },
          { id: 241, value: "Other", isCheck: false },

        ],
        label: "Loan Officer",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-52245782",
      name: "Mortgage Company Name",
      type: "InputField",
      linked_to: "Mortgage_Company_Name",
      settings: {
        placeHolder: "Mortgage Company Name",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      },
    },
    {
      elementid: "InputField-52245782",
      name: "Loan Officer Name",
      type: "InputField",
      linked_to: "Loan_Officer_Name",
      settings: {
        placeHolder: "Loan Officer Name",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "Email-52245782",
      name: "Loan Officer Email",
      type: "Email",
      linked_to: "Loan_Officer_Email",
      settings: {
        placeHolder: "Loan Officer Email",
        predefine: "",
        size: "large",
        inputType:"email",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "Contact-52245782",
      name: "Loan Officer Phone",
      type: "Contact",
      linked_to: "Loan_Officer_Phone",
      settings: {
        placeHolder: "Loan Officer Phone",
        predefine: "",
        size: "large",
        inputType:"number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-52020255",
      name: "Occupancy",
      type: "DropDown",
      linked_to: "Occupancy",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "Owner Occupied", isCheck: false },
          { id: 221, value: "Tenant Occupied", isCheck: false },
          
        ],
        label: "Occupancy",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-522452148",
      name: "Tenant Name",
      type: "InputField",
      linked_to: "Tenant_Name",
      settings: {
        placeHolder: "Tenant Name",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Contact-522452148",
      name: "Tenant Phone",
      type: "Contact",
      linked_to: "Tenant_Phone",
      settings: {
        placeHolder: "Tenant Phone",
        predefine: "",
        size: "large",
        inputType:"number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "Email-522452148",
      name: "Tenant Email",
      type: "Email",
      linked_to: "Tenant_Email",
      settings: {
        placeHolder: "Tenant Email",
        predefine: "",
        size: "large",
        inputType:"email",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "InputField-552487820",
      name: "Attorney Name",
      type: "InputField",
      linked_to: "Attorney_Name",
      settings: {
        placeHolder: "Attorney Name",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Email-552487820",
      name: "Attorney Email",
      type: "Email",
      linked_to: "Attorney_Email",
      settings: {
        placeHolder: "Attorney Email",
        predefine: "",
        size: "large",
        inputType:"email",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-52258578",
      name: "Water",
      type: "DropDown",
      linked_to: "Water",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "Public Water", isCheck: false },
          { id: 221, value: "Well Water ", isCheck: false },
          { id: 241, value: "Other", isCheck: false },

        ],
        label: "Water",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "DropDown-52258578",
      name: "Sewer",
      type: "DropDown",
      linked_to: "Sewer",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "Public Sewer", isCheck: false },
          { id: 221, value: "Septic", isCheck: false },
          { id: 241, value: "Other", isCheck: false },

        ],
        label: "Sewer",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      },
    },
    {
      elementid: "InputField-52448820",
      name: "Year Built",
      type: "InputField",
      linked_to: "Year_Built",
      settings: {
        placeHolder: "Year Built",
        predefine: "",
        size: "large",
        inputType:"number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      
      
      
      
      
      
      },
    },
    {
      elementid: "TextArea-4457510",
      name: "Type Property Description for MLS and Marketing",
      type: "TextArea",
      linked_to: "Type_Property_Description_for_MLS_and_Marketing",
      settings: {
        placeHolder: "Type Property Description for MLS and Marketing",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "TextArea-52558520",
      name: "Notes",
      type: "TextArea",
      linked_to: "Notes",
      settings: {
        placeHolder: "Notes",
        predefine: "",
        size: "large",
        inputType:"text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-22225577",
      name: "Appt Entry ID",
      type: "InputField",
      linked_to: "Appt_Entry_ID",
      settings: {
        placeHolder: "Appt Entry ID",
        predefine: "",
        size: "large",
        inputType:"number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Email-522555240",
      name: "Email (For Automation)",
      type: "Email",
      linked_to: "Email_(For_Automation)",
      settings: {
        placeHolder: "Email (For Automation)",
        predefine: "",
        size: "large",
        inputType:"email",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "InputField-22225577",
      name: "Listing Agent Commission Percent",
      type: "InputField",
      linked_to: "Listing_Agent_Commission_Percent",
      settings: {
        placeHolder: "Listing Agent Commission Percent",
        predefine: "",
        size: "large",
        inputType:"number",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
  ],
  Disposition_Form: [
    {
      elementid: "Headings-78213548",
      name: "Disposition Form: Template",
      type: "Headings",
      linked_to: "Disposition_Form",
      settings: {
        title: "Disposition Form: Template",
        size: "left",
        inputType: "text",
      
      },
    },
    {
      elementid: "InputField-Follow-up-boss-lead-id",
      type: "InputField",
      linked_to: "Follow_up_boss_lead_id",
      name: "Follow Up Boss Lead Id",
      settings: {
        placeHolder: "Follow Up Boss Lead Id",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-opp-key",
      type: "InputField",
      linked_to: "opp key",
      name: "Opp key",
      settings: {
        placeHolder: "Opp key",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      },
    },
    {
      elementid: "InputField-appt_form_id",
      type: "InputField",
      linked_to: "appt_form_id",
      name: "Appt Form ID",
      settings: {
        placeHolder: "Appt Form ID",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      },
    },
    {
      elementid: "InputField-Lead-Last-Name",
      type: "InputField",
      linked_to: "Lead_Last_Name",
      name: "Lead Last Name",
      settings: {
        placeHolder: "Lead Last Name",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-opp_appt_disposition",
      type: "DropDown",
      linked_to: "opp_appt_disposition",
      name: "Opp Appt Disposition",
      settings: {
        options: [
          { id: 78541, value: "", isCheck: false },
          { id: 20111, value: "Yes", isCheck: false },
          { id: 8011, value: "No", isCheck: false },
          { id: 5441, value: "Rescheduled", isCheck: false },
          
        ],
        label: "Opp Appt Disposition",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Date-opp_appt_met_date",
      type: "Date",
      linked_to: "opp_appt_met_date",
      name: "Opp Appt Met Date",
      settings: {
        placeHolder: "Opp Appt Met Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-854782014",
      name: "Was the Appt Qualified?",
      type: "DropDown",
      linked_to: "Was_the_Appt_Qualified?",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 21, value: "Yes", isCheck: false },
          { id: 81, value: "No", isCheck: false },
        ],
        label: "Was the Appt Qualified?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-85471254",
      name: "Why wasn't the Appt Qualified?",
      type: "InputField",
      linked_to: "Why_wasn't_the_Appt_Qualified?",
      settings: {
        placeHolder: "Why wasn't the Appt Qualified?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      
      },
    },
    {
      elementid: "DropDown-appt_outcome",
      type: "DropDown",
      linked_to: "appt_outcome",
      name: "Appt Outcome",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 21, value: "No outcome", isCheck: false },
          { id: 81, value: "No Agreement Signed", isCheck: false },
          { id: 52541, value: "BBA obtained", isCheck: false },
          { id: 41, value: "Listing obtained", isCheck: false },
          { id: 71, value: "BBA and Listing Agreement", isCheck: false },


          
        ],
        label: "Appt Outcome",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Ratings-app-rating",
      name: "Appt Rating",
      type: "Ratings",
      linked_to: "Appt_Rating",
      settings: {
        placeHolder: "Appt Rating",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "DropDown-854752155",
      name: "Why Didn't the Appt Happen?",
      type: "DropDown",
      linked_to: "Why_Didn't_the_Appt_Happen?",
      settings: {
        options: [
          { id: 1021, value: "", isCheck: false },
          { id: 2551, value: "Cancelled", isCheck: false },
          { id: 201141, value: "No Show", isCheck: false },
          
        ],
        label: "Why Didn't the Appt Happen?",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      
      
      
      
      
      
      },
    },
    {
      elementid: "DropDown-85412578452",
      name: "Next Steps",
      type: "DropDown",
      linked_to: "Next_Steps",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "First Choice", isCheck: false },
          { id: 221, value: "Second Choice", isCheck: false },
          { id: 241, value: "Third Choice", isCheck: false },

        ],
        label: "Water",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-contact-stage",
      name: "Contact Stage",
      type: "DropDown",
      linked_to: "Contact_Stage",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 11, value: "Engaged", isCheck: false },
          { id: 221, value: "Nurture", isCheck: false },
          { id: 241, value: "Active Client", isCheck: false },

        ],
        label: "Contact Stage",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden:true,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "Headings-25448752",
      name: "Rescheduled Appointment Details",
      type: "Headings",
      linked_to: "Rescheduled_Appointment_Details",
      settings: {
        title: "Rescheduled Appointment Details",
      },
    },
    {
      elementid: "Date-rescheduled-app-date",
      name: "Rescheduled Appointment Date",
      type: "Date",
      linked_to: "Rescheduled_Appointment_Date",
      settings: {
        placeHolder: "Rescheduled Appointment Date",
        predefine: "",
        size: "large",
        inputType: "date",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "Time-rescheduled-time",
      name: "Rescheduled Appointment Time",
      type: "Time",
      linked_to: "Rescheduled_Appointment_Time",
      settings: {
        options: [
          { id: 2545, value: "07:30 AM", isCheck: false },
          { id: 1, value: "08:00 AM", isCheck: false },
          { id: 21, value: "08:15 AM", isCheck: false },
          { id: 50241, value: "08:30 AM", isCheck: false },
          { id: 7021, value: "08:45 AM", isCheck: false },
          { id: 7011, value: "09:00 AM", isCheck: false },
          { id: 5, value: "09:15 AM", isCheck: false },
          { id: 7441, value: "09:30 AM", isCheck: false },
          { id: 7511, value: "09:45 AM", isCheck: false },
          { id: 8701, value: "10:00 AM", isCheck: false },
          { id: 81, value: "10:15 AM", isCheck: false },
          { id: 7541, value: "10:30 AM", isCheck: false },
          { id: 7451, value: "10:45 AM", isCheck: false },
          { id: 2711, value: "11:00 AM", isCheck: false },
          { id: 5410, value: "11:15 AM", isCheck: false },
          { id: 716, value: "11:30 AM", isCheck: false },
          { id: 7142, value: "11:45 AM", isCheck: false },
          { id: 3771, value: "12:00 PM", isCheck: false },
          { id: 941, value: "12:15 PM", isCheck: false },
          { id: 7170, value: "12:30 PM", isCheck: false },
          { id: 7171, value: "12:45 PM", isCheck: false },
          { id: 731, value: "01:00 PM", isCheck: false },
          { id: 7071, value: "01:15 PM", isCheck: false },
          { id: 7178, value: "01:30 PM", isCheck: false },
          { id: 7271, value: "01:45 PM", isCheck: false },
          { id: 2271, value: "02:00 PM", isCheck: false },
          { id: 351, value: "02:15 PM", isCheck: false },
          { id: 7018, value: "02:30 PM", isCheck: false },
          { id: 721, value: "02:45 PM", isCheck: false },
          { id: 3701, value: "03:00 PM", isCheck: false },
          { id: 4701, value: "03:15 PM", isCheck: false },
          { id: 7881, value: "03:30 PM", isCheck: false },
          { id: 712, value: "03:45 PM", isCheck: false },
          { id: 4711, value: "04:00 PM", isCheck: false },
          { id: 571, value: "04:15 PM", isCheck: false },
          { id: 713, value: "04:30 PM", isCheck: false },
          { id: 711, value: "04:45 PM", isCheck: false },
          { id: 7501, value: "05:00 PM", isCheck: false },
          { id: 761, value: "05:15 PM", isCheck: false },
          { id: 7148, value: "05:30 PM", isCheck: false },
          { id: 521, value: "05:45 PM", isCheck: false },
          { id: 7180, value: "06:00 PM", isCheck: false },
          { id: 7107, value: "06:15 PM", isCheck: false },
          { id: 715, value: "06:30 PM", isCheck: false },
          { id: 781, value: "06:45 PM", isCheck: false },
          { id: 719, value: "07:00 PM", isCheck: false },
          { id: 7201, value: "07:15 PM", isCheck: false },
          { id: 7981, value: "07:30 PM", isCheck: false },
          { id: 87071, value: "07:45 PM", isCheck: false },
          { id: 710, value: "08:00 PM", isCheck: false },
          { id: 7591, value: "08:15 PM", isCheck: false },
          { id: 5401, value: "08:30 PM", isCheck: false },
        ],
        label: "Appointment Time",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        // duplicate: true,
        minValue: "",
        maxValue: "",
        instructions: "",
      },
    },
    {
      elementid: "DropDown-rescheduled-loaction",
      name: "Rescheduled Appointment Location",
      type: "DropDown",
      linked_to: "Rescheduled_Appointment_Location",
      settings: {
        options: [
          { id: 1, value: "", isCheck: false },
          { id: 21, value: "Same Location", isCheck: false },
          { id: 51125, value: "New Address", isCheck: false },
          { id: 81, value: "Zoom", isCheck: false },
          
        ],
        label: "Rescheduled Appointment Location",
        size: "large",
        inputType: "dropdown",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "InputField-zoom-link",
      type: "InputField",
      linked_to: "zoom_link",
      name: "Zoom Link",
      settings: {
        placeHolder: "Zoom Link",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",

      },
    },
    {
      elementid: "Headings-85784521",
      name: "Appointment Notes",
      type: "Headings",
      linked_to: "Appointment_Notes",
      settings: {
        title: "Appointment Notes",
      
      }
      ,
    },
    {
      elementid: "TextArea-525884878",
      name: "What are the next steps to get the client to the finish line?",
      type: "TextArea",
      linked_to:
        "What_are_the_next_steps_to_get_the_client_to_the_finish_line?",
      settings: {
        placeHolder: "What are the next steps to get the client to the finish line?",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
    {
      elementid: "TextArea-Notes",
      type: "TextArea",
      linked_to: "Notes",
      name: "Notes",
      settings: {
        placeHolder: "Notes",
        predefine: "",
        size: "large",
        inputType: "text",
        required: false,
        hidden: false,
        minValue: "",
        maxValue: "",
        instructions: "",
      
      },
    },
  ],
};
