import React, { useState, useContext } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";

export const HeadingUi = ({
  elementid,
  PreviewData,
  showHide,
  previewFlag = false,
}) => {
  const [errorMessage, setErrorMessage] = useState({});
  const { board } = useContext(boardConetxt);
  ////console.log("board",board);
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;

  const ElementSettings = board.find((item) => item?.elementid === elementid);
  //console.log("ElementSettings", ElementSettings);
  const { title, position,hidden } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  console.log("previewFlag", title);

  return (
    <>
      <div
        className={previewFlag && GetStyleClasses(elementid) ||  ""}
        id={elementid}
        style={initStyle}
        hidden={hidden && previewFlag}
      >
        {title ? (
          <h2>{title}</h2>
        ) : ElementSetting ? (
          <h4>{ElementSettings.name}</h4>
        ) : (
          <h2>Sample Text</h2>
        )}
      </div>
      <ElementsErrorMessage errorMessage={errorMessage} />
    </>
  );
};
