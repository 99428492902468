import { Routes, Route, useLocation } from "react-router-dom";
import FormBuilder from "./pages/form-builder/form-builder";
//import AutomationBuilder from './pages/automation-builder/automation-builder';
//import Home from './pages/home/home';
//import Welcome from './pages/welcome/Welcome';
import Header from "./components/header-component";
import FormList from "./FormList";
import Conditions from "./pages/form-builder/conditions/Conditions";
import ConditionCreate from "./pages/form-builder/conditions/ConditionCreate";
import Preview from "./pages/form-builder/preview/Preview";
import { LayoutNavbar } from "./components/LayoutNavbar";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import CreateForm from "./pages/form-builder/dashboard/CreateForm";
import FormEntries from "./pages/form-builder/dashboard/FormEntries";
import OpportunitysList from "./pages/form-builder/dashboard/OpportunitysList";
import Opportunity from "./pages/form-builder/dashboard/Opportunity";

import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from "react";

import {
  boardConetxt,
  conditionContext,
} from "./pages/form-builder/dashboard/ContextHelper";

import AutomationBuilderDashboard from "./pages/automation-builder/AutomationBuilderDashboard";
import EditWorkflow from "./pages/automation-builder/workflows/EditWorkflow";
import CreateWorkflow from "./pages/automation-builder/workflows/CreateWorkflow";
import { makeApiCall } from "./modules/MakeApiRequest";
import Cookies from "js-cookie";
import DashBoardForFormBuilder from "./pages/form-builder/dashboard/DashBoardForFormBuilder";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { Form, FormProvider, useForm, useFormContext } from "react-hook-form";
import FormEntryData from "./pages/form-builder/dashboard/FormEntryData";
import PrivateRoute from "./PrivateRoute";
import ConditionsAll from "./components/form-builder-components/FormConditions/ConditionsAll";
import ThankyouPage from "./pages/form-builder/dashboard/ThankyouPage";
import WorkflowHistory from "./pages/automation-builder/workflows/WorkflowHistory";
import SettingsPage from "./pages/form-builder/dashboard/SettingsPage";
import ConversionRatio from "./pages/form-builder/dashboard/ConversionRatio";
// const httpLink = new HttpLink({ uri: API_HOST,
//                 credentials: 'include' });
// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   //console.log("./graphQLErrors", graphQLErrors);
//   //console.log("./networkError", networkError);
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       //console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );
//   if (networkError) //console.log(`./...[Network error]: ${networkError}`);
// });

// const link = ApolloLink.from([errorLink]);
// //console.log("./clientlinklink", link);

const client = new ApolloClient({
  // uri: "https://api.sandbox.datalabz.re/graphql",
  // uri: "https://api.datalabz.re/graphql",
  uri: "http://localhost:4000/graphql",
  cache: new InMemoryCache(),
  credentials: "include",
  // link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    },
  },
});

const UserTriggersList = createContext();
const WorkflowsList = createContext();
const userdetails = createContext();

function App() {
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { watch } = methods;
  const FormValues = useRef(watch());

  const [board, setboard] = useState([]);
  const [condition, setCondition] = useState([]);
  const [listOfWorkflows, setListOfWorkflows] = useState([]);
  const [listOfUserTriggers, setListOfUserTriggers] = useState([]);
  const [UserDetails, setUserDetails] = useState([]);
  const handleState = (newTrigger) => {
    //console.log("newTrigger", newTrigger);
    setListOfUserTriggers((prevTriggerlist) => {
      //console.log("prevTriggerlist", prevTriggerlist);
      return [...prevTriggerlist, newTrigger];
    });
  };
  // console.log(
  //   ">> location",
  //   location,
  //   window.location.hostname,
  //   window.location.port
  // );
  useEffect(() => {
    // makeApiCall("GET", "user/csrf/", "")
    //   .then((data) => {
    //     console.log("Cool", data);
    //     if (data !== undefined && data.redirected === true) {
    //       if (data.url.includes("localhost")) {
    //         // setShowPriview(true);
    //         // return (window.location.href =
    //         //   data.url +
    //         //   "?redirect=" +
    //         //   location.pathname +
    //         //   "&subdomain=" +
    //         //   window.location.hostname +
    //         //   ":" +
    //         //   window.location.port);
    //       } else {
    //         // setShowPriview(true);
    //         // return (window.location.href =
    //         //   data.url +
    //         //   "?redirect=" +
    //         //   location.pathname +
    //         //   "&subdomain=" +
    //         //   window.location.hostname);
    //       }
    //     }
    //     return data !== undefined && data.json();
    //   })
    //   .then((data1) => {
    //     //console.log("hllo", data1);
    //     const token = data1.csrftoken;
    //     //console.log("token csrftoken", token);
    //     Cookies.set("csrftoken", token, { secure: true, sameSite: "none" });
    //   });
  }, []);
  // useEffect(() => {
  //   makeApiCall("GET", "user/csrf/", "").then((data) => {
  //     if(data.redirected===true){
  //      return window.location.href = data.url;
  //     }
  //     return data.json();

  //   }).then((data1)=>{

  //     //console.log(">>>> hllo",data1)
  //     const token = data1.csrfToken;
  //     Cookies.set("csrftoken", token);

  //   })

  // }, []);

  const handleWorkflowsList = (newWorkflow) => {
    setListOfWorkflows((prevWorkflowsList) => [
      ...prevWorkflowsList,
      newWorkflow,
    ]);
  };

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="App">
        <FormProvider {...methods}>
          <ApolloProvider client={client}>
            <userdetails.Provider value={{ UserDetails, setUserDetails }}>
              <boardConetxt.Provider value={{ board, setboard }}>
                <conditionContext.Provider value={{ condition, setCondition }}>
                  <UserTriggersList.Provider
                    value={{ listOfUserTriggers, handleState }}
                  >
                    <WorkflowsList.Provider
                      value={{ listOfWorkflows, handleWorkflowsList }}
                    >
                      {/* <Header /> */}
                      <div>
                        {/* <Routes>
                          {/* <Route path="/" element={<Home />} /> */}
                        {/* <Route path="/dashboard" element={<DashBoardForFormBuilder />} /> */}
                        {/* <Route path="/form" element={<FormList/>} /> */}
                        {/* <Route path="/welcome" element={<Welcome />} /> */}
                        <Routes>
                          <Route path="/" element={<PrivateRoute />}>
                            <Route path="/thankyou" element={<ThankyouPage />} />
                            <Route
                            path="/opportunity/:oppid"
                            element={<Opportunity />}
                            />
                            <Route
                              path="/opportunities/"
                              element={<OpportunitysList />}
                            />
                             <Route
                              path="/conversion-ratio/"
                              element={<ConversionRatio />}
                            />
                          {/* <Route path="/forms" element={<LayoutNavbar />}>

                            <Route path="/forms/" element={<FormList />} />
                            <Route
                              path="/forms/create-form"
                              element={<CreateForm />}
                            /> */}
                            {/* <Route
                              path="/opportunitys"
                              element={<OpportunitysList />}
                            /> */}
                            <Route path="/forms" element={<LayoutNavbar />}>
                              <Route path="/forms/"  element={<FormList />} />
                              <Route
                                path="/forms/create-form"
                                
                                element={<CreateForm />}
                              />
                              <Route
                                path="/forms/entries/:id"
                                
                                element={<FormEntries />}
                              />
                              <Route
                                path="/forms/build/:id"
                                
                                element={<DashBoardForFormBuilder />}
                              />
                              <Route
                                path="/forms/create-form"
                                
                                element={<CreateForm />}
                              />
                              <Route 
                           path="/forms/settings/:id" element={<SettingsPage />} />


                              <Route
                                path="/forms/conditions/:id" element={<Conditions />}/>
                              
                              <Route
                                path="/forms/conditions/:id/create-edit/:condition_index"
                                
                                element={<ConditionCreate />}
                              />
                            </Route>
                            <Route
                              path="/automation-builder/create-trigger"
                              element={<AutomationBuilderDashboard />}
                            />
                            <Route
                              path="/automation-builder/"
                              element={<AutomationBuilderDashboard />}
                            />
                            <Route
                              path="/automation-builder/create-workflow"
                              element={<CreateWorkflow />}
                            />
                            <Route
                              path="/automation-builder/edit-workflow/:workflow_id"
                              element={<EditWorkflow />}
                            />
                            {/* <Route
                              path="/automation-builder/workflow-history"
                              element={<WorkflowHistory />}
                            /> */}
                          </Route>
                          <Route
                                path="/forms/:id/entries/:entryid"
                                element={<FormEntryData />}
                              />
                          {/* </Route> */}
                          <Route path="/forms/:id" element={<Preview />} />
                        </Routes>
                        {/* <PrivateRoute
                            path="/opportunity/:oppid"
                            element={<Opportunity />}
                          />
                          <PrivateRoute
                            path="/opportunitys"
                            element={<OpportunitysList />}
                          />
                          <PrivateRoute path="/forms" element={<LayoutNavbar />}>
                            <PrivateRoute path="/forms/" element={<FormList />} />
                            <PrivateRoute
                              path="/forms/entries/:id"
                              element={<FormEntries />}
                            />
                            <PrivateRoute
                              path="/forms/:id/entries/:entryid"
                              element={<FormEntryData />}
                            />
                            <PrivateRoute
                              path="/forms/build/:id"
                              element={<DashBoardForFormBuilder />}
                            />
                            <PrivateRoute
                              path="/forms/create-form"
                              element={<CreateForm />}
                            />

                            <PrivateRoute
                              path="/forms/conditions/:id"
                              element={<Conditions />}
                            />
                            <PrivateRoute
                              path="/forms/conditions/:id/create-edit/:condition_index"
                              element={<ConditionCreate />}
                              exact
                            />
                          </PrivateRoute> */}
                        {/* <Route path="/forms/:id" element={<Preview />} /> */}
                        {/* <Route path="/automation-builder" element={<AutomationBuilder />} /> */}
                        {/* <Header /> */}

                        {/* <Route path="/form-builder" element={<FormBuilder />} /> 
                          <PrivateRoute
                            path="/automation-builder/create-trigger"
                            element={<AutomationBuilderDashboard />}
                          />
                          <PrivateRoute
                            path="/automation-builder/"
                            element={<AutomationBuilderDashboard />}
                          />
                          <PrivateRoute
                            path="/automation-builder/create-workflow"
                            element={<CreateWorkflow />}
                          />
                          <PrivateRoute
                            path="/automation-builder/edit-workflow/:workflow_id"
                            element={<EditWorkflow />}
                          />
                        </Routes> */}
                      </div>
                    </WorkflowsList.Provider>
                  </UserTriggersList.Provider>
                </conditionContext.Provider>
              </boardConetxt.Provider>
            </userdetails.Provider>
          </ApolloProvider>
        </FormProvider>
      </div>
    </CacheBuster>
  );
}

export default App;
export { UserTriggersList };
export { WorkflowsList };
export { userdetails };
