import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions';
import { InputUi } from './InputUi';

export const DnDInput = ({elementid,element,oppFields}) => {
   const {board,setboard}=useContext(boardConetxt);
  return (
    <div className='fm-comp' id={elementid}>
        <ElementsSettingsWidget
         BoardDeleteHandler={BoardDeleteHandler}
         board={board}
         setboard={setboard}
        elementid={elementid}
        element={element}
         />
         <InputUi elementid={elementid} oppFields={oppFields}/>
    </div>
  )
}
