import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import { Loaderr } from "../../../components/Loaderr";
import { OppsNavBar } from "../../../components/HeadNavBar";
import '../../../assets/css/opportunityList.css';

export default function OpportunitysList() {
  const navigate = useNavigate();
  const { Opps } = useFormApiHelper();
  const { oppsLoading, oppsError, opps } = Opps();
  const handleEntryView = (oppid) => {
    navigate(`/opportunity/${oppid}`);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [showCustomise, setShowCustomise] = useState(false);

  const filteredOpps = opps?.Opps.filter((opp) =>
    Object.values(opp).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  let ischecked = false;


  const totalOppPrice = opps?.Opps?.reduce((accumulator, opp) => {
    if (typeof opp.opp_price === 'number' && !isNaN(opp.opp_price)) {
      return parseFloat((opp.opp_price + accumulator).toFixed(2));
    } else {
      return accumulator;
    }
  }, 0);

  const totalCgi = opps?.Opps?.reduce((accumulator, opp) => {
    if (typeof opp.opp_price === 'number' && !isNaN(opp.opp_price) &&
        typeof opp.opp_commission_percent === 'number' && !isNaN(opp.opp_commission_percent)) {
      const totalPriceWithCommission = opp.opp_price * (opp.opp_commission_percent );
      return parseFloat((totalPriceWithCommission + accumulator).toFixed(2));
    } else {
      return accumulator;
    }
  }, 0);
  

  function currencyConvert(totalOppPrice) {
    if (totalOppPrice >= 1000000000) {
      return (totalOppPrice / 1000000000).toFixed(2) + ' B';
    } else if (totalOppPrice >= 1000000) {
      return (totalOppPrice / 1000000).toFixed(2) + ' M';
    } else {
      return totalOppPrice.toString();
    }
  }
  



  if (oppsLoading) return <Loaderr />;
  return (
    <>
      <OppsNavBar />
      <div className="main fmc-main p-0">
        <section className="container-lg container-fluid pt-5">

          <section className="d-flex flex-column py-4 gap-3">
          {showFilter && (

<div class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
               <div class="modal-dialog modald-md w-100 modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0">
          <div class="modal-content rounded-0 h-100">

            <div class="modal-header px-4 rounded-0"  >
                <div class="d-flex justify-content-center align-items-center pointer">
                    <i class='iconify fs-5 me-2' data-icon="mdi:filter"></i>
                    <h5 class="modal-title fs-6">Filter</h5>
                </div>
                
                <button type="button" class="btn-close small" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowFilter(false)}></button>
            </div>


            <div class="modal-body p-0">


                <div class="accordion opp-accordion pb-5" id="accordionPanelsStayOpenExample">

                    <div class="accordion-item rounded-0 border-top-0">
                      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                          Opportunity type
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                            <div>
                                <span class="rounded-pill bg-primary px-3 py-1 small text-white pointer">APP MET</span>
                                <span class="rounded-pill border border-primary px-3 py-1 small text-primary pointer">APP SET</span>
                            </div>
                        </div>
                      </div>
                    </div>


                    <div class="accordion-item">
                      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                          Status
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse " aria-labelledby="panelsStayOpen-headingTwo">
                        <div class="accordion-body">
                            <div>
                                <div class="form-check mb-3">
                                    {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"> */}
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Select all
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Appointment MET
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Appointment SET
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"> */}
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Pending
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"> */}
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Signed
                                    </label>
                                </div>
                                <div class="form-check mb-3">
                                    {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"> */}
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Closed
                                    </label>
                                </div>
                           </div>
                        </div>
                      </div>
                      
                    </div>

             

                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                           Date Range
                          </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                          <div class="accordion-body">

                            <div class="d-flex align-items-end flex-nowrap input-group mb-3">
                               
                                <div>
                                    <label class="small">Start date</label>
                                    {/* <input class="form-control rounded-0 search-fgy dy-filt" type="date" placeholder="start date"> */}
                                </div>
                                
        
                                {/* <hr class="col-1 mb-4 my-auto"> */}
        
                                <div>
                                    <label class="small">End date</label>
                                    {/* <input class="form-control search-fgy dy-filt rounded-0" type="date" placeholder="end date"> */}
                                </div>
        
                                
        
                                {/* <div class="btn btn-primary fs-6 d-flex align-items-center justify-content-center search-fgy"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" class="iconify" data-icon="mdi:calendar" style="vertical-align: -0.125em; transform: rotate(360deg);"><path fill="currentColor" d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V1m-1 11h-5v5h5z"></path></svg></div> */}
                            </div>
  
                              
  
                          </div>
                        </div>
                    </div>


                </div>
                
            </div>


            <div class="modal-footer">
                
                <button type="button" class="btn btn-primary-outline" data-dismiss="modal" onClick={() => setShowFilter(false)}>Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
            
          </div>
        </div>
    </div>
          )}
          {showCustomise && (
            // <div class="modal show fade" id="opp-customise" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
            // <div class="modal-dialog modald-md w-100 modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0">
            <div class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
               <div class="modal-dialog modald-md w-100 modal-fullscreen-sm-down modal-dialog-scrollable h-100 mt-0 me-0 mb-0">
    
              <div class="modal-content rounded-0 h-100">
    
                <div class="modal-header px-4 rounded-0" >
                    <div class="d-flex justify-content-center align-items-center pointer">
                        <i class='iconify fs-5 me-2' data-icon="mdi:table"></i>
                        <h5 class="modal-title fs-6">Customise Table</h5>
                    </div>
                    
                    <button type="button" class="btn-close small" data-bs-dismiss="modal" aria-label="Close"  onClick={() => setShowCustomise(false)}></button>
                </div>
    
    
                <div class="modal-body p-0 p-4">
                    <p>Select the Columns you want to see</p>
    
                    <div>
                        
                        <div class="form-check d-flex align-items-center border-top py-3 mb-3">
                            {/* <input class="form-check-input me-2 my-0" type="checkbox" value="" id="flexCheckDefault5" checked> */}
                            <label class="form-check-label fs-6 m-0" for="flexCheckDefault5">
                                ID
                            </label>
                            <i class='iconify fs-5 ms-auto text-secondary pointer' data-icon="codicon:gripper"></i>
                        </div>
                        <div class="form-check d-flex align-items-center border-top py-3 mb-3">
                            {/* <input class="form-check-input me-2 my-0" type="checkbox" value="" id="flexCheckDefault5" checked> */}
                            <label class="form-check-label fs-6 m-0" for="flexCheckDefault5">
                               Created
                            </label>
                            <i class='iconify fs-5 ms-auto text-secondary pointer' data-icon="codicon:gripper"></i>
                        </div>
                        <div class="form-check d-flex align-items-center border-top py-3 mb-3">
                            {/* <input class="form-check-input me-2 my-0" type="checkbox" value="" id="flexCheckDefault5" checked> */}
                            <label class="form-check-label fs-6 m-0" for="flexCheckDefault5">
                                Agent
                            </label>
                            <i class='iconify fs-5 ms-auto text-secondary pointer' data-icon="codicon:gripper"></i>
                        </div>
                        <div class="form-check d-flex align-items-center border-top py-3 mb-3">
                            {/* <input class="form-check-input me-2 my-0" type="checkbox" value="" id="flexCheckDefault5" checked> */}
                            <label class="form-check-label fs-6 m-0" for="flexCheckDefault5">
                                Stage
                            </label>
                            <i class='iconify fs-5 ms-auto text-secondary pointer' data-icon="codicon:gripper"></i>
                        </div>
                        <div class="form-check d-flex align-items-center border-top py-3 mb-3">
                            {/* <input class="form-check-input me-2 my-0" type="checkbox" value="" id="flexCheckDefault5" checked> */}
                            <label class="form-check-label fs-6 m-0" for="flexCheckDefault5">
                                Tags
                            </label>
                            <i class='iconify fs-5 ms-auto text-secondary pointer' data-icon="codicon:gripper"></i>
                        </div>
                        <div class="form-check d-flex align-items-center border-top py-3 mb-3">
                            {/* <input class="form-check-input me-2 my-0" type="checkbox" value="" id="flexCheckDefault5" checked> */}
                            <label class="form-check-label fs-6 m-0" for="flexCheckDefault5">
                                Splits 
                            </label>
                            <i class='iconify fs-5 ms-auto text-secondary pointer' data-icon="codicon:gripper"></i>
                        </div>
                        <div class="form-check d-flex align-items-center border-top py-3 mb-3">
                            {/* <input class="form-check-input me-2 my-0" type="checkbox" value="" id="flexCheckDefault5" checked> */}
                            <label class="form-check-label fs-6 m-0" for="flexCheckDefault5">
                                Payments
                            </label>
                            <i class='iconify fs-5 ms-auto text-secondary pointer' data-icon="codicon:gripper"></i>
                        </div>
                   </div>
    
    
                </div>
    
    
                <div class="modal-footer">
                    
                    <button type="button" class="btn btn-primary-outline" data-dismiss="modal">Clear</button>
                    <button type="button" class="btn btn-primary">Apply Filter</button>
                </div>
                
              </div>
            </div>
        </div>
           ) }
            <h5 class="m-0 fs-5 fw-bold mb-3">Opportunity list</h5>
            <p class="text-secondary mt-2">View and edit all your opportunity list and customize your data. To learn more about creating opportunity list click here</p>

            <div className="d-md-flex align-items-center">
              <div className="col-md-6 col-lg-7 col-xxl-4">
                <label className="small">Search</label>
                <input
                  className="form-control input-bn search-fgy mb-3"
                  placeholder="search opportunities"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div class="d-flex gap-4 ps-4">
              <div className="d-flex justify-content-center align-items-center pointer" onClick={() =>  setShowFilter(true)}>
              <i className='iconify fs-5' data-icon="mdi:filter"></i>
              <span className="text-decoration-underline">Filter</span>
            </div>
                <div class="vr border"></div>
                <div class="d-flex justify-content-center align-items-center pointer">
                  <i class='iconify fs-5' data-icon="mdi:table"></i>
                  <span class="text-decoration-underline">Table</span>
                </div>
                <div class="vr border"></div>

                <div class="d-flex justify-content-center align-items-center pointer">
                  <i class='iconify fs-5' data-icon="mdi:wrench"></i>
                  <span class="text-decoration-underline" onClick={() =>  setShowCustomise(true)}>Customise</span>
                </div>
              </div>
            </div>
            {/* /test */}
    
            {/* test */}

            {/* modal */}
            
            <div class="row row-cols-3 py-5">

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Counts</span>
                  <h2>{opps?.Opps?.length}</h2>
                  <span class="small text-secondary">{opps?.Opps?.length} after split Counts</span>

                </div>
              </div>

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Total Volume</span>
                  <h2>$ {currencyConvert(totalOppPrice)}</h2>
                  <span class="small text-secondary">{totalOppPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>

              <div>
                <div class="bg-white border rounded-3 py-3 px-4 text-center">

                  <span class="text-primary fw-bold">Total CGI</span>
                  <h2>$ {currencyConvert(totalCgi)}</h2>
                  <span class="small text-secondary">{totalCgi.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>
            </div>
            <div class="table-responsive opp-table pe-1">
              <table class="table">
                <thead>
                  <tr>
                    {/* <th scope="col"><input class="form-check-input" type="checkbox"></th> */}
                    <th scope="col">Action</th>

                    {/* ‘opp_stage’, ‘opp_agreement_signed_date’, ‘opp_type’, ‘opp_appt_date’, ‘opp_appt_met_date’, ‘fub_person_id’, ‘opp_price’, ‘sisu_client_id’, ‘opp_agreement_expiration_date’, ‘opp_commission_percent’, ‘opp_postal_code’, ‘opp_last_name’, ‘opp_state’, ‘opp_forecasted_close_date’, ‘opp_under_contract_date’, ‘opp_settlement_date’, */}

                  
                    <th scope="col">Opp Stage</th>
                    <th scope="col">Opp Agreement Signed Date</th>
                    <th scope="col">Opp Type</th>
                    <th scope="col">Opp Appt Date</th>
                    <th scope="col">Opp Appt Met Date</th>
                    <th scope="col">Fub Person ID</th>
                    <th scope="col">Opp Price</th>
                    <th scope="col">Sisu Client ID</th>
                    <th scope="col">Opp Agreement Expiration Date</th>
                    <th scope="col">Opp Commission Percent</th>
                    <th scope="col">Opp Postal Code</th>
                    <th scope="col">Opp Last Name</th>
                    <th scope="col">Opp State</th>
                    <th scope="col">Opp Forecasted Close Date</th>
                    <th scope="col">Opp Under Contract Date</th>
                    <th scope="col">Opp Settlement Date</th>


                  </tr>
                </thead>
                <tbody>
                  {
                    filteredOpps.map(
                      (opp, index) => (
                        (
                          <tr>

                            <td>
                              <div className="dropdown">
                                <i className="iconify fs-5 pointer dropdown-toggle" data-bs-toggle="dropdown" data-icon="dashicons:ellipsis" aria-expanded="false"></i>
                                <ul className="dropdown-menu" aria-labelledby="ellipsisDropdown">
                                  <li>
                                    <span className="dropdown-item" onClick={() => handleEntryView(opp.opp_key)}>View / Edit</span>
                                  </li>
                                  <li>
                                    <span className="dropdown-item">Delete</span>
                                  </li>
                                </ul>
                              </div>
                            </td>

                            {/* <td> <i class='iconify fs-5 pointer' data-icon="dashicons:ellipsis"></i></td> */}
                            <td>{opp?.opp_stage}</td>
                            <td>{opp?.opp_agreement_signed_date}</td>
                            <td>{opp?.opp_type}</td>
                            <td>{opp?.opp_appt_date}</td>
                            <td>{opp?.opp_appt_met_date}</td>
                            <td>{opp?.fub_person_id}</td>
                            <td>{opp?.opp_price}</td>
                            <td>{opp?.sisu_client_id}</td>
                            <td>{opp?.opp_agreement_expiration_date}</td>
                            <td>{opp?.opp_commission_percent}</td>
                            <td>{opp?.opp_postal_code}</td>
                            <td>{opp?.opp_last_name}</td>
                            <td>{opp?.opp_state}</td>
                            <td>{opp?.opp_forecasted_close_date}</td>
                            <td>{opp?.opp_under_contract_date}</td>
                            <td>{opp?.opp_settlement_date}</td>

                          </tr>
                        )
                      )
                    )
                  }


                </tbody>
              </table>

              <div></div>
            </div>
          </section>
           {/* Modal */}
          
            
        </section>
      </div>
   
    </>
  );
}
