import { useEffect } from "react";
import Elements from "../../../components/PreviewElem";
// import { Elements } from "../dashboard/CustomElement";
export const BoardElemForPre = ({ element, Error, oppData,showHide,setElementVisiblity ,previewFlag}) => {
  console.log("BoardElemForPre",element,oppData )
  // //console.log("showHide",showHide)
  const elem = Elements.find((e) => e.id === element.type);
  //  //console.log("elem",elem)
  const Message = Error?.find((item) => {
    if (item[element.elementid]) {
      return item[element.elementid];
    }
  });
  // var result = element.elementid.substr(0, element.elementid.indexOf('-'));
  var inputString = element?.elementid;

// Find the index of the first dash
var indexOfFirstDash = inputString.indexOf('-');

// Extract the substring from the first dash onward
var modifiedString = inputString.slice(indexOfFirstDash + 1);





  console.log("OppData",element.linked_to)
//const oppFields=oppData?.opportunityData[element?.linked_to] === undefined ? null : oppData?.opportunityData[element.linked_to]
// let oppFields=null
//  if(oppData?.opportunityData[element.linked_to] === undefined){
//    oppFields=null
//   }else{
//     console.log("OppData",modifiedString)
//     // oppFields=oppData?.opportunityData[element.linked_to]
//   }


  console.log("Opp>>",oppData)
  // console.log("Message",modifiedString,)
  // const oppFields=Object?.keys(oppData?.opportunityData)?.map((key)=>key==element.elementid)
  // console.log("oppFields",oppFields)
  // Object.keys(Error).map((key)=>key==element.elementid)
  ////console.log("Message",Message)

  const FieldIDFinderFuntion=()=>{
    var inputString = element?.elementid;
    var indexOfFirstDash = inputString.indexOf('-');
    var modifiedString = inputString.slice(indexOfFirstDash + 1);
   return Object.keys(oppData?.opportunityData)?.map((key)=>key==modifiedString)
  }
  // console.log("FieldIDFinderFuntion",FieldIDFinderFuntion())
  const UI = elem.ui;

  return (
    <UI
      elementid={element.elementid}
      element={element}
      message={Message}
      oppFields={oppData?.opportunity?.[element.linked_to]}
      showHide={showHide}
      setElementVisiblity={setElementVisiblity}
      previewFlag={previewFlag}
    />
  );
};
