import React, { useRef, useState ,useContext, useEffect} from "react";
import Validation from "../../../utils/Validation";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { ErrorResult, boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { useForm,Controller ,useFormContext} from "react-hook-form";
import { useLocation } from "react-router-dom";


export const NumberFieldUi = ({ elementid,message={}, oppFields,PreviewData, showHide,previewFlag = false}) => {
  const [errorMessage, seterrorMessage] = useState({});
  const Numberref = useRef(null);
const {board,setboard}=useContext(boardConetxt)
const ElementSetting = board.find(
  (item) => item?.elementid === elementid
).settings;
const {
  placeHolder,
  inputType,
  instructions,
  minValue,
  maxValue,
  predefine,
  required,
  hidden,
} = ElementSetting;
console.log("predefine",required)
const initStyle = {
  display: showHide === "show" ? "none" : "block",
};
const {register,formState:{errors},control,setValue} = useFormContext({
  mode: "onFocus",
  reValidateMode: "onChange",
});
const [copy,setCopy]=useState(false);

const Location=useLocation();
const params=new URLSearchParams(Location.search);

useEffect(()=>{
  if(oppFields){
    setValue(elementid,oppFields)
  } 
},[oppFields])

// useEffect(()=>{
//   if(elementid,params.get(elementid.replace(/-\d+$/, ''))){
//     setValue(elementid,elementid,params.get(elementid.replace(/-\d+$/, '')))
//   } 

// },[elementid,params.get(elementid.replace(/-\d+$/, ''))])
useEffect(()=>{
  setValue(elementid,predefine)
},[predefine])
////console.log("Val",Numberref.current?.value)
//   const inputValidationResult = (e) => {
//     e.preventDefault();
//     let elementData = {};
//     elementData.number = Numberref.current.value;
//     let validation = Validation(elementData,ElementSetting);
//     let { numberChecks } = validation.validateNumberField();
   
//     seterrorMessage(numberChecks);
//     const newboard = board.map((product) => {
//       if (product.elementid === elementid) {
//         product.settings.predefine = Numberref.current?.value;
//       }
//       return product;
//     });
//     setboard(newboard);
//   };
// useEffect(()=>{
//   let elementData = {};
//   elementData.number = predefine !== "" ? predefine : Numberref.current?.value;
//   let validation = Validation(elementData,ElementSetting);
//   let { numberChecks } = validation.validateNumberField();
  
//   seterrorMessage(numberChecks);

// },[predefine])

// useEffect(() => {
//   if (message[elementid]?.result === false) {
//     seterrorMessage(message);
//   }
// }, [message]);
//   ////console.log(+maxValue > 13 ? maxValue : 13)
const label=board.find(
  (item) => item?.elementid === elementid
).name;
//console.log("Setting",ElementSetting)
useEffect(()=>{
  if(elementid.includes("copy")){
      setCopy(true)
  }
},[])
  return (
    <div className={previewFlag && GetStyleClasses(elementid) ||  ""}
    id={elementid} style={initStyle} hidden={hidden && previewFlag}>
    <div className="form-floating w-100 form-group">
      {/* <input
        placeholder="Enter Number"
        className="form-control input-bn"
        type={inputType !=="" ? inputType : "Number"}
        
        maxLength={maxValue <= 13 ? maxValue : "13"}
        minLength={minValue ? minValue : 1}
        name="number"
        id={elementid}
        ref={Numberref}
        defaultValue={predefine ? predefine :Numberref.current?.value}
        onChange={(e) => {
          inputValidationResult(e);
        }}
      /> */}
      {Object.keys(ElementSetting).length === 0  ? (
         <Controller
         control={control}
         name={elementid}
         rules={
           {
            
             maxLength: {
               value: 13,
               message: `Please Enter less then 13 Characters`
             },
             pattern: {
               value: /^[0-9]*$/,
               message: "Please Enter Number Only",
             },
 
         }}
         render={({ field }) => (
           <input
           type= "number" 
           className="form-control input-bn"
           placeholder="Enter Number"
          //  defaultValue={predefine=== " " ? "" : ""}
           {...field}
           />
         )}
       />
 
      ) : ( <Controller
        control={control}
        name={elementid}
        rules={
          {
            required: {
              value: true,
              message: "This Field is Required",
            },
            minLength: {
              value: minValue == " " ? 1 : minValue,
              message: `Please Enter more then ${minValue == "" ? 1 : minValue} Characters`
            },
            maxLength: {
              value: maxValue !== "" ?  maxValue :13,
              message: `Please Enter less then ${maxValue !== "" ?  maxValue :13} Characters`
            },
            pattern: {
              value: /^[0-9]*$/,
              message: "Please Enter Number Only",
            },

        }}
        render={({ field }) => (
          <input
          type={inputType ==undefined ? "number" : inputType}
          className="form-control input-bn"
          placeholder="Enter Number"
          defaultValue={predefine=== " " ? null : predefine}
          {...field}
          />
        )}
      />
)}
     

     {/* {ElementSetting ? ( <input
        type="number"
        className="form-control input-bn"
        placeholder="Enter Number"
        {...register(elementid, {
        
          const handelCopy=(e)=>{
    e.preventDefault();
    const newboard = board.map((product) => {
      if (product.elementid === elementid) {
        product.elementid = elementid + "-copy";
      }
      return product;
    });
    console
    // setboard(newboard);
  }
         
          maxLength: {
            value: 13,
            message: `Please Enter less then 13 Characters`
          },
          pattern: {
            value: /^[0-9]*$/,
            message: "Please Enter Number Only",
          },
        })}
        />) :( <input
        type={inputType ==undefined ? "number" : inputType}
        className="form-control input-bn"
        placeholder="Enter Number"
        defaultValue={predefine=== " " ? "" : predefine}
        {...register(elementid, {
          required: {
            value: required === undefined ? false : required,
            message: "This Field is Required",
          },
          minLength: {
            value: minValue == " " ? 1 : minValue,
            message: `Please Enter more then ${minValue == "" ? 1 : minValue} Characters`
          },
          maxLength: {
            value: maxValue !== "" ?  maxValue :13,
            message: `Please Enter less then ${maxValue !== "" ?  maxValue :13} Characters`
          },
          pattern: {
            value: /^[0-9]*$/,
            message: "Please Enter Number Only",
          },
        })}
        />)} */}
      <label>
        <span className="d-flex align-items-center">
        {/* {placeHolder ? placeHolder : label} */}
        {copy ? placeHolder == undefined ? label : placeHolder+"-copy": placeHolder ? placeHolder : label}

              {required ? <span style={{ color: "red" }}>*</span> : ""}
        </span>
      </label>

    </div>
    <small className="text-secondary">{instructions}</small>
    <div className="d-flex flex-column gap-2">
      {errors && (
        <span className="text-danger">
          {errors[elementid]?.message}
        </span>
      )}
        </div>

      {/* placeholder for errors */}
      {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
    </div>
  );
};
