import React from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { useContext, useState } from "react";
import ConditionRuleStateUi from "./ConditionRuleStateUi";
import { useEffect } from "react";
export function ConditionRuleUi({
  rulesArr,
  rule,
  conditionIndex,
  setcondition,
  condition,
  addCondition,
  removeCondition,
  handleChange,
  removeRule,
  addAndRule,
  addOrRule,
  handleDataTypeChange,
  selectedElementId,
}) {
  const { board, setboard } = useContext(boardConetxt);

  const [operator, setoperator] = useState("equals");

  let data_type = "";
  console.log("rule>", board);
  return (
    <>
      {rule?.map(
        (item, Ruleindex) => (
          (data_type = item.data_type),
          console.log("ruleitem", item),
          (
            <div
              id={Ruleindex}
              key={Ruleindex}
              className="bg-white d-flex flex-wrap justify-content-between align-items-center p-4 px-xl-5 py-4 rounded-3 border position-relative"
            >
              <span onClick={() => removeRule(conditionIndex, Ruleindex)}>
                <i
                  className="iconify text-secondary fs-6 fm-condi-close"
                  data-icon="mdi:close-circle"
                />
              </span>
              <div className="w-100">
                <div className="mt-0">
                  <div className="d-sm-flex aligm-items-center mb-4">
                    <label className="col-1 me-3 fw-bold d-flex align-items-center">
                      <span className=" mb-2 mb-md-0 d-flex align-items-center">
                        <span>IF</span>
                      </span>
                    </label>
                    <select
                      className="form-select input-bn"
                      name="field_id"
                      value={item.field_id}
                      required
                      onChange={(e) => {
                        handleChange(e, Ruleindex, conditionIndex, true);
                        handleDataTypeChange(e, conditionIndex, Ruleindex);
                      }}
                    >
                      {/* <option value="">Please select a Option</option> */}
                      {board?.map((field, key) => (
                        <option
                          value={field.elementid}
                          data-fieldtype={field.type}
                          key={key}
                        >
                          {field.settings.placeHolder || field.settings.label ||field.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-100">
                <div className="mt-0">
                  <div className="d-sm-flex aligm-items-center mb-4">
                    <label className="col-1 me-3 fw-bold d-flex align-items-center">
                      <span className=" mb-2 mb-md-0 d-flex align-items-center">
                        <span>STATE</span>
                      </span>
                    </label>
                    <select
                      className="form-select input-bn"
                      name="operator"
                      required
                      onChange={(e) =>
                        handleChange(e, Ruleindex, conditionIndex)
                      }
                      value={item.operator}
                    >
                      {/* <option value="">Please select a Option</option> */}
                      <option value="equals">equals</option>
                      <option value="not_equals">is not</option>
                      <option value="in">contains</option>
                      <option value="not_in">does not contain</option>
                      <option value="startswith">begins with</option>
                      <option value="endswith">ends with</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="w-100">
                <div className="mt-0">
                  <div className="d-sm-flex aligm-items-center mb-4">
                    <label className="col-1 me-3 fw-bold d-flex align-items-center">
                      <span className=" mb-2 mb-md-0 d-flex align-items-center">
                        <span>VALUE</span>
                      </span>
                    </label>

                    <ConditionRuleStateUi
                      data_type={data_type}
                      board={board}
                      handleChange={handleChange}
                      Ruleindex={Ruleindex}
                      conditionIndex={conditionIndex}
                      item={item}
                      selectedElementId={rule[Ruleindex].field_id.replace(
                        /[{}]/g,
                        ""
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        )
      )}
      {
        <>
          <input
            type="button"
            className="btn-check"
            name="and_or"
            id={conditionIndex}
          />
          <label
            className="btn btn-primary-outline small"
            htmlFor="and_select"
            onClick={() => addAndRule(conditionIndex)}
          >
            AND
          </label>
          <hr />
        </>
      }
    </>
  );
}
