import React, { useContext } from 'react'
import { ElementsSettingsWidget } from './ElementsSettingsWidget'
import { boardConetxt } from '../../../pages/form-builder/dashboard/ContextHelper'
import { BoardDeleteHandler } from '../../BoardHandlerFunctions';
import { DateUi } from './DateUi';

export const DndDateUi = ({elementid,element,oppFields}) => {
    const {board,setboard}=useContext(boardConetxt);
  return (
    <div elementid={elementid}>
        <ElementsSettingsWidget
        BoardDeleteHandler={BoardDeleteHandler}
        elementid={elementid}
        board={board}
        setboard={setboard}
        element={element}
        />
        <DateUi elementid={elementid} oppFields={oppFields} />
    </div>
  )
}
