import { ConditionRuleUi } from "./ConditionRuleUi";
import React, { useContext, useState, useEffect } from "react";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import cloneDeep from "lodash/cloneDeep";

function AddConditions({
  setInitCondition,
  initCondition,
  condition,
  setCondition,
  conditionsIndex,
}) {
  const { board, setboard } = useContext(boardConetxt);

  const [selectedElementId, setSelectedElementId] = useState("");

  const saveCondition = () => {
    setCondition((prev) => {
      console.log("prev", prev);
      let newCondition = cloneDeep([...prev]);
      if (condition[conditionsIndex] !== undefined) {
        console.log("condition[conditionsIndex]", condition[conditionsIndex]);
        newCondition[conditionsIndex].condtions = initCondition.condtions; // Fixed typo "condtions" to "conditions"
      } else {
        newCondition.push(initCondition);
      }
      return newCondition;
    });
  };

  // const removeCondition = (index) => {
  //   const values = [...initCondition];
  //   values.splice(index, 1);
  //   setInitCondition(values);
  // };

  const handleChange = (e, index, conditionIndex, valueif = false) => {
    const { name, value } = e.target;
    console.log("name", name, "value", value);
    const values = cloneDeep(initCondition);
    values.condtions[conditionIndex][index][name] = valueif ? value : value;
    console.log("values", values);
    setInitCondition(() => values);
    saveCondition();
  };
  const addAndRule = (index) => {
    setInitCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.condtions[index].push({
        data_type: board?.[0]?.type ? board[0].type.toLowerCase() : "",
        expected_value: "",
        operator: "equals",
        field_id: board?.[0]?.elementid ? board[0].elementid : "",
      });
      return newCondition;
    });

    saveCondition();
  };

  const addOrRule = (index) => {
    setInitCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.condtions.push([
        {
          data_type: board?.[0]?.type ? board[0].type.toLowerCase() : "",
          expected_value: "",
          operator: "equals",
          field_id: board?.[0]?.elementid ? board[0].elementid : "",
        },
      ]);

      return newCondition;
    });

    saveCondition();
  };

  const removeRule = (conditionIndex, index) => {
    let values = cloneDeep(initCondition);
    values.condtions[conditionIndex].splice(index, 1);
    if (values.condtions[conditionIndex].length === 0) {
      values.condtions.splice(conditionIndex, 1);
    }
    setInitCondition(() => values);

    saveCondition();
  };

  const addField = () => {
    setInitCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.Fields.push(board?.[0]?.elementid ? board[0].elementid : "");
      return newCondition;
    });
    saveCondition();
  };

  const handleFieldChange = (e, index) => {
    const { value } = e.target;
    console.log("value", value);
    setInitCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.Fields[index] = value;
      return newCondition;
    });
    saveCondition();
  };

  const removeField = (index) => {
    setInitCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.Fields.splice(index, 1);
      return newCondition;
    });
    saveCondition();
  };

  const handelVisibilityChange = (e) => {
    setInitCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.Visibility = e.target.value;
      return newCondition;
    });
    saveCondition();
  };

  const handleDataTypeChange = (e, conditionIndex, index) => {
    console.log(conditionIndex, index);
    var selectedIndex = e.target.selectedIndex;
    var optionElement = e.target.childNodes[selectedIndex];
    var option = optionElement.getAttribute("data-fieldtype");
    console.log("option", option);
    setInitCondition((prev) => {
      let newCondition = cloneDeep(prev);
      newCondition.condtions[conditionIndex][index].data_type =
        option.toLowerCase();
      newCondition.condtions[conditionIndex][index].expected_value = "";
      return newCondition;
    });

    setSelectedElementId(() => e.target.value);
    console.log("*****selectedElementId****", e.target.value);
    saveCondition();
  };

  useEffect(() => {
    initCondition !== undefined
      ? saveCondition()
      : console.log("initCondition is undefined");
  }, [initCondition]);

  console.log("rerendered", initCondition);

  return (
    <>
      {initCondition?.condtions?.map((rule, index) => (
        <ConditionRuleUi
          rule={rule}
          conditionIndex={index}
          setCondition={setInitCondition}
          condition={initCondition}
          // removeCondition={removeCondition}
          handleChange={handleChange}
          removeRule={removeRule}
          addAndRule={addAndRule}
          addOrRule={addOrRule}
          handleDataTypeChange={handleDataTypeChange}
          selectedElementId={selectedElementId}
          key={index}
        />
      ))}

      <input
        type="button"
        className="btn-check"
        name="and_or"
        id="or_select"
        autoComplete="off"
        onClick={() => addOrRule()}
      />
      <label className="btn btn-primary-outline small" htmlFor="or_select">
        OR
      </label>

      <div>
        <div className="d-flex py-2 text">
          <div
            className="iconify fs-1 text-primary"
            data-icon="gg:arrow-long-down-r"
          />
        </div>
        <div className="bg-white dot-border border-primary border-2 d-flex flex-wrap justify-content-between align-items-center p-4 px-xl-5 py-4 mb-3 rounded-3 border">
          <div className="w-100">
            <div className="mt-0">
              <div className="d-sm-flex aligm-items-center mb-4">
                <label className="col-1 me-3 fw-bold d-flex align-items-center">
                  <span className=" mb-2 mb-md-0 d-flex align-items-center">
                    <span>THEN</span>
                  </span>
                </label>
                <select
                  className="form-select input-bn"
                  onChange={(e) => handelVisibilityChange(e)}
                  required
                >
                  <option value="show">show</option>
                  <option value="hide">hide</option>
                </select>
              </div>
            </div>
          </div>
          <div className="w-100">
            <div className="mt-0">
              <div className="d-sm-flex aligm-items-center mb-4">
                <label className="col-1 me-3 fw-bold d-flex align-items-center">
                  <span className=" mb-2 mb-md-0 d-flex align-items-center">
                    <span>Fields</span>
                  </span>
                </label>
                <div className="d-flex flex-column w-100">
                  {initCondition?.Fields?.map(
                    (fields, index) =>
                      fields !== "" && (
                        <div
                          className="d-flex flex-row align-items-center"
                          key={index}
                        >
                          <select
                            className="form-select input-bn flex-fill mb-1"
                            id={index}
                            defaultValue={fields}
                            onChange={(e) => {
                              handleFieldChange(e, index);
                            }}
                          >
                            {/* <option value="">Please Select a Field</option> */}
                            {board?.map((boardField, boardkey) => (
                              <option
                                value={boardField.elementid}
                                key={boardkey}
                              >
                                {boardField.settings.placeHolder || boardField.settings.label ||
                                  boardField.name}
                              </option>
                            ))}
                          </select>
                          <div className="py-2 fm-widget">
                            <span onClick={() => removeField(index)}>
                              <i
                                className="iconify mx-1 fs-6 text-danger"
                                data-icon="bx:trash"
                                id={index}
                              />
                            </span>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>

              <div className="w-100 d-flex justify-content-end position-relative">
                <div
                  className="border shadow-sm bg-white text-primary rounded-0 rounded-end rounded-pill btn accordion-flush"
                  onClick={() => addField()}
                >
                  <i className="fs-4 iconify" data-icon="mdi:plus" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddConditions;
