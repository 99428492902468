import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import { gql, useQuery, useMutation } from "@apollo/client";
import { Loaderr } from "./components/Loaderr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFormApiHelper from "./utils/useFormApiHelper";

// Reduce the parameters which are not required in the query

// const DeleteForm = gql`
//   mutation DeleteForm($formId: String) {
//     DeleteForm(form_id: $formId) {
//       form_id
//       team_id
//     }
//   }
// `;

// const UpdateForm = gql`
//   mutation UpdateFormName($formId: String, $formName: String) {
//     UpdateFormName(form_id: $formId, form_name: $formName) {
//       team_id
//       form_id
//     }
//   }
// `;

const FormDetails = ({
  formId,
  formName,
  form,
  setform,
  base_form_template,
  form_type,
}) => {
  const navigate = useNavigate();
  const {
    DuplicateForm,
    CountFormEntries,
    GetFormEntries,
    UpdateFormName,
    DeleteForm,
  } = useFormApiHelper();
  const [word, setWord] = useState(formName);
  const [isHovered, setHovered] = useState(false);
  const { countFormEntriesInOneCallData } = CountFormEntries(formId);
  const { duplicateFormMutation, duplicateFormData } = DuplicateForm();
  const { data } = GetFormEntries(formId);
  const { UpdateFormData } = UpdateFormName();
  const { deleteForm } = DeleteForm();
  // const { loading, error, data } = useQuery(FormEntries, {
  //   variables: { formId },
  // });
console.log("duplicateFormData",duplicateFormData)
  // const entries = data?.formEntries || [];

  const handleClick = (id) => {
    if (index == null) {
      navigate(`build/${id}`);
    }
  };

  const handelEntryClick = () => {
    navigate(`entries/${formId}`, {
      state: { entries: data },
    });
  };
  // const [
  //   deleteForm,
  //   {
  //     loading: deleteFormLoading,
  //     error: deleteFormError,
  //     data: deleteFormData,
  //   },
  // ] = useMutation(DeleteForm, {});
  // const [
  //   UpdateName,
  //   {
  //     loading: UpdateNameLoading,
  //     error: UpdateNameError,
  //     data: UpdateNameData,
  //   },
  // ] = useMutation(UpdateForm, {});

  function copyTextz() {
    const copyText = "https://app.datalabz.re/forms/" + formId;

    const Myicon = <i className="iconify fs-5" data-icon="noto:clipboard"></i>;

    navigator.clipboard.writeText(copyText).then(() => {
      toast.success("copied to clipboard!" + copyText, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2500,
        icon: Myicon,
        hideProgressBar: true,
        pauseOnHover: false,
        closeOnClick: true,
        theme: "dark",
      });
      // alert("Copied to clipboard: " + copyText);
    });
  }

  const handleDuplicate = () => {
    duplicateFormMutation({
      variables: {
        formId: formId,
      },
    });
    setTimeout(() => {
      setform([...form, duplicateFormData?.DuplicateForm]);
    }, [2000]);
  };

  const [index, setIndex] = useState(null);
  const handleRename = (formId) => {
    setIndex(formId);
  };
  const handleChange = (e) => {
    setWord(e.target.value);
  };
  const handleSave = (e) => {
    e.preventDefault();
    UpdateFormData({
      variables: {
        formId: formId,
        formName: word,
      },
    });
    setIndex(null);
  };
  //console.log(">>dd", DeleteForm);
  const handleDelete = () => {
    //console.log(">>Run", formId);
    deleteForm({
      variables: {
        formId: formId,
      },
    });
    const newForm = form.filter((item) => item.form_id !== formId);
    setform(newForm);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setIndex(null);
    setWord(formName);
  };

  return (
    <div
      className="d-flex flex-wrap border rounded-3 bg-white fm-list-container mb-1 "
      key={formId}
    >
      <div
        className={`d-flex flex-wrap col-12 col-md-7 align-items-center p-4 border-0 border-end border your ${
          isHovered ? "hovered" : ""
        }`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <span
          className=" fs-6 p-0 m-0 text-capitalize pointer pint ml-2"
          onClick={(e) => {
            handleClick(formId);
          }}
        >
          {index === formId ? (
            <>
              <input
                type="text"
                className="text"
                style={{
                  border: "none",
                  outline: "none",
                  padding: "0",
                  margin: "0",
                }}
                value={word}
                onChange={handleChange}
              />
              <span
                onClick={(e) => {
                  handleSave(e);
                }}
                style={{ color: "green" }}
              >
                <i
                  className="iconify"
                  data-icon="typcn:tick"
                  width="25"
                  height="25"
                ></i>
              </span>
              <span
                onClick={(e) => {
                  handleCancel(e);
                }}
                style={{ color: "red" }}
              >
                <i
                  className="iconify"
                  data-icon="raphael:cross"
                  width="25"
                  height="25"
                ></i>
              </span>
            </>
          ) : (
            <>
              <span>{word}</span>
            </>
          )}
        </span>
        <span>
          {index == null && (
            <span
              className="icon"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleRename(formId);
              }}
            >
              <i
                className="iconify"
                data-icon="mdi:pencil"
                width="22"
                height="20"
              ></i>
            </span>
          )}
        </span>
        <span
          className="badge bg-secondary fs-small fw-normal rounded-pill"
          style={{ marginLeft: "auto" }}
        >
          {" "}
          {base_form_template || "Custom Form"}
        </span>

        <span
          className="badge bg-primary fs-small fw-normal rounded-pill"
          style={{ marginLeft: "5px" }}
        >
          {form_type}
        </span>
      </div>

      <div className="d-flex align-items-center justify-content-center p-4 fs-5 gap-1 col col-md-2 border-0 border-end border pointer pint">
        <b>{countFormEntriesInOneCallData?.countFormEntriesToday?.count}</b>
        <i className="iconify fs-5" data-icon="bx:list-ul" />
      </div>

      <div
        className="d-flex align-items-center justify-content-center p-4 fs-5 gap-1 col col-md-2 border-0 border-end border pointer pint"
        onClick={() => handelEntryClick()}
      >
        <b>{countFormEntriesInOneCallData?.countFormEntries.count}</b>
        <i className="iconify fs-5" data-icon="bx:list-ul" />
      </div>

      {/* <div className="d-flex align-items-center justify-content-center p-4 fs-5 gap-1 col col-md-2 border-0 border-end border">
        <b>{form_fields || 0}</b>
        <i className="iconify fs-35" data-icon="codicon:settings" />
      </div> */}

      {/* <div className="d-flex align-items-center justify-content-center p-4 fs-5 gap-1 col col-md-2 border-0 border-end border pointer pint" onClick={handelCondtionClick}>
        <b>{ condition || 0 }</b>
        <i className="iconify fs-5" data-icon="carbon:branch" />
      </div> */}

      <div className="d-flex align-items-center dropdown justify-content-center p-4 col col-md-1 btnn pointer">
        <div>
          <i
            className="iconify dropdown-toggle fs-3"
            data-icon="fe:elipsis-h"
            data-bs-toggle="dropdown"
          ></i>
          <ul className="dropdown-menu shadow-lg border-0 py-0">
            <li
              id={formId}
              onClick={(e) => {
                handleClick(formId);
              }}
            >
              <p className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                Edit
                <i className="iconify" data-icon="clarity:note-edit-line"></i>
              </p>
            </li>

            <li>
              <p className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                View entries
                <i className="iconify" data-icon="bx:list-ul"></i>
              </p>
            </li>

            <li
              onClick={() => {
                handleDuplicate(formId);
              }}
            >
              <p className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                Duplicate
                <i className="iconify" data-icon="fluent:copy-24-regular"></i>
              </p>
            </li>

            {/* <li>
              <a className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                Rename
                <i className="iconify" data-icon="fluent:rename-24-regular"></i>
              </a>
            </li> */}

            <NavLink to={`/forms/${formId}`} target="_blank">
              <li>
                <p className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                  Preview
                  <i className="iconify" data-icon="fluent:eye-24-regular"></i>
                </p>
              </li>
            </NavLink>

            <li className="" onClick={copyTextz}>
              <p className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                Copy Link
                <i className="iconify" data-icon="fluent:link-24-regular"></i>
              </p>
            </li>

            <li
              onClick={() => {
                handleDelete(formId);
              }}
            >
              <p className="dropdown-item mb-d2 py-3 d-flex justify-content-between align-items-center">
                Delete
                <i className="iconify" data-icon="clarity:trash-line"></i>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const FormList = () => {
  const [form, setform] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [duplicate, setDuplicate] = useState(false);
  const [postsPerPage] = useState(10);
  const { GetFormDataForFormList, FormCount } = useFormApiHelper();
  const navigate = useNavigate();
  const { getFormsLoading, getFormsData, fetchMore } = GetFormDataForFormList(
    currentPage,
    postsPerPage
  );
  const { countTotalFormsData } = FormCount();
  const count = countTotalFormsData?.countTotalForms?.count;
console.log("FormList",getFormsData)
  useEffect(() => {
    let forms = getFormsData?.forms;
    setform(forms);
  }, [getFormsData?.forms]);

  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  // console.log(">>NO Of Page", currentPage * postsPerPage - postsPerPage);
  for (let i = 1; i <= Math.ceil(count / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePageNumbers = async (number) => {
    paginate(number);
    fetchMore({
      variables: {
        offset: String(currentPage * postsPerPage - postsPerPage),
      },
    });
  };

  if (getFormsLoading) return <Loaderr />;

  return (
    <div>
      <ToastContainer />
      <div className="main fmc-main p-0">
        
        <section className="container-lg container-fluid pt-5">
        <div
          className="d-lg-none alert alert-warning banner-top p-3 py-2 mb-3 rounded-0 m-0"
          role="alert"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center my-2">
              <i className="bx bx-mobile fs-5"></i>
              <i className="bx bx-arrow-to-right me-2 fs-5"></i>
              <i className="bx bx-desktop me-2 fs-5"></i>
            </div>

            <span className="my-2">
              Please switch to desktop and optimize this view. Smaller screens
              are not efficient. To learn more click here
            </span>
          </div>
        </div>

          <div className="left">
            <button
              className="btn btn-primary float-end"
              onClick={() => navigate("create-form")}
            >
              Create New Form
            </button>
          </div>
          <h5>Forms and Integration</h5>
          <p className="text-secondary small mt-2">
            Customise forms and Stages with your own fields or use predefined
            inputs
          </p>

          {form?.length > 0 && (
            <>
              <section className="d-flex flex-column py-4 gap-3">
                <div className="d-md-flex flex-wrap border rounded-3 d-none fm-list-container-thead">
                  <div className="d-flex flex-wrap col-12 col-md-7 align-items-center justify-content-between pb-2 pt-4 py-md-3 px-4 border-0 border-end border">
                    <b className="p-0 m-0">Forms</b>
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                    <p className="p-0 m-0">Today</p>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                  Fields
                </div> */}
                  {/* <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                  Conditions
                </div> */}
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-2 border-0 border-end border">
                    <p className="p-0 m-0">Total Entries</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-4 col col-md-1"></div>
                </div>

                <section className="d-flex flex-column py-4 gap-3">
                  {form?.length > 0 &&
                    form.map((item) => (
                      <FormDetails
                        key={item?.form_id}
                        formId={item?.form_id}
                        base_form_template={item?.base_form_template}
                        formName={item?.form_name}
                        form_fields={item?.form_fields?.length}
                        form_type={item?.form_type}
                        setform={setform}
                        form={form}
                        duplicate={duplicate}
                        setDuplicate={setDuplicate}
                      />
                    ))}
                </section>
                <div className="d-flex justify-content-center">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination pagination-mb">
                      {pageNumbers.map((number) => (
                        <li key={number} className="page-item">
                          <a
                            onClick={() => handlePageNumbers(number)}
                            className="page-link px-3 text-dark"
                          >
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </section>
            </>
          )}

          {form === undefined && (
            <>
              <div className="center">
                <h1 className="text-secndary">No Forms Found</h1>
                <p>
                  To start your new forms entry please
                  <button
                    onClick={() => {
                      navigate("create-form");
                    }}
                  >
                    Click Here
                  </button>
                </p>
              </div>
            </>
          )}
        </section>
      </div>
    </div>
  );
};

export default FormList;
