import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";
import Opportunity from "../pages/form-builder/dashboard/Opportunity";
import { Navigate, useNavigate, useLocation } from "react-router-dom";

function useFormApiHelper() {
  // function GetCustomFields() {
  //   const GetCustomFields = gql`
  //     query GetCustomFields {
  //       GetCustomFields {
  //         field_id
  //         field_name
  //         field_description
  //         field_type
  //         settings
  //         linked_to
  //       }
  //     }
  //   `;
  //   const {
  //     loading: customFieldsLoading,
  //     error: customFieldsError,
  //     data: customFields,
  //   } = useQuery(GetCustomFields, {
  //     onError: (error) => {
  //       //console.log("error", error);
  //     },
  //   });
  //   let hey = "hey";
  //   return { customFieldsLoading, customFieldsError, customFields, hey };
  // }

  function GetFormData(params) {
    const FormData = gql`
      query FormData($formId: String) {
        formData(form_id: $formId) {
          form_id
          form_name
          form_description
          form_fields
          form_conditions
          status
          form_created_date
          form_updated_date
          update_by
          base_form_template
          color_scheme
          logo_url
          form_type
          team_id
          

        }
      }
    `;
    const {
      loading: formDataLoading,
      error: formDataError,
      data: formData,
    } = useQuery(FormData, {
      variables: { formId: params.id },
      onCompleted: (data) => {
        //console.log("data", data);
      },
    });
    //console.log("GetFormData", formData);
    return { formDataLoading, formDataError, formData };
  }

  function CreateFormEntry() {
    const CreateFormEntry = gql`
      mutation CreateFormEntry($formId: String, $formEntryData: GraphQLJSON) {
        CreateFormEntry(form_id: $formId, form_entry_data: $formEntryData) {
          form_id
          form_entry_id
          entry_created_date
        }
      }
    `;
    const [
      createFormEntry,
      {
        loading: createFormEntryLoading,
        error: createFormEntryError,
        data: createFormEntryData,
      },
    ] = useMutation(CreateFormEntry, {
      onCompleted: (data) => {
        //console.log("data", data);
      },
    });
    return {
      createFormEntry,
      createFormEntryLoading,
      createFormEntryError,
      createFormEntryData,
    };
  }
  function GetFormEntries(params,formId) {
    const id=params?.id || formId
    const FormEntries = gql`
      query FormEntries($formId: String) {
        formEntries(form_id: $formId) {
          form_entry_id
          form_id
          form_entry_data
          entry_created_date
        }
      }
    `;
    const {
      loading: formEntriesLoading,
      error: formEntriesError,
      data: formEntries,
    } = useQuery(FormEntries, {
      variables: { formId: id  },
    });
    //console.log("FormEntries", formEntries);
    return { formEntriesLoading, formEntriesError, formEntries };
  }
  function FormEntry(params) {
    const FormEntry = gql`
      query FormEntry($formEntryId: String) {
        formEntry(form_entry_id: $formEntryId) {
          form_entry_id
          form_id
          form_entry_data
          entry_created_date
        }
      }
    `;
    const {
      loading: formEntryLoading,
      error: formEntryError,
      data: formEntry,
    } = useQuery(FormEntry, {
      variables: { formEntryId: params.entryid },
    });
    //console.log("FormEntry", formEntry);
    return { formEntryLoading, formEntryError, formEntry };
  }
  function UpdateForm() {
    const UpdateForm = gql`
      mutation UpdateForm($formId: String, $formData: GraphQLJSON) {
        UpdateForm(form_id: $formId, form_data: $formData) {
          team_id
          form_id
        }
      }
    `;
    const [
      updateForm,
      {
        loading: updateFormLoading,
        error: updateFormError,
        data: updateFormData,
      },
    ] = useMutation(UpdateForm, {
      onCompleted: (data) => {},
    });
    return { updateForm, updateFormLoading, updateFormError, updateFormData };
  }



  function DeleteFormEntry() {
    const DeleteFormEntry = gql`
      mutation DeleteFormEntry($formEntryId: String, $formId: String) {
        DeleteFormEntry(form_entry_id: $formEntryId, form_id: $formId) {
          form_id
          form_entry_id
          entry_created_date
        }
      }
    `;
    const [
      deleteFormEntry,
      {
        loading: deleteFormEntryLoading,
        error: deleteFormEntryError,
        data: deleteFormEntryData,
      },
    ] = useMutation(DeleteFormEntry, {
      onCompleted: (data) => {
        //console.log("data", data);
      },
    });
    return {
      deleteFormEntry,
      deleteFormEntryLoading,
      deleteFormEntryError,
      deleteFormEntryData,
    };
  }
  function UpdateFormEntry() {
    const UpdateFormEntry = gql`
      mutation UpdateFormEntry(
        $formId: String
        $formEntryId: String
        $formEntryData: GraphQLJSON
      ) {
        UpdateFormEntry(
          form_id: $formId
          form_entry_id: $formEntryId
          form_entry_data: $formEntryData
        ) {
          form_id
          form_entry_id
          entry_created_date
        }
      }
    `;
    const [
      updateFormEntry,
      {
        loading: updateFormEntryLoading,
        error: updateFormEntryError,
        data: updateFormEntryData,
      },
    ] = useMutation(UpdateFormEntry, {
      onCompleted: (data) => {
        //console.log("data", data);
      },
    });
    return {
      updateFormEntry,
      updateFormEntryLoading,
      updateFormEntryError,
      updateFormEntryData,
    };
  }
  function Opps() {
    const Opps = gql`
      query Opps {
        Opps {
          opp_key
          sisu_client_updated_ts
          opp_updated_ts
          opp_stage
          opp_agreement_signed_date
          fub_deal_created_ts
          opp_type
          opp_appt_date
          fub_deal_stage_name
          opp_appt_met_date
          fub_person_id
          sisu_client_created_ts
          opp_created_ts
          fub_deal_id
          opp_appt_disposition
          teamFubDealId
          team
          fub_deal_entered_stage_ts
          sisu_client_id
          opp_assigned_osa
          opp_isa
          opp_notes
          opp_address
          opp_agreement_expiration_date
          appt_set_entry_id
          fub_appt_start_time
          fub_original_appt_start_time
          disp_text_wait_timestamp
          appt_set_lead_type
          appt_set_platform
          disp_text_original_wait_timestamp
          opp_address2
          opp_city
          opp_postal_code
          opp_last_name
          opp_state
          previous_opp_stage
          CreateEntryId
          pipeline_entry_id
          opp_forecasted_close_date
          opp_under_contract_date
          appt_form_id
          form_id_entry_id
          opp_settlement_date
          CreateFormId
          appt_outcome
          external_system_key_buyer
          otc_property_id
          external_system_key_seller
          FormId_EntryIds
          opp_price
          opp_commission_percent
          fub_appt_id
          custom_fields
        }
      }
    `;
    const {
      loading: oppsLoading,
      error: oppsError,
      data: opps,
    } = useQuery(Opps, {
      onCompleted: (data) => {
        console.log("data", data);
      },
    });
    return { oppsLoading, oppsError, opps };
  }
  function GetOppByOppId(oppKey) {
    const Opportunity = gql`
      query Opportunity($oppKey: String) {
        opportunity(opp_key: $oppKey) {
          opp_key
          sisu_client_updated_ts
          opp_updated_ts
          opp_stage
          opp_agreement_signed_date
          fub_deal_created_ts
          opp_type
          opp_appt_date
          fub_deal_stage_name
          opp_appt_met_date
          fub_person_id
          sisu_client_created_ts
          opp_created_ts
          fub_deal_id
          opp_appt_disposition
          teamFubDealId
          team
          fub_deal_entered_stage_ts
          sisu_client_id
          opp_assigned_osa
          opp_isa
          opp_notes
          opp_address
          opp_agreement_expiration_date
          appt_set_entry_id
          fub_appt_start_time
          fub_original_appt_start_time
          disp_text_wait_timestamp
          appt_set_lead_type
          appt_set_platform
          disp_text_original_wait_timestamp
          opp_address2
          opp_city
          opp_postal_code
          opp_last_name
          opp_state
          previous_opp_stage
          CreateEntryId
          pipeline_entry_id
          opp_forecasted_close_date
          opp_under_contract_date
          appt_form_id
          form_id_entry_id
          opp_settlement_date
          CreateFormId
          appt_outcome
          external_system_key_buyer
          otc_property_id
          external_system_key_seller
          FormId_EntryIds
          opp_price
          opp_commission_percent
          fub_appt_id
          custom_fields
        }
      }
    `;
    const { loading, error, data } = useQuery(Opportunity, {
      variables: { oppKey: oppKey },
    });
    return { loading, error, data };
  }

  function UpdateOpp() {
    const UpdateOpp = gql`mutation UpdateOpp($oppKey: String, $oppData: GraphQLJSON) {
    UpdateOpp(opp_key: $oppKey, opp_data: $oppData) {
      opp_key
      sisu_client_updated_ts
      opp_updated_ts
      opp_stage
      opp_agreement_signed_date
      fub_deal_created_ts
      opp_type
      opp_appt_date
      fub_deal_stage_name
      opp_appt_met_date
      fub_person_id
      sisu_client_created_ts
      opp_created_ts
      fub_deal_id
      opp_appt_disposition
      teamFubDealId
      team
      fub_deal_entered_stage_ts
      sisu_client_id
      opp_assigned_osa
      opp_isa
      opp_notes
      opp_address
      opp_agreement_expiration_date
      appt_set_entry_id
      fub_appt_start_time
      fub_original_appt_start_time
      disp_text_wait_timestamp
      appt_set_lead_type
      appt_set_platform
      disp_text_original_wait_timestamp
      opp_address2
      opp_city
      opp_postal_code
      opp_last_name
      opp_state
      previous_opp_stage
      CreateEntryId
      pipeline_entry_id
      opp_forecasted_close_date
      opp_under_contract_date
      appt_form_id
      form_id_entry_id
      opp_settlement_date
      CreateFormId
      appt_outcome
      external_system_key_buyer
      otc_property_id
      external_system_key_seller
      FormId_EntryIds
      opp_price
      opp_commission_percent
      fub_appt_id
      custom_fields

    }
  }`;
    const [
      updateOpp,
      { loading: updateOppLoading, error: updateOppError, data: updateOppData },
    ] = useMutation(UpdateOpp, {
      onCompleted: (data) => {
        console.log("data", data);
      },
    });
    return { updateOpp, updateOppLoading, updateOppError, updateOppData };
  }

  function DuplicateForm(){
  const duplicateForm = gql`
  mutation DuplicateForm($formId: String) {
    DuplicateForm(form_id: $formId) {
      form_id
      team_id
      form_name
      form_description
      form_fields
      form_conditions
      status
      form_created_date
      form_updated_date
      update_by
      base_form_template
      color_scheme
      logo_url
      form_type
    }
  }
`;
  const [
    duplicateFormMutation,
    {
      loading: duplicateFormLoading,
      error: duplicateFormError,
      data: duplicateFormData,
    },
  ] = useMutation(duplicateForm, {
    onCompleted: (data) => {
      //console.log("data", data);
    },

  });
return {duplicateFormMutation, duplicateFormLoading, duplicateFormError, duplicateFormData}
}

function UpdateFormName(){
  const UpdateForm = gql`
  mutation UpdateFormName($formId: String, $formName: String) {
    UpdateFormName(form_id: $formId, form_name: $formName) {
      team_id
      form_id
    }
  }
`;
const [
  UpdateFormData,
  {
    loading: UpdateNameLoading,
    error: UpdateNameError,
    data: UpdateNameData,
  },
] = useMutation(UpdateForm, {});

return {UpdateFormData, UpdateNameLoading, UpdateNameError, UpdateNameData}
}

function CreateFieldFuntion(){
  const createField= gql`
mutation CreateField($fieldData: GraphQLJSON) {
  CreateField(field_data: $fieldData) {
    field_id
  }
}`
const [createFieldMutation, { data: createFieldData, loading: createFieldLoading, error: createFieldError }] = useMutation(createField, {
  onCompleted: (data) => {
    // console.log("createFieldMutation", data);
  },
});
return {createFieldMutation, createFieldData, createFieldLoading, createFieldError}
}

function GetFormFields(){
  const FormFields=gql`
query GetFieldsByTeamId {
  GetFieldsByTeamId {
    field_id
    field_name
    field_type
    input_type
    field_sub_type
    settings
  }
}`
const {loading:loading1,error:error1,data:FormFieldsData,refetch}=useQuery(FormFields,{ 
})
return {FormFieldsData,loading1,error1,refetch}
}

function GetOppData(OppKey){
  const opportunityData = gql`
  query Opportunity($oppKey: String) {
    opportunity(opp_key: $oppKey) {
      opp_key
      sisu_client_updated_ts
      opp_updated_ts
      opp_stage
      opp_agreement_signed_date
      fub_deal_created_ts
      opp_type
      opp_appt_date
      fub_deal_stage_name
      opp_appt_met_date
      fub_person_id
      sisu_client_created_ts
      opp_created_ts
      fub_deal_id
      opp_appt_disposition
      teamFubDealId
      team
      fub_deal_entered_stage_ts
      sisu_client_id
      opp_assigned_osa
      opp_isa
      opp_notes
      opp_address
      opp_agreement_expiration_date
      appt_set_entry_id
      fub_appt_start_time
      fub_original_appt_start_time
      disp_text_wait_timestamp
      appt_set_lead_type
      appt_set_platform
      disp_text_original_wait_timestamp
      opp_address2
      opp_city
      opp_postal_code
      opp_last_name
      opp_state
      previous_opp_stage
      CreateEntryId
      pipeline_entry_id
      opp_forecasted_close_date
      opp_under_contract_date
      appt_form_id
      form_id_entry_id
      opp_settlement_date
      CreateFormId
      appt_outcome
      external_system_key_buyer
      otc_property_id
      external_system_key_seller
      FormId_EntryIds
      opp_price
      opp_commission_percent
      fub_appt_id
      form_entries {
        form_entry_id
        form_id
        form_entry_data
        entry_created_date
      }
    }
  }
`;
const {
  loading: oppLoading,
  error: oppsError,
  data: oppData,
} = useQuery(opportunityData, {
  variables: {
    oppKey: OppKey,
  },
});
return {oppLoading, oppsError, oppData}
}

function CreateOpp(){
  const navigate = useNavigate();
  const CreateOpportunity = gql`
mutation CreateOpp($form_id: String, $opp_data: GraphQLJSON) {
  CreateOpp(form_id: $form_id, opp_data: $opp_data) {
    opp_key
  }
}`;
const [createOpp, { loading: createOppLoading, error: createOppError,data:createOppdata }] =
useMutation(CreateOpportunity, {
  onCompleted: (data) => {
    console.log("OnSumbit", data);
    navigate("/thankyou");
  },
});
return {createOpp, createOppLoading, createOppError, createOppdata}
}

function UpdateOpp(){
  const navigate = useNavigate();
  const updateOpportunity = gql`
  mutation UpdateOpp(
    $form_id: String
    $opp_key: String
    $opp_data: GraphQLJSON
  ) {
    UpdateOpp(form_id: $form_id, opp_key: $opp_key, opp_data: $opp_data) {
      opp_key
    }
  }
`;
const [updateOpp, { loading: updateOppLoading, error: updateOppError,data:updateOppData }] =
useMutation(updateOpportunity, {
  onCompleted: (data) => {
    console.log("OnSumbit", data);
    navigate("/thankyou");
  },
});
return {updateOpp, updateOppLoading, updateOppError, updateOppData}
}

function CountFormEntries(formId){
  const CountFormEntriesInOneCall= gql`
  query CountFormEntries($formId: String, $countFormEntriesTodayFormId2: String) {
    countFormEntries(form_id: $formId) {
      count
    }
    countFormEntriesToday(form_id: $countFormEntriesTodayFormId2) {
      count
    }
  }`
  const {
    loading: countFormEntriesInOneCallLoading,
    error: countFormEntriesInOneCallError,
    data: countFormEntriesInOneCallData,
  } = useQuery(CountFormEntriesInOneCall, {
    variables: { formId: formId,countFormEntriesTodayFormId2: formId },
  });
   console.log(">>cc", countFormEntriesInOneCallData?.countFormEntries.count,countFormEntriesInOneCallData?.countFormEntriesToday.count
   );

  return {countFormEntriesInOneCallLoading,countFormEntriesInOneCallError,countFormEntriesInOneCallData}
}

function GetFormDataForFormList(currentPage,postsPerPage){
  const GetForms = gql`
query Forms($offset: String) {
  forms(offset: $offset) {
    form_id
    team_id
    form_name
    form_description
    form_fields
    form_conditions
    status
    form_created_date
    form_updated_date
    update_by
    base_form_template
    color_scheme
    logo_url
    form_type
  }
}
`;
const {
  loading: getFormsLoading,
  error: getFormsError,
  data: getFormsData,
  fetchMore,
} = useQuery(GetForms, {
variables: {
  offset:String(currentPage * postsPerPage - postsPerPage),
}
});
return {getFormsLoading, getFormsError, getFormsData, fetchMore}
}

function FormCount(){
  const FormsCount= gql`
  query CountTotalForms {
    countTotalForms {
      count
    }
  }`
  const {
    loading: countTotalFormsLoading,
    error: countTotalFormsError,
    data: countTotalFormsData,
  } = useQuery(FormsCount, {});
  return {countTotalFormsLoading,countTotalFormsError,countTotalFormsData}
}

function DeleteForm(){
  const DeleteForm = gql`
  mutation DeleteForm($formId: String) {
    DeleteForm(form_id: $formId) {
      form_id
      team_id
    }
  }
`;
const [
  deleteForm,
  {
    loading: deleteFormLoading,
    error: deleteFormError,
    data: deleteFormData,
  },
] = useMutation(DeleteForm, {});
return {deleteForm, deleteFormLoading, deleteFormError, deleteFormData}
}


  return {
    // GetCustomFields,
    GetFormData,
    CreateFormEntry,
    GetFormEntries,
    FormEntry,
    UpdateForm,
    DeleteFormEntry,
    UpdateFormEntry,
    Opps,
    GetOppByOppId,
    UpdateOpp,
    DuplicateForm,
    UpdateFormName,
    CreateFieldFuntion,
    GetFormFields,
    GetOppData,
    CreateOpp,
    UpdateOpp,
    CountFormEntries,
    GetFormDataForFormList,
    FormCount,
    DeleteForm,
  };
}

export default useFormApiHelper;
