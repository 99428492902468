import "./form-builder-style.css";
import { FormBuilderMiddleBar } from "./FormBuilderMiddleBar";
import { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import uuidGenerator from "../../../utils/uuidGenerator";
import { boardConetxt } from "./ContextHelper";
import { ElemContext } from "./ContextHelper";
import { ElementContext } from "./SettingContext";
import ElementsSettingsModal from "../../../components/form-builder-components/BoardElementsUI/ElementsSettingsModal";
import FormBuilderLeftSideBar from "./FormBuilderLeftSideBar";
import FormBuilderRightSideBar from "./FormBuilderRightSideBar";
import { makeApiCall } from "../../../modules/MakeApiRequest";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Loaderr } from "../../../components/Loaderr";
import useFormApiHelper from "../../../utils/useFormApiHelper";
import { conditionContext } from "./ContextHelper";
import bst from "../../../assets/images/interface_analytics.png";


const createField= gql`
mutation CreateField($fieldData: GraphQLJSON) {
  CreateField(field_data: $fieldData) {
    field_id
  }
}`

const DashBoardForFormBuilder = () => {
  const [setElement, setSetElement] = useState({});
  const [url, setUrl] = useState("");
  const [color, setColor] = useState("");
  const [CustomElement, setCustomElement] = useState([]);

  const [defaultElement, setDefaultElement] = useState([]);

  const [getFormData, setGetFormData] = useState();

  const { board, setboard } = useContext(boardConetxt);
  const { setCondition } = useContext(conditionContext);

  const params = useParams();
  const formNameRef = useRef(null);
  const {  GetFormData,UpdateFormName,CreateFieldFuntion,UpdateForm } = useFormApiHelper();

  
 const {createFieldMutation}=CreateFieldFuntion()
  const {UpdateFormData}=UpdateFormName()
  const { formDataLoading, formDataError, formData } = GetFormData(params);
  const {updateForm}=UpdateForm()
  const [name, setName] = useState("");
  const  formName=useRef("")
  const [index, setIndex] = useState(null);

console.log("formData",formData)
  useEffect(() => {
    const newelement = formData?.formData?.form_fields?.map((item) => {
      // console.log("item><<",item)
      return { ...item };
    });    
    setboard(newelement);
    let getForm = formData?.formData;
    setGetFormData(getForm);
    setName(getForm?.form_name);
    
  }, [formData?.formData,formData?.formData?.form_fields]);


// Later on, you can just call this function to update the form color and url
  //console.log("element =====>",board)
  // useEffect(() => {
  //   UpdateFormData({
  //     variables: {
  //       formId: formData?.formData?.form_id,
  //       logo_url: url,
  //       color_scheme: color,
  //     },
  //   });
  // }, [color, url]);

  if (formDataLoading) return <Loaderr />;

  console.log("newelement",board)

  const onDragEnd = (result) => {
   
    if (!result.destination) {
      return;
    }
    if (result.source.droppableId == "DefaultElements") {
      //console.log("DefaultElements", result);
      const endIndex = result.destination.index;
      const cloneUsers = Array.from(board);
      const ElementType = result.draggableId.split("-")[0];
      console.log("ElementType: ", endIndex,cloneUsers);
      let newelement = {
        elementid:result.draggableId,
        type: ElementType,
        // name: "",
        settings: {},
      };
      defaultElement?.map((item) => {
        if (item.elementid === result.draggableId) {
          newelement.name = item.name;
          newelement.linked_to = item.linked_to;
          newelement.settings = item.settings;
        }
      });
      console.log("newelement", newelement);

      cloneUsers.splice(endIndex, 0, newelement);

      setboard(cloneUsers);
      //removeElementDefault(ElementType);
      return;
    }

    if (result.source.droppableId == "CustomElements") {
      //console.log("CustomElements", result);
      const endIndex = result.destination.index;

      const cloneUsers = Array.from(board);
      const ElementType = result.draggableId.split("-")[0];
      // //console.log("ElementType: ", ElementType);
      let newelement = {
        elementid: result.draggableId,
        // type:"",
        name: ElementType,
        settings: {},
      };
      CustomElement?.map((item) => {
        if (item.elementid === result.draggableId) {
          newelement.type = item.type;
          newelement.settings = item.settings;
          newelement.name = item.name;
         // newelement.linked_to = item.linked_to;
        // newelement.settings = item.settings;
        }
      });
      console.log("newelement", newelement)
      cloneUsers.splice(endIndex, 0, newelement);

      setboard(cloneUsers);
      // removeElementCustom(ElementType);
      return;
    }

    // //console.log(result)
    if (result.source.droppableId == "Elements") {
      //console.log("Elements", result);
      const endIndex = result.destination.index;
      const cloneUsers = Array.from(board);
      let newelement = {
        elementid: String(uuidGenerator(result.draggableId).elementid),
        type: result?.draggableId,
        name: result.draggableId,
        settings: {},
      };

      createFieldMutation({
  variables: {
    fieldData: {
      field_id: newelement?.elementid,
      input_type: newelement?.type,
      field_name: newelement.type,
      field_type: "custom",
      settings: {},
    }
  }
})

cloneUsers.splice(endIndex, 0, newelement);
      setboard(cloneUsers);

      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const cloneUsers = Array.from(board);
    const [removed] = cloneUsers.splice(startIndex, 1);
    cloneUsers.splice(endIndex, 0, removed);

    setboard(cloneUsers);
  };

  const style = {
    overflowY: "auto",
    height: "100vh",
  };
  const show = board?.length > 0 ? style : null;

  //Handle Reset Form
  const HandleRestForm = (e) => {
    e.preventDefault();
    updateForm({
      variables: {
        formId: formData?.formData?.form_id,
        form_fields: [],
        form_conditions: [],
      },
    })
    
    // setboard([]);
    // setCondition([]);
    //console.log("HandleRestForm",board)
  };

  //handle Upload Logo
  const handleUploadLogo = (e) => {
    e.preventDefault();
    setUrl(e.target.value);
  };
  console.log("handleUploadLogo", url);

  //handle color change
  const handleColorChange = (e) => {
    localStorage.setItem("color", e.target.value);
    setColor(e.target.value);
  };
  const handleRename = () => {
    setIndex(name);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setName(formNameRef.current.value);
    setIndex(formNameRef.current.value);
  };
  const handleSave = (e) => {
    e.preventDefault();
    console.log("handleSave");
    UpdateFormData({
      variables: {
        formId: formData?.formData?.form_id,
        formName: name,
      },
    });
    setIndex(null);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setName(name);
    setIndex(null);
  };
  const handleChangeLogo = () => {
    // setShowLogo(false)
  };
  return (
    <div style={show} onClick={handleChangeLogo}>
      <ElementContext.Provider value={{ setSetElement }}>
        <ElemContext.Provider
          value={{
            CustomElement,
            setCustomElement,
            defaultElement,
            setDefaultElement,
          }}
        >
          {/* <boardConetxt.Provider value={{ board, setboard,color,setColor }}> */}
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="main fmc-main p-0" style={{ marginTop: "64px", position:"fixed" }}>
              <section>
                <div className="d-lg-none builder-stop">
                  <div className="text-center p-5 d-flex flex-column justify-content-center align-items-center">
                    <img
                      src={bst} 
                      className="img-fluid col-5 px-2 mb-5"
                      alt=""
                    />
                    <h2>OOPS !!! We are sorry you cant build on mobile</h2>
                    <p>
                      Please kindly open form builder on your desktop or PC to
                      continue using this engine.
                    </p>
                  </div>
                </div>

                <section className="d-flex fm-stack" style={{ width: "100%" }}>
                  
                  <div className="new-field d-none d-lg-block col-lg-3 col-xl-2 col-xxl-2 bg-white py-3 px-2 p-xl-3 border-end">
                    <FormBuilderLeftSideBar />
                  </div>

                  <section
                    className="build-contianer col-auto col-lg-6 col-xl-7 p-lg-3 px-xl-5 px-5 mb-5 pt-5 pb-5 mx-auto"
                    style={board?.length == 0 ? null : { overflow: "scroll" }}
                  >
                    <div className="mx-auto bg-white d-flex p-4 px-xl-5 py-4 mb-3 rounded-3 border">
                      <h5 className="m-0">
                        {index === name ? (
                          <>
                            <input
                              type="text"
                              className="text"
                              style={{
                                border: "none",
                                outline: "none",
                                padding: "0",
                                margin: "0",
                              }}
                              value={name}
                              ref={formNameRef}
                              onChange={handleChange}
                            />
                            <span
                              onClick={(e) => {
                                handleSave(e);
                              }}
                              style={{ color: "green" }}
                            >
                              <i
                                className="iconify"
                                data-icon="typcn:tick"
                                width="25"
                                height="25"
                              ></i>
                            </span>
                            <span
                              onClick={(e) => {
                                handleCancel(e);
                              }}
                              style={{ color: "red" }}
                            >
                              <i
                                className="iconify"
                                data-icon="raphael:cross"
                                width="25"
                                height="25"
                              ></i>
                            </span>
                          </>
                        ) : (
                          <>
                            <span>{name}</span>
                          </>
                        )}
                      </h5>
                      {index == null && (
                        <span
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            handleRename();
                          }}
                        >
                          <i
                            className="iconify"
                            data-icon="mdi:pencil"
                            width="22"
                            height="20"
                          ></i>
                        </span>
                      )}
                      <div className="d-flex" style={{ marginLeft: "auto" }}>
                        <div className="dropdown pointer">
                          <i
                            className="iconify fs-4  "
                            data-icon="eva:more-horizontal-outline"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                          />
                          <div
                            className="dropdown-menu shadow border-0"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span
                              onClick={(e) => {
                                HandleRestForm(e);
                              }}
                            >
                              <p className="dropdown-item small" href="#">
                                Reset form
                              </p>
                            </span>
                            {/*                             
                            <div className="dropdown-item small d-flex align-items-center py-2">

                            <span  onClick={(e)=>{
                                 
                                  setShowLogo(true)
                                }}>Upload Logo</span>  
                                 </div> */}
                                {/* <div className="accordion-body">
                            <div className="accordion" id="logAccordion">
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="logHeading"
                                >
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#logCollapse"
                                    aria-expanded="true"
                                    aria-controls="logCollapse"
                                  >
                                    Logo Upload
                                  </button>
                                </h2>
                                <div
                                  id="logCollapse"
                                  className="accordion-collapse collapse show"
                                  aria-labelledby="logHeading"
                                  data-bs-parent="#logAccordion"
                                >
                                    <div className="mb-3">
                                      <label
                                        htmlFor="logUrl"
                                        className="form-label"
                                      >
                                        Logo URL
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="logUrl"
                                        placeholder="Enter logo URL"
                                        value={url} 
                                        onChange={handleUploadLogo} 
                                      />
                                    </div>
                                    {/* Add your log upload logic here 
                                    <button className="btn btn-primary" onClick={(e)=>{
                               
                               setShowLogo(false)
                             }}>
                                      Upload Logo
                                    </button>
                                </div>
                              </div>
                            </div>
                                  </div> */}

                            {/* { showLogo &&
                                
                                  <>
                                 <div className="dropdown-item small d-flex align-items-center py-2">
                                <span>Image URL</span>
                                </div>
                                <input type="text" className="form-control" value={url} onChange={handleUploadLogo}  />
                                <input type="submit" className="btn btn-primary ml-1" onClick={(e)=>{
                               
                                  setShowLogo(false)
                                }}
                                
                                value="Upload"
                                
                                />
                                </>
                                 */}

                            {/* <div className="dropdown-item small d-flex align-items-center py-2">
                              <span>Background color</span>
                              <input
                                type="color"
                                className="form-control form-control-color mx-2"
                                id="exampleColorInput"
                                // defaultValue="#563d7c"
                                value={color}
                                title="Choose your color"
                                onChange={handleColorChange}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <FormBuilderMiddleBar getFormData={getFormData} />
                  </section>

                  <div className=" pre-field d-none d-lg-block col-lg-3 col-xl-3 col-xxl-3 bg-white py-3 px-2 p-xl-3 border-start">
                    <FormBuilderRightSideBar formType={formData?.formData?.form_type} />
                  </div>


                 
                </section>

              </section>
            </div>
          </DragDropContext>
          {Object.keys(setElement)?.length !== 0 ? (
            <ElementsSettingsModal
              elementid={setElement?.elementid}
              type={setElement?.type}
            />
          ) : (
            <></>
          )}
          {/* </boardConetxt.Provider> */}
        </ElemContext.Provider>
      </ElementContext.Provider>
    </div>
  );
};

export default DashBoardForFormBuilder;
