import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import Validation from "../../../utils/Validation";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { useForm, Controller, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";

export function ContactUi({
  elementid,
  message = {},
  showHide,
  oppFields,
  previewFlag = false,
}) {
  //("email >>", useFormContext());

  // const [errorMessage, setErrorMessage] = useState({});
  // const contactRef = useRef(null);
  // const [contact, setContact] = useState("");
 const [copy,setCopy]=useState(false);
  const { board,setboard } = useContext(boardConetxt);
  const {register,formState:{errors},setValue,setError,control} = useFormContext();

  const Location=useLocation();
  const params=new URLSearchParams(Location.search);

  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])
  

  // useEffect(()=>{
  //   if(params.get(elementid.replace(/-\d+$/, ''))){
  //     setValue(elementid,params.get(elementid.replace(/-\d+$/, '')))
  //   } 
  
  // },[params.get(elementid.replace(/-\d+$/, ''))])

  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;
  const label=board.find(
    (item) => item?.elementid === elementid
  ).name;
  const {
    inputType,
    placeHolder,  
    instructions,
    minValue,
    maxValue,
    predefine,
    required,
    hidden
  } = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };
  // console.log("Update",ElementSetting)
  // useCallback(()=>{
  //   updateCustomField(
  //     variables: {
  //       formId: params.id,
  //       formData: {
  //         ...formData?.formData,
  //         form_fields: board,
  //       },
  //     },
  //   )
  //     }
  // ,[ElementSetting]
  //   )

  useEffect(()=>{
    setValue(elementid,predefine)
  },[predefine])
  // const contactValidationResult = (e) => {
  //   const phone=e.target.value.replaceAll(/[^0-9]/g, '')
  //   //("check",phone )
  //   let elementData = {};
  //   elementData.contact = phone
  //   // elementData.contact = String(contactRef.current.value)?.replaceAll(/[-+()]/g, '').slice(-10);
  //   let validation = Validation(elementData, ElementSetting);
  //   let { contactChecks } = validation.validateContact();
  //   const newboard = board.map((product) => {
  //     if (product.elementid === elementid) {
  //       product.settings.predefine = phone
  //       //String(contactRef.current.value)?.replaceAll(/-/g, '').slice(-10);
  //     }
  //     return product;
  //   });
  //   setContact(phone)

  //   setboard(newboard);
  //   setErrorMessage(contactChecks);
  // };

  // useEffect(() => {
  //   let elementData = {};
  //   elementData.contact =predefine
  //   let validation = Validation(elementData, ElementSetting);
  //   let { contactChecks } = validation.validateContact();
  //   setErrorMessage(contactChecks);
  // }, [predefine]);

  // useEffect(() => {
  //   if (message[elementid]?.result === false) {
  //     setErrorMessage(message);
  //   }
  // }, [message]);
  const chek=Object.keys(ElementSetting).length === 0
  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])
//("vlaue",chek)
  return (
    <div
      id={elementid}
      className={previewFlag && GetStyleClasses(elementid) || ""}
      style={initStyle}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating w-100 form-group">
        {/* <input
          placeholder="Contact"
          className="form-control input-bn"
          type="number"
          maxLength={maxValue !== "" ? maxValue : 15}
          minLength={minValue ? minValue : 9}
          name="contact"
          id={elementid}
          value={contact || predefine}
          ref={contactRef}
          onInput={(e) => contactValidationResult(e)}
        /> */}
        {/* {ElementSetting ? (
          <input
          type="number"
          className="form-control input-bn"
          placeholder="Contact"
          {...register(`${elementid}`,{
            
            
            // minLength:{
            //   value:10,
            //   message: `Please Enter More then 9 Characters`
            // },
            // maxLength:{
            //   value: 10,
            //   message:`Please Enter Less then 10 Characters`
            // },
            // pattern: {
            //   value: /^[0-9]*$/,
            //   message: "Only numbers are allowed",
            // },
          })
        }

          />
        ):(
          <input
          type={inputType ? inputType : "number"}
          className="form-control input-bn"
          placeholder="Contact"
          defaultValue={predefine === undefined ? "" : predefine}
          {...register(`${elementid}`, {
            required: {
              value: required === true ? true : false,
              message: "This field is required",
            },
            minLength:{
              value:minValue ===  " " ? 10 : minValue,
              message: `Please Enter More then ${minValue ===" " ? 10 : minValue} Characters`
            },
            maxLength:{
              value: maxValue ===  "" || undefined ? 10   : maxValue,
              message:`Please Enter Less then ${maxValue ===  "" ? 10 : maxValue}   Characters`
            },
            pattern: {
              value: /^[0-9]*$/,
              message: "Only numbers are allowed",
            },
          })
        } 

          />
        )}
        */}
       {Object.keys(ElementSetting).length === 0 ? ( <Controller
          control={control}
          name={elementid}
          rules={{
            maxLength:{
              value: 10,
              message:`Please Enter Less then  10 Characters`
            },
            pattern: {
              value: /^[0-9]*$/,
              message: "Only numbers are allowed",
            },
          }}
          render={({ field }) => (
            <input
              type="number"
              className="form-control input-bn"
              placeholder="Contact"
              {...field}
            />
          )}
        />):
        ( <Controller
          control={control}
          name={elementid}
          rules={{
            required: {
              value: required === true ? true : false,
              message: "This field is required",
            },
            minLength:{
              value:minValue ===  " " ? 10 : minValue,
              message: `Please Enter More then ${minValue ===" " ? 10 : minValue} Characters`
            },
            maxLength:{
              value: maxValue ===  "" || undefined ? 10   : maxValue,
              message:`Please Enter Less then ${maxValue ===  "" ? 10 : maxValue}   Characters`
            },
            pattern: {
              value: /^[0-9]*$/,
              message: "Only numbers are allowed",
            },
          }}
          defaultValue={predefine === undefined ? "" : predefine}
          render={({ field }) => (
            <input
              type={inputType ? inputType : "number"}
              className="form-control input-bn"
              placeholder="Contact"
              {...field}
            />
          )}
        />)
        }
        <label>
          <span className="d-flex align-items-center">
            <i className="iconify me-1 mr-1 fs-5" data-icon="carbon:phone" />
            <span>
              {/* {placeHolder ? placeHolder : label} */}
              {copy ? placeHolder == undefined ? label : placeHolder+"-copy": placeHolder ? placeHolder : label}

              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
        {/* placeholder for errors */}
        <small className="text-secondary">{instructions}</small>
        <div className="d-flex flex-column gap-2">
          {errors && (
            <span className="text-danger">{errors[elementid]?.message}</span>
          )}
        </div>
        {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
      </div>
    </div>
  );
}
