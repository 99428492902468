import React, { useContext, useEffect, useRef, useState } from "react";
import { ElementsErrorMessage } from "./ElementsErrorMessage";
import { DatePicker } from "antd";
import { boardConetxt } from "../../../pages/form-builder/dashboard/ContextHelper";
import { Controller, useForm, useFormContext } from "react-hook-form";
import GetStyleClasses from "./BoardElementsSettingsUi/GetStyleClasses";
import { useLocation } from "react-router-dom";

export const DateUi = ({
  elementid,
  message = {},
  showHide,
  oppFields,
  previewFlag = false,
}) => {
  const [copy,setCopy]=useState(false);
  const { board, setboard } = useContext(boardConetxt);
  const [val, setval] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const {
    register,
    formState: { errors },
    controls,
    setValue
  } = useFormContext();
console.log("date>>",errors)
  useEffect(()=>{
    if(oppFields){
      setValue(elementid,oppFields)
    } 
  },[oppFields])


  const Location=useLocation();
  const params=new URLSearchParams(Location.search);

  useEffect(()=>{
    if(params.get(elementid.replace(/-\d+$/, ''))){
      setValue(elementid,params.get(elementid.replace(/-\d+$/, '')))
    } 
  
  },[params.get(elementid.replace(/-\d+$/, ''))])
  // const dateRef = useRef(null);
  // const DateValidationResult = (e) => {
  //   e.preventDefault();
  //   let elementData = {};
  //   elementData.date = dateRef.current.value;
  //   let elementSettings = {};
  //   let euuid = e.target.id + "-" + elementid;
  //   let validation = Validation(euuid, elementData, elementSettings);
  //   let { dateCheck } = validation.validateDate();
  //   ////console.log(dateRef.current.value);
  //   setErrorMessage(dateCheck);
  // };
  useEffect(()=>{
    if(elementid.includes("copy")){
        setCopy(true)
    }
  },[])
  const ElementSetting = board.find(
    (item) => item?.elementid === elementid
  ).settings;

  const label = board.find((item) => item?.elementid === elementid).name;
  //console.log("date>>", label);
  const { placeHolder, instructions, required ,hidden} = ElementSetting;
  const initStyle = {
    display: showHide === "show" ? "none" : "block",
  };

  //   const onChange = (date, dateString) => {
  //     setval(date);
  //   };
  //   useEffect(() => {
  //     const newboard = board?.map((item) => {
  //       if (item?.elementid === elementid) {
  //         return { ...item, settings: { ...item.settings, Date: Date } };
  //       }
  //       return item;
  //     });
  //     setboard(newboard);
  //   }, [val]);
  // if(val!==null){
  //   var { $D, $M, $y } = val;
  // }
  //   const Date = `${$M + 1}/${$D}/${$y}`;
  //   const dateFormat = "MM/DD/YYYY";

  //   useEffect(() => {
  //     if (message[elementid]?.result === false) {
  //       setErrorMessage(message);
  //     }
  //   }, [message]);
  console.log("date>>", errors);
  return (
    <div
      className={previewFlag && GetStyleClasses(elementid) ||  ""}
      style={initStyle}
      id={elementid}
      hidden={hidden && previewFlag}
    >
      <div className="form-floating form-group">
        {/* <input
          placeholder="Sample Date "
          className="date-picker-input form-control input-bn "
          type="date"
          required
          defaultValue={predefinedat ? predefinedat : dateRef.current?.value}
          ref={dateRef}
        //  onBlur={(e) => DateValidationResult(e)}
        onChange={(e) => DateValidationResult(e)}
          name="date"
          id={elementid}
        /> */}
        {/* <input className="date-picker-input form-control input-bn "/> */}
        
        <Controller
          control={controls}
          name={elementid}
          rules={{
            required: {
              value: required === true ? true : false,
              message: "This field is required",
            },
          }}
          render={({ field }) => (
            <DatePicker
              format="MM/DD/YYYY"
              className="date-picker-input form-control input-bn w-100"
              onChange={(date, dateString) => {
                field.onChange(dateString);
                setval(date);
              }}
              id={elementid}
            />
          )}
        />
        {/* <DatePicker
          format={dateFormat}
          
          className="date-picker-input form-control input-bn w-100"
          onChange={onChange}
        ></DatePicker> */}

        <label>
          <span className="d-flex align-items-center">
            <span>
              {/* {placeHolder ? placeHolder : label} */}
              {copy ? placeHolder == undefined ? label : placeHolder+"-copy": placeHolder ? placeHolder : label}

              {required ? <span style={{ color: "red" }}>*</span> : ""}
            </span>
          </span>
        </label>
      </div>
      {/* placeholder for errors */}

      <small className="text-secondary">{instructions}</small>
      <div className="d-flex flex-column gap-2">
        {errors && (
          <span className="text-danger">{errors[elementid]?.message}</span>
        )}
      </div>
      {/* <ElementsErrorMessage errorMessage={errorMessage} /> */}
    </div>
  );
};
