import ElementsSettingsModal from "../components/form-builder-components/BoardElementsUI/ElementsSettingsModal";
import ElementsSettingsWidget from "../components/form-builder-components/BoardElementsUI/ElementsSettingsModal"
export const BoardAddHandler = (elementID, setboard) => {
  //console.log("BOard Handler called", elementID)
  const item = {"elementid":elementID};
  setboard((board) => [...board, item]);
};


export const BoardDeleteHandler = (e, elementid, board, setboard) => {
  //console.log("HandlerDelete",board);
  //console.log("elementid", elementid, "board", board);
  const newboard = board.filter((item) => item.elementid !== elementid);
  //console.log("newboard", newboard);

  return setboard(newboard);
 // //console.log("newboard>", board);
  ////console.log("HandlerDelete",board);

};

export const BoardMoveHandler = (e, id, board, setboard) => {

}

export const BoardSettingsHandler = (e, id, board, setboard) => {
  ////console.log("BoardSettingsHandler", id);
  e.preventDefault();
  return <ElementsSettingsModal elementid={id} type={id} />

  
  
}